import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import Section from "./Section";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#ffffff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    backgroundSize: "100%",
    marginBottom: -1
  },
  lightBlue: {
    backgroundColor: "#e7f7ff"
  },
  silver: {
    backgroundColor: "#cfe3ed"
  },
  darkBlue: {
    backgroundColor: "#32668f",
    color: "#ffffff"
  },
  lightYellow: {
    backgroundColor: "#FAFAF3"
  },
  lightRose: {
    backgroundColor: "#FFE6DF"
  },
  content: {
    margin: "0 auto",
    "&.limitWidth": {
      maxWidth: 1440
    }
  },
  image: {
    width: "100%",
    height: 70,
    marginBottom: -6,
    [theme.breakpoints.up("lg")]: {
      height: 100
    },
    [theme.breakpoints.up("xl")]: {
      height: 150
    },
    "&.rotateImage": {
      width: "100vh",
      transformOrigin: "top left",
      transform: "rotate(90deg)",
      position: "absolute",
      left: "auto",
      top: "auto"
    }
  }
}));

export type BackgroundColorType = "lightBlue" | "silver" | "darkBlue" | "lightYellow" | "lightRose" | undefined;

const CustomSection: React.FC<{
  image?: string;
  imagePosition?: "bottom" | "background";
  color?: BackgroundColorType;
  limitWidth?: boolean;
  withPadding?: boolean;
}> = ({ children, color, image, imagePosition = "bottom", limitWidth = true, withPadding = true }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.container, color && classes[color])}
      style={image && imagePosition === "background" ? { backgroundImage: `url(${image})` } : {}}
    >
      <div className={classnames(classes.content, { limitWidth })}>
        <Section withPadding={withPadding}>{children}</Section>
      </div>
      {image && imagePosition === "bottom" && <img src={image} className={classes.image} alt="background" />}
    </div>
  );
};

export default CustomSection;
