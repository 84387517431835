import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import logo from "../../../assets/logo.png";

const style = () => ({
  root: {
    padding: "100px 0",
    textAlign: "center",
    minHeight: "calc(100% - 318px)",
    margin: 0,
    width: "100%"
  },
  image: {
    width: "100%"
  }
});

const LogoAndMessagePage = ({ classes, message, secondMessage = undefined }) => (
  <Grid container className={classes.root} direction="column" spacing={4}>
    <Grid item>
      <Grid container justify="center">
        <Grid item xs={10} md={6} lg={4} xl={2}>
          <img src={logo} alt="My Wellbeing" className={classes.image} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container justify="center">
        <Grid item xs={11} md={10} lg={8}>
          <Typography variant="h5">{message}</Typography>
        </Grid>
      </Grid>
    </Grid>
    {secondMessage && (
      <Grid item>
        <Grid container justify="center">
          <Grid item xs={11} md={10} lg={8}>
            {secondMessage}
          </Grid>
        </Grid>
      </Grid>
    )}
  </Grid>
);

export default withStyles(style)(LogoAndMessagePage);
