import React, { useEffect, FC } from "react";
import Box from "@material-ui/core/Box";
import { withMatchData } from "../common/MatchDataProvider";
import MatchResult from "./MatchResult";
import LoadingData from "../common/LoadingData";
import LoadError from "../common/LoadError";
import { sendEvent } from "../events";
import Header from "../../client-form-wizard/components/Header/Header";
import { MatchDataContextType, PreferencesDataContextType } from "../types";
import { withPreferencesData } from "../common/PreferencesDataProvider";

const Index: FC<{ matchDataContext: MatchDataContextType; preferencesDataContext: PreferencesDataContextType }> = ({
  matchDataContext,
  preferencesDataContext
}) => {
  const { loadData, loaded, loadErrorCode } = matchDataContext;

  const { loadData: loadPreferences } = preferencesDataContext;

  useEffect(() => {
    loadPreferences();
  }, [loadPreferences]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loaded) {
    sendEvent("summary_page_visited", {});

    return (
      <>
        <Header />
        <MatchResult />
      </>
    );
  }

  if (loadErrorCode) {
    return (
      <>
        <Header />
        <LoadError code={loadErrorCode} />
      </>
    );
  }

  return (
    <>
      <Header blueBackground />
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "#CFE3ED" }}
      >
        <LoadingData text="Loading Your Matches..." />
      </Box>
    </>
  );
};

export default withPreferencesData(withMatchData(Index));
