import React, { useState } from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import profilePic from "../../assets/alyssa_profile_pic.jpeg";
import { ReactComponent as PlayIcon } from "../../assets/play_icon.svg";
import MediaPlayerDialog from "./MediaPlayerDialog";

const useStyles = makeStyles(theme => ({
  button: {
    height: 56,
    borderRadius: 28,
    backgroundColor: "#d3f6a4",
    textTransform: "none",
    display: "inline-flex",
    alignItems: "center",
    padding: "4px 32px 4px 24px",
    width: 280,
    [theme.breakpoints.down("sm")]: {
      padding: "4px 20px"
    },
    "&:hover": {
      backgroundColor: "#c8e9a0"
    }
  },
  profilePic: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 16,
    border: `1px solid ${theme.palette.secondary.main}`
  },
  icon: {
    height: 28,
    width: 28,
    color: "#6B315D"
  },
  title: {
    color: "#2a3948",
    fontSize: 12,
    fontWeight: "bold"
  },
  subtitle: {
    color: "#2a3948",
    fontSize: 12,
    fontWeight: "bold",
    marginLeft: 8
  }
}));
const VideoInstruction: React.FC<{ videoId: string; title: string; subtitle: string }> = ({
  videoId,
  title,
  subtitle
}) => {
  const classes = useStyles();
  const [playing, setPlaying] = useState(false);

  const hlsManifestUrl = `https://cdn.jwplayer.com/manifests/${videoId}.m3u8`;

  return (
    <>
      <Button
        className={classes.button}
        onClick={() => {
          setPlaying(true);
        }}
      >
        <img src={profilePic} className={classes.profilePic} alt="assistant" />
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          <Box display="flex" alignItems="center">
            <PlayIcon className={classes.icon} />
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </Box>
        </Box>
      </Button>
      <MediaPlayerDialog
        show={playing}
        onClose={() => {
          setPlaying(false);
        }}
        hlsManifestUrl={hlsManifestUrl}
        mediaType="video"
        verticalMode
      />
    </>
  );
};

export default VideoInstruction;
