import { Grid, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { SetStateAction } from "react";
import Tooltip from "../../../../common/Tooltip";
import PreferenceList from "./PreferenceList";
import PreferenceSelect from "./PreferenceSelect";

const useStyles = makeStyles(theme => ({
  wrapper: {
    "&.disabled": {
      opacity: 0.35
    },
    marginTop: 16
  },
  icon: {
    height: 30,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  iconTitle: {
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  selectValues: {
    "&.disabled": {
      opacity: 0.5
    }
  },
  tooltip: {
    fontSize: 11,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  tooltipLine: {
    width: "100%"
  },
  required: {
    fontSize: 12,
    fontWeight: "bold",
    color: "red",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    textAlign: "justify"
  },
  limited: {
    fontSize: 12,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    textAlign: "justify"
  }
}));

const Preference: React.FC<{
  title: string;
  options: any[];
  icon: string;
  disabled?: boolean;
  currentState: any;
  type: "select" | "multi-select";
  update: React.Dispatch<SetStateAction<any>>;
  limit?: number;
}> = ({ title, options, icon, disabled, currentState, type, update, limit }) => {
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      <div className={classNames(classes.wrapper, { disabled })}>
        <img src={icon} className={classes.icon} alt={title} />
        <Typography className={classes.iconTitle} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={classNames(classes.selectValues, { disabled })}>
        {type === "multi-select" ? (
          <PreferenceList
            currentState={currentState}
            disabled={disabled}
            options={options}
            update={update}
            limit={limit}
            title={title}
          />
        ) : (
          <PreferenceSelect currentState={currentState} options={options} update={update} />
        )}
      </div>
      {title === "STATE" && (
        <div className={classes.tooltipLine}>
          <Typography className={classes.tooltip}>
            Why state stays?
            <Tooltip
              placement="bottom-start"
              title={
                <Typography variant="body2">
                  Your mental health provider must be eligible to work in the state in which you reside, even if the
                  care is provided by video or by phone. You are only matched with providers who are licensed or
                  eligible to work in your state of residence. Please note: therapists must be licensed in your state
                  and therapy is covered in- or out-of-network by insurance, while coaching and holistic care can work
                  across state lines but is not covered by insurance.
                </Typography>
              }
            />
          </Typography>
        </div>
      )}
    </Grid>
  );
};

export default Preference;
