import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { ModalLinkProps, StepItems } from "../../../types";
import StepContainer from "../../common/StepContainer";
import { SINGLE_SELECTION_DROPDOWN } from "../../../types/FormTypes";
import SelectionDropdown from "../../FormElements/SelectionDropdown";

export type SelectionWithDropdownFormProps = {
  title: string;
  type: string;
  items: Array<StepItems>;
  setSavedData: Dispatch<SetStateAction<string>>;
  savedData: string;
  topLink?: ModalLinkProps;
  textUnderChoices?: string;
  subtitle?: string;
  notice?: string;
  onNext: () => void;
  validationMessage?: string;
};

const SelectionWithDropdownForm: FC<SelectionWithDropdownFormProps> = ({
  title,
  type,
  items,
  setSavedData,
  savedData,
  topLink,
  textUnderChoices,
  subtitle,
  notice,
  onNext,
  validationMessage
}) => {
  const [isValid, setIsValid] = useState(false);
  const [movingToNext, setMovingToNext] = useState(false);
  const [savedDataInitiallyEmpty] = useState(savedData === null);

  const onNextWithValidation = (event: React.MouseEvent) => {
    setMovingToNext(true);
    if (isValid) {
      onNext();
    }
  };

  useEffect(() => {
    setMovingToNext(false);
  }, [savedData, setMovingToNext]);

  useEffect(() => {
    if (savedDataInitiallyEmpty && isValid && type === SINGLE_SELECTION_DROPDOWN) {
      setTimeout(() => onNext(), 200);
    }
  }, [savedDataInitiallyEmpty, isValid, type, onNext]);

  return (
    <>
      <StepContainer
        title={title}
        subtitle={subtitle}
        notice={notice}
        topLink={topLink}
        textUnderChoices={textUnderChoices}
        onNext={type === SINGLE_SELECTION_DROPDOWN && savedDataInitiallyEmpty ? undefined : onNextWithValidation}
        isValid={isValid}
        showValidationMessage={(movingToNext && !isValid) || !!validationMessage}
        validationMessage={validationMessage || "Please make a selection"}
      >
        <SelectionDropdown setIsValid={setIsValid} items={items} savedData={savedData} setSavedData={setSavedData} />
      </StepContainer>
    </>
  );
};

export default SelectionWithDropdownForm;
