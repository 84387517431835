import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { StepItems } from "../../types";
import Box from "@material-ui/core/Box";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ENVIRONMENT } from "../../../../config";
import { shareASaleRequestUrl } from "../../../../utils/shareasale";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingData from "../../../match-page/common/LoadingData";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Tiempos, Times, serif"
  },
  subtitle: {
    fontFamily: "Tiempos, Times, serif",
    fontSize: 26
  }
}));

type SummaryProps = {
  title: string;
  subtitle?: string;
  items: Array<StepItems>;
  redirectUrl?: string;
  clientId: number;
};

const Summary: React.FC<SummaryProps> = ({ title, subtitle, items, redirectUrl, clientId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (redirectUrl) {
      setTimeout(() => window.location.assign(redirectUrl), 500);
    }
  }, [redirectUrl]);

  return (
    <>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} md={9}>
          <Box mt={3}>
            {redirectUrl ? (
              <LoadingData text="Loading Your Matches..." />
            ) : (
              <Typography variant={mobile ? "h4" : "h3"} align="center" gutterBottom className={classes.title}>
                {title}
              </Typography>
            )}
          </Box>
        </Grid>
        {subtitle && (
          <Grid item xs={12} md={9}>
            <Box mt={3}>
              <Typography variant={mobile ? "body1" : "h4"} align="center" gutterBottom className={classes.subtitle}>
                {subtitle}
              </Typography>
            </Box>
          </Grid>
        )}
        {items.map(item => (
          <Grid item xs={12} md={9} key={item.key}>
            <Box mt={3}>
              <Typography
                variant={mobile ? "body2" : "h5"}
                align="center"
                dangerouslySetInnerHTML={{ __html: item.options as string }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {redirectUrl && ENVIRONMENT === "production" && (
        <>
          <img src={shareASaleRequestUrl(clientId, "customer")} width="1" height="1" alt="Pixel" />
          <script src="https://www.dwin1.com/19038.js" type="text/javascript" defer />
        </>
      )}
    </>
  );
};

// @ts-ignore
export default Summary;
