import { camelizeKeys } from "humps";
import React from "react";
import { preferencesRequest } from "../api";

export const PreferencesDataContext = React.createContext();

export const withPreferencesData = Component => ({ ...props }) => (
  <PreferencesDataContext.Consumer>
    {preferencesDataContext => <Component preferencesDataContext={preferencesDataContext} {...props} />}
  </PreferencesDataContext.Consumer>
);

class PreferencesDataProvider extends React.Component {
  state = {
    availability: null,
    fee: null,
    age: null,
    orientation: null,
    gender: null,
    raceEthnicity: null,
    expertise: null,
    style: null,
    loaded: false,
    loadError: false,
    loadData: this.loadData.bind(this)
  };

  updatePreferences(response) {
    this.setState({
      availability: camelizeKeys(response.data.availability),
      fee: camelizeKeys(response.data.fee),
      age: camelizeKeys(response.data.age),
      orientation: camelizeKeys(response.data.orientation),
      gender: camelizeKeys(response.data.gender),
      raceEthnicity: camelizeKeys(response.data.race_ethnicity),
      expertise: camelizeKeys(response.data.expertise),
      style: camelizeKeys(response.data.style),
      loaded: false,
      loadError: false
    });
  }

  loadData() {
    preferencesRequest({
      callbacks: {
        onSuccess: response => {
          this.updatePreferences(response);
        },
        onError: () => {
          this.setState({ loadError: true });
        },
        onAfter: () => {}
      }
    });
  }

  render() {
    const { children } = this.props;

    return <PreferencesDataContext.Provider value={this.state}>{children}</PreferencesDataContext.Provider>;
  }
}

export default PreferencesDataProvider;
