import React, { FC } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import defaultPicture from "../../../../assets/default-profile-image.jpg";

const useStyles = makeStyles(() => ({
  image: {
    objectFit: "cover",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40
  }
}));

const ProfileImage: FC<{
  profileImage: string | undefined;
  altText: string;
  size?: number;
}> = ({ profileImage, altText, size = 280 }) => {
  const classes = useStyles();

  return (
    <img src={profileImage || defaultPicture} alt={altText} className={classes.image} height={size} width={size} />
  );
};

export default ProfileImage;
