import React, { Dispatch, SetStateAction } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, ButtonBase, Grid, Collapse } from "@material-ui/core";
import { ModalLinkProps, SliderConfig, StepItems } from "../../../types";
import SliderCard from "./SliderCard";
import StepContainer from "../../common/StepContainer";

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: 16,
    padding: theme.spacing(1),
    borderRadius: 18,
    "&:hover": {
      backgroundColor: "white"
    }
  }
}));

export type MultiSliderFormProps = {
  title: string;
  items: Array<StepItems>;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: any;
  defaults?: any;
  topLink?: ModalLinkProps;
  subtitle?: string;
  onNext: () => void;
  validationMessage?: string;
};

const SliderForm: React.FC<MultiSliderFormProps> = ({
  title,
  items,
  setSavedData,
  savedData,
  defaults,
  topLink,
  subtitle,
  onNext,
  validationMessage
}) => {
  const classes = useStyles();
  const defaultsSelected =
    defaults &&
    savedData.therapist_a_rating === defaults.therapist_a_rating &&
    savedData.therapist_b_rating === defaults.therapist_b_rating &&
    savedData.therapist_c_rating === defaults.therapist_c_rating;

  const Sliders = () => (
    <Grid container spacing={2}>
      {items.map(item => {
        const options = item.options as SliderConfig;

        return (
          <Grid item xs={12} md={4} key={item.key}>
            <SliderCard
              setSavedData={setSavedData}
              savedData={savedData}
              id={item.key}
              errorMsg="Please make a selection"
              isNotValid={false}
              config={options}
              title={item.title}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <StepContainer
      title={title}
      subtitle={subtitle}
      topLink={topLink}
      onNext={onNext}
      buttonLabel={defaultsSelected ? "Proceed with most common selections" : "Next"}
      isValid={true}
      showValidationMessage={!!validationMessage}
      validationMessage={validationMessage}
    >
      <>
        <Sliders />
        {defaults && (
          <Collapse in={!defaultsSelected}>
            <Box mt={2} display="flex" justifyContent="center">
              <ButtonBase onClick={() => setSavedData(defaults)} className={classes.button} focusRipple>
                Reset to most common selections
              </ButtonBase>
            </Box>
          </Collapse>
        )}
      </>
    </StepContainer>
  );
};

export default SliderForm;
