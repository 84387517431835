import { axiosInstance, attachCallbacks } from "../../../utils/api";
import { NextStepRequest, PreviousStepRequest, SectionRequest } from "../types";
import { RAILS_SECURITY_TOKEN } from "../../../config";

export const fetchStepDefinition = ({
  formUuid,
  formId,
  formQueryString,
  currentStepName,
  demo,
  savedData,
  callbacks
}: NextStepRequest) => {
  attachCallbacks(
    axiosInstance.post(
      "/client_forms/next_step",
      {
        form_id: formId,
        form_uuid: formUuid,
        form_query_string: formQueryString,
        current_step_name: currentStepName,
        demo,
        saved_data: savedData,
        security_token: RAILS_SECURITY_TOKEN
      },
      { withCredentials: true }
    ),
    callbacks
  );
};

export const fetchSectionIntroDefinition = ({ formUuid, formId, sectionName, callbacks }: SectionRequest) => {
  attachCallbacks(
    axiosInstance.post("/client_forms/section", {
      name: sectionName,
      form_id: formId,
      form_uuid: formUuid,
      security_token: RAILS_SECURITY_TOKEN
    }),
    callbacks
  );
};

export const fetchPreviousStepDefinition = ({ formUuid, formId, callbacks }: PreviousStepRequest) => {
  attachCallbacks(
    axiosInstance.post("/client_forms/prev_step", {
      form_id: formId,
      form_uuid: formUuid,
      security_token: RAILS_SECURITY_TOKEN
    }),
    callbacks
  );
};
