import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "./components/match-page/theme";
import NotificationProvider from "./components/therapist-app/notification/NotificationProvider";
import Notification from "./components/therapist-app/notification/Notification";
import ErrorBoundary from "./components/common/ErrorBoundary";
import Router from "./Router";
import PageViewAnalytics from "./components/analytics/page-view-analytics";
import "./App.css";
import Spinner from "./components/common/Spinner";

const App: React.FC = () => (
  <MuiThemeProvider theme={Theme}>
    <Helmet>
      <title>MyWellbeing</title>
      <meta
        name="description"
        content="Find The Best Therapist or Coach For You. Complete Our Brief, Free Questionnaire and We Will Email 1-3 Therapist Recommendations Right to Your Inbox."
      />
    </Helmet>
    <CssBaseline />
    <NotificationProvider>
      <Notification />
      <Suspense fallback={<Spinner loading withLogo />}>
        <BrowserRouter>
          <PageViewAnalytics />
          <ErrorBoundary>
            <Router />
          </ErrorBoundary>
        </BrowserRouter>
      </Suspense>
    </NotificationProvider>
  </MuiThemeProvider>
);

export default App;
