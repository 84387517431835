import axios from "axios";
import { API_URL } from "../config";

export const axiosInstance = axios.create({
  baseURL: API_URL
});

export const attachCallbacks = (req, { onSuccess, onError, onAfter }) => {
  req
    .then(response => onSuccess(response))
    .catch(error => onError(error))
    .then(() => onAfter());
};
