import { useState } from "react";

/* This hook will handle the state of a Modal to show/hide it */
const useModal = () => {
  const [isVisible, setIsShowing] = useState(false);

  const toggle = (): void => {
    setIsShowing(!isVisible);
  };

  return {
    isVisible,
    toggle
  };
};

export default useModal;
