import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import InputMask from "react-input-mask";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { emailRegex } from "../../../common/regexps";

const useStyles = makeStyles(theme => ({
  root: {
    width: 280,
    height: 60,
    fontSize: 18,
    color: "#2A3948",
    backgroundColor: "white",
    borderRadius: 16,
    "@media screen and (min-width: 960px)": {
      fontSize: 24,
      width: 380
    }
  },
  adornment: {
    lineHeight: "52px",
    fontSize: "38px",
    color: "#8A9CA9"
  },
  label: {
    marginTop: theme.spacing(4)
  },
  input: {
    margin: "0 8px",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontSize: 18,
      "@media screen and (min-width: 960px)": {
        fontSize: 24
      }
    },
    textAlign: "center",
    "-moz-appearance": "textfield",
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
}));

export type TextElementProps = {
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: string;
  isNumericalFiled?: boolean;
  isEmailField?: boolean;
  maskFormat?: string;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  adornment?: string;
  textInputLabel?: string;
  textPlaceholder?: string;
  skipValidation?: boolean;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
};

const TextElement: React.FC<TextElementProps> = ({
  setSavedData,
  savedData,
  isNumericalFiled,
  isEmailField,
  maskFormat,
  minLength,
  maxLength,
  minValue,
  maxValue,
  textInputLabel,
  textPlaceholder,
  skipValidation,
  adornment,
  setIsValid,
  onKeyUp
}) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>();

  const value = savedData || "";

  // Fix for mobile devices where cursor was placed in the middle of the input
  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const input = inputRef.current;
    if (input) {
      input.focus();
      if (!value || value.replace(/_/g, "") === "") {
        input.setSelectionRange(0, 0);
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    let valid = true;

    if (skipValidation) {
      setIsValid(true);
      return;
    }

    if (isNumericalFiled) {
      if ((minValue && parseInt(value, 10) < minValue) || (maxValue && parseInt(value, 10) > maxValue)) {
        valid = false;
      }
      if (maskFormat && value.includes("_")) {
        valid = false;
      }
    }

    if (
      !value ||
      value.length === 0 ||
      value.trim().length === 0 ||
      (minLength && value.length < minLength) ||
      (maxLength && value.length > maxLength) ||
      (isEmailField && !emailRegex.test(value))
    ) {
      valid = false;
    }

    setIsValid(valid);
  }, [
    value,
    skipValidation,
    isNumericalFiled,
    isEmailField,
    maskFormat,
    minValue,
    maxValue,
    minLength,
    maxLength,
    setIsValid
  ]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSavedData(event.target.value);
  };

  const Label = () => {
    if (textInputLabel) {
      return (
        <Typography align="center" className={classes.label}>
          {textInputLabel}
        </Typography>
      );
    }

    return <></>;
  };

  if (maskFormat) {
    return (
      <div>
        <Label />

        <InputMask mask={maskFormat} value={value} onChange={onChange} alwaysShowMask>
          {() => (
            <Input
              className={classes.root}
              classes={{ input: classes.input }}
              inputRef={inputRef}
              onKeyUp={onKeyUp}
              onClick={handleClick}
              autoFocus
              disableUnderline
              type={isNumericalFiled ? "tel" : "text"}
              placeholder={textPlaceholder}
              // inputProps={isNumericalFiled ? { pattern: "[0-9]*" } : {}}
              startAdornment={
                adornment ? (
                  <InputAdornment disableTypography className={classes.adornment} position="start">
                    {adornment}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
            />
          )}
        </InputMask>
      </div>
    );
  }

  return (
    <div>
      <Label />

      <Input
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        className={classes.root}
        classes={{ input: classes.input }}
        inputRef={inputRef}
        onClick={handleClick}
        autoFocus
        disableUnderline
        type={isNumericalFiled ? "tel" : "text"}
        placeholder={textPlaceholder}
        // inputProps={isNumericalFiled ? { pattern: "[0-9]*" } : {}}
        startAdornment={
          adornment ? (
            <InputAdornment disableTypography className={classes.adornment} position="start">
              {adornment}
            </InputAdornment>
          ) : (
            undefined
          )
        }
      />
    </div>
  );
};

export default TextElement;
