import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { CheckboxDetails, ModalLinkProps } from "../../../types";
import StepContainer from "../../common/StepContainer";
import TextElement from "../../FormElements/TextElement";
import CheckboxesGroup from "../../FormElements/CheckboxesGroup";

export type TextData = {
  text: string;
  checkboxesValues?: { [key: string]: boolean };
  captcha?: string;
};

export type TextInputFormProps = {
  title: string;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: TextData;
  topLink?: ModalLinkProps;
  subtitle?: string;
  subtitle2?: string;
  onNext: () => void;
  isNumericalFiled?: boolean;
  isEmailField?: boolean;
  maskFormat?: string;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  customErrorMessage?: string;
  validationMessage?: string;
  adornment?: string;
  textInputLabel?: string;
  textPlaceholder?: string;
  checkboxesDetails?: Array<CheckboxDetails>;
  buttonLabel?: string;
};

const TextInputForm: React.FC<TextInputFormProps> = ({
  title,
  setSavedData,
  savedData,
  topLink,
  subtitle,
  subtitle2,
  onNext,
  isNumericalFiled,
  isEmailField,
  maskFormat,
  minLength,
  maxLength,
  minValue,
  maxValue,
  textInputLabel,
  textPlaceholder,
  adornment,
  customErrorMessage,
  validationMessage,
  checkboxesDetails,
  buttonLabel
}) => {
  const [text, setText] = useState(savedData.text || "");
  const [textValid, setTextValid] = useState(true);
  const [skipTextValidation, setSkipTextValidation] = useState(false);

  const [checkboxesValues, setCheckboxesValues] = useState(savedData.checkboxesValues);
  const [checkboxesValid, setCheckboxesValid] = useState(true);
  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState("");

  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [movingToNext, setMovingToNext] = useState(false);

  useEffect(() => {
    setSavedData({ text, checkboxesValues });

    if (checkboxesDetails && checkboxesValues) {
      let skipValidation = false;
      checkboxesDetails.forEach(({ key, skipsInputValidation }) => {
        if (checkboxesValues[key] && skipsInputValidation) {
          skipValidation = true;
        }
      });
      setSkipTextValidation(skipValidation);
    }
  }, [text, checkboxesValues, setSavedData, checkboxesDetails]);

  useEffect(() => {
    setIsValid(textValid && checkboxesValid);
  }, [textValid, checkboxesValid, setIsValid]);

  useEffect(() => {
    setErrorMessage((!textValid && customErrorMessage) || (!checkboxesValid && checkboxErrorMessage) || "");
  }, [textValid, customErrorMessage, checkboxesValid, checkboxErrorMessage, setErrorMessage]);

  function onNextWithValidation() {
    setMovingToNext(true);
    if (isValid) {
      onNext();
    }
  }

  const handleClick = (event: React.MouseEvent) => {
    onNextWithValidation();
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === "Enter") {
      onNextWithValidation();
    }
  };

  useEffect(() => {
    setMovingToNext(false);
  }, [savedData, setMovingToNext]);

  return (
    <StepContainer
      title={title}
      subtitle={subtitle}
      subtitle2={subtitle2}
      topLink={topLink}
      onNext={handleClick}
      isValid={isValid}
      showValidationMessage={(movingToNext && !isValid) || !!validationMessage}
      validationMessage={validationMessage || errorMessage || "Please fill in the field"}
      buttonLabel={buttonLabel}
    >
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <TextElement
              setSavedData={setText}
              savedData={text}
              isNumericalFiled={isNumericalFiled}
              isEmailField={isEmailField}
              maskFormat={maskFormat}
              minLength={minLength}
              maxLength={maxLength}
              minValue={minValue}
              maxValue={maxValue}
              textInputLabel={textInputLabel}
              textPlaceholder={textPlaceholder}
              adornment={adornment}
              setIsValid={setTextValid}
              onKeyUp={handleKeyUp}
              skipValidation={skipTextValidation}
            />
          </Grid>

          {checkboxesDetails && checkboxesDetails.length > 0 && checkboxesValues && (
            <Grid item>
              <CheckboxesGroup
                checkboxesDetails={checkboxesDetails}
                checkboxesValues={checkboxesValues}
                setCheckboxesValues={setCheckboxesValues}
                setCheckboxesValid={setCheckboxesValid}
                setCheckboxErrorMessage={setCheckboxErrorMessage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </StepContainer>
  );
};

export default TextInputForm;
