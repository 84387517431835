import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    fontFamily: ["Avenir", "Arial", "sans-serif"]
  },
  palette: {
    primary: {
      main: "#32668F",
      light: "#6494bf"
    },
    secondary: {
      main: "#6B315D"
    },
    text: {
      primary: "#2A3948"
    },
    background: {
      default: "#ffffff"
    },
    error: {
      main: "#ee0000",
      light: "#F19E86"
    }
  },
  colors: {
    backgroundBlue: "#a6bfcc",
    text: "#2a3948",
    linkText: "#33668f"
  }
});
