import { pick } from "ramda";
import { seeMoreMatchesRequest, updatePreferencesRequest } from "./api";

export const objectsEqual = (o1, o2) => {
  return o1 && o2 && typeof o1 === "object" && typeof o2 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
};

export const compare = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

export const sortObject = object => {
  const keys = Object.keys(object);
  if (keys.length > 0) {
    keys.forEach(key => {
      if (Array.isArray(object[key])) {
        Object.assign(object, { [key]: object[key].sort(compare) });
      }
    });
  }
  return object;
};

export const havePreferencesChanged = (client, preferences) => {
  const preferencesOnlyClient = pick(
    ["availabilities", "ages", "ethnicities", "feeRanges", "genders", "lgbtqia", "specializations", "styles"],
    client
  );
  const sortedClient = sortObject(preferencesOnlyClient);
  const sortedPreferences = sortObject(preferences);
  return !objectsEqual(sortedPreferences, sortedClient);
};

export const seeMoreMatches = (clientPreferenceId, onSuccess, onError) => {
  seeMoreMatchesRequest({
    data: {
      client_preference_id: clientPreferenceId
    },
    callbacks: {
      onSuccess,
      onError,
      onAfter: () => {}
    }
  });
};

export const updatePreferences = (data, onSuccess, onError) => {
  const { clientPreference, preferences } = data;
  if (havePreferencesChanged(clientPreference, preferences)) {
    updatePreferencesRequest({
      data: {
        client_preference_id: clientPreference.id,
        availabilities: preferences.availabilities.map(item => item.id),
        age_ranges: preferences.ages.map(item => item.id),
        ethnicities: preferences.ethnicities.map(item => item.id),
        fee_ranges: preferences.feeRanges.map(item => item.id),
        genders: preferences.genders.map(item => item.id),
        orientation: preferences.lgbtqia.id,
        specializations: preferences.specializations.map(item => item.id),
        styles: preferences.styles.map(item => item.id)
      },
      callbacks: {
        onSuccess,
        onError,
        onAfter: () => {}
      }
    });
  } else {
    seeMoreMatches(clientPreference.id, onSuccess, onError);
  }
};
