import { Grid, makeStyles, Paper } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import icon from "../../assets/more_matches.svg";

const useStyles = makeStyles(theme => ({
  container: {
    width: 280,
    "&.expanded": {
      [theme.breakpoints.up("lg")]: {
        width: 380
      }
    }
  },
  paper: {
    borderRadius: 40,
    marginBottom: theme.spacing(0.5),
    minHeight: 460
  },
  imageItem: {
    position: "relative",
    marginTop: theme.spacing(2.5)
  },
  icon: {
    height: 160,
    "&.expanded": {
      height: 240
    },
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  cardCopy: {
    fontSize: 12,
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    textAlign: "justify"
  }
}));

const NoMoreMatchesCard: FC<{ expanded: boolean }> = ({ expanded }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, { expanded })}>
      <Paper className={classes.paper} elevation={1}>
        <Grid container>
          <Grid item xs={12} className={classes.imageItem}>
            <img src={icon} className={classNames(classes.icon, { expanded })} alt="Card icon" />
          </Grid>
          <Grid item xs={12} className={classes.cardTitle}>
            More matches?
          </Grid>
          <Grid item xs={12} className={classes.cardCopy}>
            Research shows that often, having *too many* options is more overwhelming than helpful. To best support you,
            let's continue with what we've got so far.
            <br />
            <br />
            Book a free consult call with at least one of your matches to gauge your fit.
            <br />
            <br />
            If you've already completed consults and want to explore more, scroll to the bottom of this page to view
            your options.
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default NoMoreMatchesCard;
