import React, { FC } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import CrisisResources from "../../common/CrisisResources";
import certificateImage from "../assets/certificate.svg";
import pickProvidersImage from "../assets/pick_provider.svg";
import FlatButton from "../../therapist-app/profile/common/FlatButton";
import { DIRECTORY_URL } from "../../../config";

const Footer: FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box p={{ xs: "8px 0", md: "8px 96px" }}>
      <Typography align="center" style={{ fontFamily: "Tiempos", fontSize: 32 }}>
        Still searching?
      </Typography>
      <Typography align="center" style={{ marginBottom: 48 }}>
        Update your preferences above for a new set of matches or utilize additional options below
      </Typography>

      <Grid container justify="center">
        <Grid item xs={12} sm={5}>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <img src={pickProvidersImage} alt="Pick your matches" style={{ height: 150, marginBottom: 16 }} />
            <Typography align="center" style={{ fontFamily: "Tiempos", fontSize: 20 }}>
              Pick Your Provider
            </Typography>
            <Typography align="center">National Directory</Typography>
            <Box width="200px" mt="16px" mx="auto">
              <FlatButton color="orange" round fullWidth href={`${DIRECTORY_URL}/therapists-and-coaches`}>
                Browse Directory
              </FlatButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Box display="flex" justifyContent="center" flexDirection="column" mt={mobile ? "48px" : undefined}>
            <img src={certificateImage} alt="Dedicated" style={{ height: 150, marginBottom: 16 }} />
            <Typography align="center" style={{ fontFamily: "Tiempos", fontSize: 20 }}>
              Book a Dedicated Provider
            </Typography>
            <Typography align="center">In-network with BCBS, United, Cigna, Aetna</Typography>
            <Box width="200px" mt="16px" mx="auto">
              <FlatButton
                color="orange"
                round
                fullWidth
                href="https://mywellbeing.com/group-pages/dedicated-by-mywellbeing"
              >
                Browse Dedicated
              </FlatButton>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box pt="96px">
        <CrisisResources align="center" />
      </Box>
    </Box>
  );
};

export default Footer;
