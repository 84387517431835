import { Grid, makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";

const useStyles = makeStyles(() => ({
  section: {
    marginTop: 10,
    borderRadius: 20,
    padding: 10,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "justify"
  },
  sectionHeader: {
    width: "100%",
    textAlign: "center",
    paddingBottom: 16
  }
}));

const Section: React.FC<{ header?: ReactNode | ReactNode[]; color?: string; children: ReactNode | ReactNode[] }> = ({
  header,
  color,
  children
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.section} style={{ backgroundColor: color }}>
      {header && <div className={classes.sectionHeader}>{header}</div>}
      {children}
    </Grid>
  );
};

export default Section;
