import { Grid, InputBase, makeStyles, Select } from "@material-ui/core";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { SelectionFieldProps, StepItems } from "../../types";

export type SelectionDropdownProps = {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  items: StepItems[];
  savedData: string;
  setSavedData: Dispatch<SetStateAction<string>>;
};

const useStyles = makeStyles(() => ({
  selectInputRoot: {
    height: "100%",
    width: "100%",
    padding: 5
  },
  select: {
    backgroundColor: "#ffffff",
    height: 60,
    width: 380,
    borderRadius: 10
  },
  dropdownItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const SelectionDropdown: FC<SelectionDropdownProps> = ({ items, setIsValid, savedData, setSavedData }) => {
  const classes = useStyles();

  const [selection, setSelection] = useState<undefined | string>(savedData);

  useEffect(() => {
    if (selection) {
      setSavedData(selection);
      setIsValid(true);
    }
  }, [selection, setIsValid, setSavedData]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.dropdownItem}>
        <Select
          fullWidth
          native
          className={classes.select}
          value={selection}
          onChange={e => setSelection(e.target.value as string)}
          input={<InputBase classes={{ root: classes.selectInputRoot }} />}
        >
          {items
            .flatMap(item => item.options as SelectionFieldProps[])
            .map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default SelectionDropdown;
