/* eslint-disable react/no-unused-state */

import React from "react";
import queryString from "query-string";
import { camelizeKeys } from "humps";
import { matchRequest } from "../api";

export const MatchDataContext = React.createContext();

export const withMatchData = Component => ({ ...props }) => (
  <MatchDataContext.Consumer>
    {matchDataContext => <Component matchDataContext={matchDataContext} {...props} />}
  </MatchDataContext.Consumer>
);

class MatchDataProvider extends React.Component {
  state = {
    client: null,
    therapists: [],
    loaded: false,
    loadErrorCode: undefined,
    loadData: this.loadData.bind(this),
    updateProfile: this.updateProfile.bind(this),
    updated: false,
    setUpdated: this.setUpdated.bind(this)
  };

  setUpdated(updated) {
    this.setState({
      updated
    });
  }

  updateProfile(response) {
    this.setState({
      loadErrorCode: undefined,
      loaded: true,
      client: camelizeKeys(response.data.client),
      matches: camelizeKeys(response.data.match_results),
      messageTemplate: camelizeKeys(response.data.message_template),
      abTestVersion: response.data.ab_test_version
    });
  }

  loadData() {
    const parsed = queryString.parse(window.location.search);
    const { loaded } = this.state;

    matchRequest({
      data: parsed,
      callbacks: {
        onSuccess: response => {
          if (loaded) {
            this.updateProfile(response);
            this.setUpdated(true);
          } else {
            this.updateProfile(response);
          }
        },
        onError: error => {
          this.setState({ loadErrorCode: error.response.status });
        },
        onAfter: () => {}
      }
    });
  }

  render() {
    const { children } = this.props;

    return <MatchDataContext.Provider value={this.state}>{children}</MatchDataContext.Provider>;
  }
}

export default MatchDataProvider;
