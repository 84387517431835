import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core";

const styles = () => ({
  spinner: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    "&.fullHeight": {
      height: "100%"
    }
  },
  logo: {
    width: 200
  }
});

interface SpinnerProps extends WithStyles {
  loading: boolean;
  withLogo?: boolean;
  fullHeight?: boolean;
  size?: number;
  children?: React.ReactNode;
}

const Spinner: React.FC<SpinnerProps> = ({ loading, withLogo, size, children, classes, fullHeight = true }) => {
  if (loading) {
    return (
      <div className={`${classes.spinner}${fullHeight ? " fullHeight" : ""}`}>
        {withLogo && (
          <>
            <img
              src="https://app.mywellbeing.com/mywellbeing-logo.png"
              className={classes.logo}
              alt="MyWellbeing logo"
            />
          </>
        )}
        <CircularProgress color="inherit" size={size} />
      </div>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return <></>;
};

export default withStyles(styles)(Spinner);
