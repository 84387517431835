import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import React, { FC, ReactNode } from "react";

const LeftSection: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box
        width="100%"
        maxWidth={mobile ? "100%" : "696px"}
        pl={{ xs: `${theme.spacing(2)}px`, md: `${theme.spacing(12)}px` }}
        pr={{ xs: `${theme.spacing(2)}px`, md: 0 }}
        mb={mobile ? 2 : 0}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LeftSection;
