import queryString from "query-string";
import { axiosInstance, attachCallbacks } from "../../utils/api";

const attachAuthToken = data =>
  Object.assign(data || {}, { auth_token: queryString.parse(window.location.search).auth_token });

export const matchRequest = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.get("/match_result", { params: attachAuthToken(data) }), callbacks);
};

export const createClientQuestionRequest = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.post("/client_questions", { params: attachAuthToken(data) }), callbacks);
};

export const createEventRequest = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.post("/events", attachAuthToken(data)), callbacks);
};

export const preferencesRequest = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.get("/client_preference/resources", { params: attachAuthToken(data) }), callbacks);
};

export const updatePreferencesRequest = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.post("/client_preference/create_and_match", attachAuthToken(data)), callbacks);
};

export const seeMoreMatchesRequest = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.post("/client_preference/get_more_matches", attachAuthToken(data)), callbacks);
};
