import React, { ChangeEvent, useState } from "react";
import { makeStyles, Slider } from "@material-ui/core";
import styles from "../client-form-wizard/components/Forms/SliderForm/SliderForm.module.css";

const useStyles = makeStyles(() => ({
  root: {
    color: "#32668f",
    height: 16
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "4px solid #32668f",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50%)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 0.5
  }
}));

export interface ElementProps {
  selectedValue: string;
  onUpdate: (value: number) => void;
  onChange?: (value: number) => void;
  valueLabelFormat?: string | ((value: number, index: number) => React.ReactNode);
  minValue: number;
  maxValue: number;
  minLabel: string;
  maxLabel: string;
  step?: number;
  displayValueLabel?: boolean;
  withDefaultTrack?: boolean;
  displayError?: boolean;
  errorMsg?: string;
}

const SliderElement: React.FC<ElementProps> = ({
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  selectedValue,
  step,
  displayValueLabel,
  valueLabelFormat,
  withDefaultTrack,
  onUpdate,
  onChange,
  displayError = false,
  errorMsg
}: ElementProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(parseInt(selectedValue, 10));

  const handleChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  const handleChangeCommitted = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      onUpdate(newValue);
    }
  };

  return (
    <div className={styles.sliderContainer}>
      <Slider
        classes={{
          thumb: classes.thumb,
          valueLabel: classes.valueLabel,
          active: classes.active,
          rail: withDefaultTrack ? undefined : classes.rail,
          root: withDefaultTrack ? undefined : classes.root,
          track: withDefaultTrack ? undefined : classes.track
        }}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        aria-labelledby="continuous-slider"
        min={minValue}
        max={maxValue}
        step={step}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay={displayValueLabel ? "on" : "off"}
      />
      <div className={styles.sliderLabelContainer}>
        <label className={styles.sliderLabel}>{minLabel}</label>
        <label className={styles.sliderLabel}>{maxLabel}</label>
      </div>
      {displayError && <span className={styles.errorMessage}>{errorMsg}</span>}
    </div>
  );
};

export default SliderElement;
