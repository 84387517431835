import React, { Dispatch } from "react";
import classNames from "classnames";
import { Grid, Paper, makeStyles, useTheme, useMediaQuery, Typography } from "@material-ui/core";
import recommendedBagde from "../../assets/recommended_tag.svg";
import MediaWidget from "../../../common/MediaWidget";
import ProfileImage from "./ProfileImage";
import ContactDetails from "./ContactDetails";
import { TherapistType } from "../../types";
import ExpandedSection from "./ExpandedSection";
import Tooltip from "../../../common/Tooltip";

export type TherapistCardProps = {
  therapist: TherapistType;
  setTherapistIdWithOpenCalendar: Dispatch<number | undefined>;
  expanded: boolean;
  onLearnMore: () => void;
  matchIndex: number;
};

const useStyles = makeStyles(theme => ({
  container: {
    width: 280,
    "&.expanded": {
      [theme.breakpoints.up("lg")]: {
        width: 380
      },
      "&.recommended": {
        [theme.breakpoints.up("lg")]: {
          width: 386
        }
      }
    },
    "&.recommended": {
      position: "relative",
      width: 286,
      border: "3px solid #f19e86",
      borderRadius: 43,
      boxShadow:
        "0px 2px 1px -1px rgb(241,158,134,0.2), 0px 1px 1px 0px rgb(241,158,134,0.14), 0px 1px 3px 0px rgb(241,158,134,0.12)"
    }
  },
  recommendedBagde: {
    position: "absolute",
    display: "block",
    zIndex: 5,
    right: 10,
    top: -24,
    height: "auto",
    width: 180
  },
  bagdeWrapper: {
    position: "static"
  },
  paper: {
    borderRadius: 40,
    minHeight: 460
  },
  imageItem: {
    position: "relative"
  },
  nameAndMatchBanner: {
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
    backgroundColor: "rgba(42,57,72,0.5)",
    width: "100%"
  },
  nameAndMatchWrapper: {
    position: "absolute",
    bottom: 6,
    left: 0,
    width: "100%"
  },
  name: {
    fontFamily: "Avenir",
    lineHeight: "normal",
    textTransform: "none",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 20,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "& > span": {
      fontSize: 14
    }
  },
  showMoreButton: {
    alignSelf: "center"
  },
  buttons: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    },
    top: 0
  }
}));

const TherapistCard: React.FC<TherapistCardProps> = ({
  therapist,
  setTherapistIdWithOpenCalendar,
  expanded,
  onLearnMore,
  matchIndex
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const moreLicenses = therapist.licenses && therapist.licenses.length > 1 ? therapist.licenses.length - 1 : undefined;
  const licenses = therapist.licenses && therapist.licenses.length > 0 ? therapist.licenses.join(", ") : "";
  const licensesShortened = `${
    therapist.licenses && therapist.licenses.length > 0 ? `, ${therapist.licenses[0]}` : ""
  }${moreLicenses ? ` + ${moreLicenses}` : ""}`;

  return (
    <div className={classNames(classes.container, { expanded, recommended: therapist.recommended })}>
      {therapist.recommended && (
        <div className={classes.bagdeWrapper}>
          <img src={recommendedBagde} className={classes.recommendedBagde} alt="Recommended Therapist" />
        </div>
      )}
      <Paper className={classes.paper} elevation={therapist.recommended ? 0 : 1}>
        <Grid container>
          <Grid item xs={12} className={classNames(classes.imageItem, { expanded })}>
            <MediaWidget media={therapist.therapistMedia} top={20} left={20} />
            <ProfileImage
              profileImage={therapist.profileImage}
              altText={`${therapist.firstName} ${therapist.lastName} profile`}
              size={expanded && lg ? 380 : 280}
            />
            <div className={classes.nameAndMatchWrapper}>
              <div className={classes.nameAndMatchBanner}>
                <Tooltip title={licenses} placement="bottom">
                  <Typography className={classes.name}>
                    {therapist.firstName}
                    <span>{licensesShortened}</span>
                  </Typography>
                </Tooltip>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} className={classes.buttons}>
            <ContactDetails
              therapist={therapist}
              setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
              onLearnMore={onLearnMore}
              expanded={expanded}
            />
          </Grid>
          {expanded && <ExpandedSection therapist={therapist} matchIndex={matchIndex} />}
        </Grid>
      </Paper>
    </div>
  );
};

export default TherapistCard;
