import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { v4 as uuidv4 } from "uuid";
import { Box, Grid, Typography, MuiThemeProvider, Slide } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "../theme";
import { trackFormAbandonment } from "../services/analytics";
import { fetchStepDefinition, fetchPreviousStepDefinition } from "../api/form-definition";
import { FormDefinition } from "../types";
import Layout from "./Steps/Layout";
import Wizard from "./Steps/Wizard";
import { SUMMARY, WELCOME } from "../types/FormTypes";
import { hjIdentify, hjTagRecording } from "../../../utils/hotjar";
import { sendFormVisitedEvent } from "../../match-page/events";
import LoadingData from "../../match-page/common/LoadingData";

const useStyles = makeStyles(() => ({
  error: {
    color: "#FF0000",
    fontFamily: "Avenir",
    fontSize: "18px",
    lineHeight: "34px"
  },
  container: {
    height: "100%",
    padding: "0 32px"
  }
}));

const MatchingForm: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const { listen, replace, push } = history;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [formDefinition, setFormDefinition] = useState<FormDefinition>();
  const [savedData, setSavedData] = useState<any>();
  const [nextStepsCount, setNextStepsCount] = useState(0);
  const [slideDirection, setSlideDirection] = useState<"left" | "right">("left");

  const currentStepName = formDefinition ? formDefinition.currentStep : undefined;
  const formQueryString = window.location.search;
  const queryParams = queryString.parse(formQueryString, { parseNumbers: true });
  const version = 5; //queryParams.version as number;
  const uuid = queryParams.uuid as string;
  const demo = queryParams.demo === "true";
  const undefinedFormDefinition = formDefinition === undefined;

  const formId = useMemo(() => (formDefinition ? formDefinition.id : undefined), [formDefinition]);
  const stepLoadingMessage = useMemo(
    () => (formDefinition && formDefinition.step ? formDefinition.step.loadingMessage : undefined),
    [formDefinition]
  );

  const clientFormUuid = useMemo(() => {
    return uuid || uuidv4();
  }, [uuid]);

  const utmParams = useMemo(() => {
    const utmQueryParams = queryString.parse(formQueryString, { parseNumbers: true });
    Object.keys(utmQueryParams)
      .filter(key => !key.startsWith("utm_"))
      .forEach(key => delete utmQueryParams[key]);

    return utmQueryParams;
  }, [formQueryString]);

  useEffect(() => {
    if (undefinedFormDefinition && uuid === undefined) {
      sendFormVisitedEvent({ form_uuid: clientFormUuid, ...utmParams });
    }
  }, [undefinedFormDefinition, uuid, clientFormUuid, utmParams]);

  const errorMessageFromError = useCallback<(error: any) => string>(error => {
    return error.response && error.response.data && error.response.data.errorMessage
      ? error.response.data.errorMessage
      : "Sorry, something went wrong and we couldn't load the form. Reload the page or try again later.";
  }, []);

  const stepPath = useCallback<(currentStep: string) => string>(currentStep => {
    const subURL = currentStep
      .split("::")
      .slice(2)
      .join("/")
      .toLowerCase()
      .replace("step", "");
    return `/get-started/${subURL}${window.location.search}`;
  }, []);

  const goNext = useCallback(() => {
    setLoadingMessage(stepLoadingMessage);
    setLoading(true);
    setSlideDirection("left");
    fetchStepDefinition({
      formId: formId || version,
      formUuid: clientFormUuid,
      formQueryString,
      currentStepName,
      demo,
      savedData,
      callbacks: {
        onSuccess: (response: any) => {
          const { data } = response;
          if (data) {
            const { currentStep, step } = data;

            setFormDefinition(data);
            setSavedData(step.savedData);
            setErrorMessage(undefined);
            setLoading(false);

            if (nextStepsCount === 1) {
              push(stepPath(currentStep));
            } else {
              replace(stepPath(currentStep));
            }

            setNextStepsCount(nextStepsCount + 1);
          }
        },
        onError: (error: any) => {
          setErrorMessage(errorMessageFromError(error));
          setLoading(false);
        },
        onAfter: () => {}
      }
    });
  }, [
    formId,
    stepLoadingMessage,
    clientFormUuid,
    currentStepName,
    formQueryString,
    demo,
    savedData,
    version,
    replace,
    push,
    nextStepsCount,
    stepPath,
    errorMessageFromError
  ]);

  const goBack = useCallback(() => {
    setLoadingMessage(undefined);
    setSlideDirection("right");
    setLoading(true);
    fetchPreviousStepDefinition({
      formId,
      formUuid: clientFormUuid,
      callbacks: {
        onSuccess: (response: any) => {
          const { data } = response;
          if (data) {
            const { currentStep, step } = data;

            setFormDefinition(data);
            setSavedData(step.savedData);
            setErrorMessage(undefined);
            setLoading(false);

            replace(stepPath(currentStep));
          }
        },
        onError: (error: any) => {
          setErrorMessage(errorMessageFromError(error));
          setLoading(false);
        },
        onAfter: () => {}
      }
    });
  }, [formId, clientFormUuid, replace, stepPath, errorMessageFromError]);

  const goBackFromBackButton = useCallback(() => {
    setLoading(true);
    fetchPreviousStepDefinition({
      formId,
      formUuid: clientFormUuid,
      callbacks: {
        onSuccess: (response: any) => {
          const { data } = response;
          if (data) {
            const { currentStep, step } = data;

            setFormDefinition(data);
            setSavedData(step.savedData);
            setErrorMessage(undefined);
            setLoading(false);

            push(stepPath(currentStep));
          }
        },
        onError: (error: any) => {
          setErrorMessage(errorMessageFromError(error));
          setLoading(false);
        },
        onAfter: () => {}
      }
    });
  }, [formId, clientFormUuid, push, stepPath, errorMessageFromError]);

  useEffect(() => {
    if (formDefinition && formDefinition.step.transitionTime) {
      const handler = setTimeout(() => goNext(), formDefinition.step.transitionTime);
      return () => clearTimeout(handler);
    }
  }, [goNext, formDefinition]);

  useEffect(() => {
    const unListen = listen((_, action) => {
      if (action === "POP") {
        goBackFromBackButton();
      }
    });

    return () => unListen();
  }, [listen, goBackFromBackButton]);

  useEffect(() => {
    if (formDefinition) {
      // const virtualURL = `${window.location.origin}/get-started/${subURL}`;
      // window.VWO = window.VWO || [];
      // window.VWO.push(["activate", { virtualPageUrl: virtualURL }]);

      const stepNames = formDefinition.currentStep.split("::").slice(2);
      hjTagRecording(`Client Form | ${stepNames.join("::")}`);
      hjIdentify(`c_${formDefinition.clientId}`);
    }

    const handler = () => {
      if (formDefinition && formId && formDefinition.step.type !== SUMMARY) {
        const title =
          formDefinition.step.type === WELCOME ? "Welcome to your wellbeing -- Begin" : formDefinition.step.title;
        trackFormAbandonment(formDefinition.abTest ? formId + 1 : formId, title);
      }
    };

    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [formDefinition, formId, history]);

  return (
    <>
      <Helmet>
        <title>Get Matched — MyWellbeing</title>
        <link rel="canonical" href="https://mywellbeing.com/get-started" />
      </Helmet>

      <MuiThemeProvider theme={Theme}>
        {/*<FullStory org={FULLSTORY_ORG_ID} host="fullstory.com" namespace="FS" />*/}
        <CssBaseline />
        <Layout formDefinition={formDefinition} goBack={goBack} loading={loading}>
          {loading && (
            <Box display="flex" justifyContent="center" height="100%" alignItems="center">
              <LoadingData text={loadingMessage} />
            </Box>
          )}
          {!loading && (
            <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
              <Box display="flex" justifyContent="center" height="100%" alignItems="center">
                {!errorMessage && (
                  <Wizard
                    formDefinition={formDefinition}
                    setSavedData={setSavedData}
                    savedData={savedData}
                    goNext={goNext}
                  />
                )}
                {errorMessage && (
                  <Grid container justify="center" alignItems="center" className={classes.container}>
                    <Typography variant="body1" align="center" className={classes.error}>
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Slide>
          )}
        </Layout>
      </MuiThemeProvider>
    </>
  );
};

// @ts-ignore
export default MatchingForm;
