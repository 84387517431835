import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";
import availability from "../assets/availability.svg";
import fee from "../assets/fee.svg";
import age from "../assets/age.svg";
import orientation from "../assets/orientation.svg";
import state from "../assets/state.svg";
import expertise from "../assets/expertise.svg";
import gender from "../assets/gender.svg";
import style from "../assets/style.svg";
import raceEthnicity from "../assets/race_ethnicity.svg";
import { MatchDataContextType } from "../types";
import Tooltip from "../../common/Tooltip";
import { withMatchData } from "../common/MatchDataProvider";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      fontSize: 20
    },
    "&.alignLeft": {
      textAlign: "left"
    }
  },
  wrapper: {
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: 14
    }
  },
  icon: {
    height: 30,
    display: "block",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  iconTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  }
}));

const Item: React.FC<{ title: string; icon: string; wide?: boolean }> = ({ title, icon, wide = false, children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={wide ? 12 : 4} sm={2} md>
      <div className={classes.wrapper}>
        <img src={icon} className={classes.icon} alt={title} />
        <Typography className={classes.iconTitle}>{title}</Typography>
        {children}
      </div>
    </Grid>
  );
};

const Preferences: React.FC<{
  title: string;
  matchDataContext: MatchDataContextType;
  preferenceSetIndex: number;
  alignLeft: boolean;
  hideInsurance?: boolean;
}> = ({ matchDataContext, title, preferenceSetIndex, alignLeft, hideInsurance }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const alignment = mobile ? "center" : "left";

  const { clientPreference } = matchDataContext.matches[preferenceSetIndex];

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" className={classNames(classes.title, { alignLeft })}>
          {title}
        </Typography>
      </Grid>

      {clientPreference.feeRanges.length > 0 && (
        <Item title="Fee" icon={fee}>
          {clientPreference.feeRanges.map(item => (
            <Typography align={alignment} key={item.id}>
              {item.name}
            </Typography>
          ))}
        </Item>
      )}

      {clientPreference.ages.length > 0 && (
        <Item title="Age" icon={age}>
          {clientPreference.ages.map(item => (
            <Typography align={alignment} key={item.id}>
              {item.name}
            </Typography>
          ))}
        </Item>
      )}

      {clientPreference.lgbtqia.value && (
        <Item title="Orientation" icon={orientation}>
          <Typography align={alignment}>LGBTQIA+</Typography>
        </Item>
      )}

      {clientPreference.state && (
        <Item title="State" icon={state}>
          <Box display="flex" alignItems="center" justifyContent={mobile ? "center" : "flex-start"}>
            <Typography align={alignment}>{clientPreference.state.name}</Typography>
            <Tooltip
              title={
                <Typography variant="body2">
                  Your mental health provider must be eligible to work in the state in which you reside, even if the
                  care is provided by video or by phone. You are only matched with providers who are licensed or
                  eligible to work in your state of residence. Please note: therapists must be licensed in your state
                  and therapy is covered in- or out-of-network by insurance, while coaching and holistic care can work
                  across state lines but is not covered by insurance.
                </Typography>
              }
            />
          </Box>
        </Item>
      )}

      {clientPreference.genders.length > 0 && (
        <Item title="Gender" icon={gender}>
          {clientPreference.genders.map(item => (
            <Typography align={alignment} key={item.id}>
              {item.name}
            </Typography>
          ))}
        </Item>
      )}

      {clientPreference.styles.length > 0 && (
        <Item title="Style" icon={style}>
          {clientPreference.styles.map(item => (
            <Typography align={alignment} key={item.id}>
              {item.name}
            </Typography>
          ))}
        </Item>
      )}

      {clientPreference.ethnicities.length > 0 && (
        <Item title="Race/Ethnicity" icon={raceEthnicity}>
          {clientPreference.ethnicities.map(item => (
            <Typography align={alignment} key={item.id} title={item.name}>
              {item.name}
            </Typography>
          ))}
          {clientPreference.otherEthnicity && (
            <Typography align={alignment}>
              Other:
              {clientPreference.otherEthnicity}
            </Typography>
          )}
        </Item>
      )}

      {clientPreference.availabilities.length > 0 && (
        <Item title="Availability" icon={availability} wide={mobile}>
          {clientPreference.availabilities.map(item => (
            <Typography align={alignment} key={item.id} title={item.name}>
              {item.name}
            </Typography>
          ))}
        </Item>
      )}

      {clientPreference.specializations.length > 0 && (
        <Item title="Expertise" icon={expertise} wide={mobile}>
          {clientPreference.specializations.map(item => (
            <Typography align={alignment} key={item.id} title={item.name}>
              {item.name}
            </Typography>
          ))}
        </Item>
      )}

      {!hideInsurance && (
        <Grid item xs={12}>
          <Typography align={alignLeft ? "left" : "center"} style={{ fontSize: 20, fontWeight: "bold" }}>
            Insurance:
          </Typography>
          <Typography align={alignLeft ? "left" : "center"} style={{ marginBottom: 8 }}>
            Click into each provider card to learn what insurance they are in-network with or if they are an
            out-of-network only provider.
          </Typography>
          <Typography align={alignLeft ? "left" : "center"}>
            Even if your provider does not accept insurance in-network, you can still save 30-100% of the session fee by
            using &quot;out-of-network benefits.&quot; See how much you are eligible to save by inputting your insurance
            card info&nbsp;<a href="#mentaya">into this instant calculator.</a>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default withMatchData(Preferences);
