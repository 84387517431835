import React from "react";
import { makeStyles, Typography, useMediaQuery, useTheme, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  link: {
    whiteSpace: "nowrap",
    color: "inherit",
    "&:hover": {
      color: "inherit"
    },
    "&:active": {
      color: "inherit"
    },
    "&:visited": {
      color: "inherit"
    }
  },
  text: {
    fontFamily: "Tiempos, Times, serif",
    fontSize: 14,
    maxWidth: 1200,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11
    }
  }
}));

const CrisisResources: React.FC<{
  color?: "primary" | "secondary" | "inherit";
  align?: "inherit" | "left" | "center" | "right" | "justify";
  h6?: boolean;
}> = ({ align, color, h6 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box display="flex" justifyContent="center">
      <Typography align={align} variant={h6 && !mobile ? "h6" : "body1"} color={color} className={classes.text}>
        If you are in immediate need of services, feeling unsafe in this moment, or considering hurting yourself or
        someone else, please do not use this site. Instead,{" "}
        <a
          href="https://mywellbeing.com/crisis-resources"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          use these crisis resources.
        </a>{" "}
        In an emergency,{" "}
        <a href="tel:988" className={classes.link}>
          call 988
        </a>{" "}
        or visit your local emergency room.
      </Typography>
    </Box>
  );
};

export default CrisisResources;
