import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { trackPage } from "../common/utils/analytics";

const PageViewAnalytics: React.FC<{ location: any }> = ({ location }) => {
  useEffect(() => {
    trackPage(location.pathname, document.title);
  }, [location.pathname]);

  return null;
};

export default withRouter(PageViewAnalytics);
