import React from "react";
import classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MaterialButton from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    height: 56,
    borderRadius: 28,
    fontSize: 18,
    lineHeight: "18px",
    textTransform: "none",
    "&.smallFont": {
      fontSize: 16
    },
    "&.bigFont": {
      fontSize: 20
    },
    "&.bold": {
      fontWeight: 800
    },
    [theme.breakpoints.down("sm")]: {
      height: 48,
      borderRadius: 24
    }
  },
  width: {
    width: 280
  },
  disabled: {
    backgroundColor: "#c7c7c7 !important"
  }
}));

const Button: React.FC<{
  onClick?: (event: React.MouseEvent) => void;
  color: "default" | "primary" | "secondary" | "inherit";
  variant?: "contained" | "outlined";
  href?: string;
  noWidth?: boolean;
  disabled?: boolean;
  id?: string;
  smallFont?: boolean;
  bigFont?: boolean;
  bold?: boolean;
}> = ({ children, onClick, color, variant, href, noWidth, disabled, id, smallFont, bigFont, bold }) => {
  const classes = useStyles();

  return (
    <MaterialButton
      color={color}
      disabled={disabled}
      variant={variant || "contained"}
      onClick={onClick}
      href={href}
      classes={{
        root: classnames(classes.button, { [classes.width]: !noWidth, smallFont, bigFont, bold }),
        disabled: classes.disabled
      }}
      id={id}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
