import React, { SetStateAction, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { MatchDataContextType, PreferencesDataContextType } from "../../../types";
import { OrientationType, IdNameType } from "../../../../therapist-app/profile/types";
import availabilityIcon from "../../../assets/availability.svg";
import feeIcon from "../../../assets/fee.svg";
import ageIcon from "../../../assets/age.svg";
import orientationIcon from "../../../assets/orientation.svg";
import stateIcon from "../../../assets/state.svg";
import expertiseIcon from "../../../assets/expertise.svg";
import genderIcon from "../../../assets/gender.svg";
import styleIcon from "../../../assets/style.svg";
import raceEthnicityIcon from "../../../assets/race_ethnicity.svg";
import Preference from "./Preference";
import { withPreferencesData } from "../../../common/PreferencesDataProvider";
import { havePreferencesChanged, updatePreferences } from "../../../preferences";
import { withMatchData } from "../../../common/MatchDataProvider";
import { withNotification } from "../../../../therapist-app/notification/NotificationProvider";

export type UpdatePreferencesProps = {
  matchDataContext: MatchDataContextType;
  preferencesDataContext: PreferencesDataContextType;
  sendUpdate: boolean;
  setSendUpdate: React.Dispatch<SetStateAction<boolean>>;
  preferenceSetIndex: number;
  setOpenCarouselIndex: React.Dispatch<SetStateAction<number>>;
  setSubmitButtonCopy: React.Dispatch<SetStateAction<string>>;
  setSubmitButtonDisabled: React.Dispatch<SetStateAction<boolean>>;
  notificationContext: any;
  setNoMoreTherapists: React.Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles(theme => ({
  updatePreferences: {
    width: "100%",
    padding: theme.spacing(3),
    paddingBottom: 0
  },
  paper: {
    borderRadius: 40,
    marginBottom: theme.spacing(0.5),
    minHeight: 460
  },
  imageItem: {
    position: "relative",
    marginTop: theme.spacing(2.5)
  },
  icon: {
    height: 160,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  cardCopy: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
    textAlign: "justify"
  },
  showMoreButton: {
    alignSelf: "center"
  },
  buttons: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    },
    display: "flex",
    flexDirection: "column-reverse"
  }
}));

const UpdatePreferences: React.FC<UpdatePreferencesProps> = ({
  matchDataContext,
  preferencesDataContext,
  sendUpdate,
  setSendUpdate,
  preferenceSetIndex,
  setOpenCarouselIndex,
  setSubmitButtonCopy,
  setSubmitButtonDisabled,
  notificationContext,
  setNoMoreTherapists
}) => {
  const classes = useStyles();
  const { availability, fee, age, gender, raceEthnicity, expertise, style, orientation } = preferencesDataContext;
  const { matches, loadData } = matchDataContext;
  const { clientPreference, therapists } = matches[preferenceSetIndex];
  const { canSeeMoreMatches, canUpdate } = clientPreference;

  const [pickedAvailabilities, setPickedAvailabilities] = useState<IdNameType[]>(clientPreference.availabilities);
  const [pickedFees, setPickedFees] = useState<IdNameType[]>(clientPreference.feeRanges);
  const [pickedAge, setPickedAge] = useState<IdNameType | null>(
    clientPreference.ages ? clientPreference.ages[0] : null
  );
  const [pickedOrientation, setPickedOrientation] = useState<OrientationType>(clientPreference.lgbtqia);
  const [pickedGender, setPickedGender] = useState<IdNameType | null>(
    clientPreference.genders ? clientPreference.genders[0] : null
  );
  const [pickedEthnicity, setPickedEthnicity] = useState<IdNameType[]>(clientPreference.ethnicities);
  const [pickedExpertise, setPickedExpertise] = useState<IdNameType[]>(clientPreference.specializations);
  const [pickedStyle, setPickedStyle] = useState<IdNameType[]>(clientPreference.styles);

  useEffect(() => {}, [pickedAvailabilities, pickedFees, pickedEthnicity, pickedExpertise, pickedStyle]);

  useEffect(() => {
    const preferencesFromForm = {
      availabilities: pickedAvailabilities,
      feeRanges: pickedFees,
      ages: [pickedAge],
      lgbtqia: pickedOrientation,
      genders: [pickedGender],
      ethnicities: pickedEthnicity,
      specializations: pickedExpertise,
      styles: pickedStyle
    };

    if (havePreferencesChanged(clientPreference, preferencesFromForm)) {
      setSubmitButtonCopy("Submit for new matches");
      setNoMoreTherapists(false);

      const preferencesMissing = [pickedAvailabilities, pickedFees, pickedEthnicity, pickedExpertise, pickedStyle].some(
        item => item.length === 0
      );
      setSubmitButtonDisabled(preferencesMissing || !canUpdate);
    } else {
      setSubmitButtonCopy("See more, same preferences");
      if (!canSeeMoreMatches) {
        setSubmitButtonDisabled(true);
        setNoMoreTherapists(true);
      }
    }

    if (sendUpdate) {
      updatePreferences(
        {
          clientPreference,
          preferences: preferencesFromForm
        },
        () => {
          setOpenCarouselIndex(-1);
          loadData();
          setSendUpdate(false);
          setSubmitButtonDisabled(false);
        },
        () => {
          notificationContext.displayErrorNotification("An error occurred. Please refresh the page and try again");
          setSendUpdate(false);
          setSubmitButtonDisabled(false);
        }
      );
    }
  }, [
    sendUpdate,
    pickedAvailabilities,
    pickedFees,
    pickedAge,
    pickedOrientation,
    pickedGender,
    pickedEthnicity,
    pickedExpertise,
    pickedStyle,
    therapists,
    loadData,
    classes,
    clientPreference,
    notificationContext,
    setNoMoreTherapists,
    setOpenCarouselIndex,
    setSendUpdate,
    setSubmitButtonCopy,
    setSubmitButtonDisabled,
    canSeeMoreMatches,
    canUpdate
  ]);

  return (
    <div className={classes.updatePreferences}>
      <Grid container justify="center" spacing={1}>
        <Preference
          title="AVAILABILITY"
          options={availability}
          icon={availabilityIcon}
          disabled={false}
          currentState={pickedAvailabilities}
          update={setPickedAvailabilities}
          type="multi-select"
        />
        <Preference
          title="FEE"
          options={fee}
          icon={feeIcon}
          disabled={false}
          currentState={pickedFees}
          update={setPickedFees}
          type="multi-select"
        />
        <Preference
          title="AGE"
          options={age}
          icon={ageIcon}
          disabled={false}
          currentState={pickedAge}
          update={setPickedAge}
          type="select"
        />
        <Preference
          title="ORIENTATION"
          options={orientation}
          icon={orientationIcon}
          disabled={false}
          currentState={pickedOrientation}
          update={setPickedOrientation}
          type="select"
        />
        <Preference
          title="GENDER"
          options={gender}
          icon={genderIcon}
          disabled={false}
          currentState={pickedGender}
          update={setPickedGender}
          type="select"
        />
        <Preference
          title="RACE/ETHNICITY"
          options={raceEthnicity}
          icon={raceEthnicityIcon}
          disabled={false}
          currentState={pickedEthnicity}
          update={setPickedEthnicity}
          type="multi-select"
        />
        <Preference
          title="EXPERTISE"
          options={expertise}
          icon={expertiseIcon}
          disabled={false}
          currentState={pickedExpertise}
          update={setPickedExpertise}
          type="multi-select"
          limit={5}
        />
        <Preference
          title="STYLE"
          options={style}
          icon={styleIcon}
          disabled={false}
          currentState={pickedStyle}
          update={setPickedStyle}
          type="multi-select"
        />
        {clientPreference.state && (
          <Preference
            title="STATE"
            icon={stateIcon}
            options={[]}
            disabled
            currentState={[clientPreference.state]}
            update={() => null}
            type="multi-select"
          />
        )}
      </Grid>
    </div>
  );
};

export default withNotification(withPreferencesData(withMatchData(UpdatePreferences)));
