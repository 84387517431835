import React from "react";
import classnames from "classnames";
import { makeStyles, Grid, Box } from "@material-ui/core";
import Header from "../Header/Header";
import { FormDefinition } from "../../types";
import StepsProgress from "../StepsProgress";
import { INTRO, SUBMIT, SUMMARY, WELCOME } from "../../types/FormTypes";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    background: "#CFE3ED"
  },
  mainContentWrapper: {
    backgroundColor: "inherit",
    overflowX: "clip"
  },
  mainContent: {
    height: "100%",
    padding: `0 ${theme.spacing(2)}px`,
    "&.intro": {
      padding: 0
    }
  },
  header: {
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 10,
    backgroundColor: "inherit",
    "&.absolute": {
      position: "absolute",
      backgroundColor: "transparent"
    }
  },
  progress: {
    position: "sticky",
    bottom: 0,
    backgroundColor: "inherit"
  }
}));

const Layout: React.FC<{
  formDefinition?: FormDefinition;
  goBack: () => void;
  loading: boolean;
}> = ({ children, formDefinition, goBack }) => {
  const classes = useStyles();
  const { completedStepsCount, totalStepsCount } = formDefinition || {};
  const stepType = formDefinition && formDefinition.step && formDefinition.step.type;
  const showBackButton = stepType && ![WELCOME, SUMMARY].includes(stepType);
  const showProgress = stepType && ![WELCOME, SUMMARY, SUBMIT].includes(stepType);
  const isChapterScreen = stepType === undefined || [INTRO, SUBMIT, WELCOME].includes(stepType);

  return (
    <Grid container direction="column" className={classnames(classes.container)} wrap="nowrap">
      <Grid item className={classnames(classes.header, { absolute: isChapterScreen })}>
        <Header goBack={showBackButton ? goBack : undefined} />
      </Grid>
      <Grid item xs className={classes.mainContentWrapper}>
        <Box height="100%" px={isChapterScreen ? 0 : 2}>
          {children}
        </Box>
      </Grid>
      {showProgress && (
        <Grid item className={classes.progress}>
          <StepsProgress completedStepsCount={completedStepsCount} totalStepsCount={totalStepsCount} />
        </Grid>
      )}
    </Grid>
  );
};

export default Layout;
