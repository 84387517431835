import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import ChapterLayout from "./ChapterLayout";
import Button from "../common/Button";
import queryString from "query-string";
import VideoInstruction from "../../../common/VideoInstruction";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "3rem",
    fontFamily: "Tiempos, Times, serif",
    maxWidth: "900px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem"
    }
  },
  subtitle: {
    fontFamily: "Tiempos, Times, serif",
    margin: "0 10%"
  }
}));

type WelcomeProps = {
  onNext: () => void;
};

const Welcome: React.FC<WelcomeProps> = ({ onNext }) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const formQueryString = window.location.search;
  const queryParams = queryString.parse(formQueryString, { parseNumbers: true });
  const uuid = queryParams.uuid as string;

  return (
    <Grid justify="center">
      <Box mt={2}>
        <Typography variant="h1" align="center" className={classes.title}>
          Connect with the right therapist&nbsp;or&nbsp;coach for <u>you</u>.
        </Typography>
      </Box>

      <Box mt={mobile ? 2 : 4}>
        <Typography variant={mobile ? "h6" : "h5"} align="center" className={classes.subtitle}>
          Get personalized matches based on things like insurance, issue area, and more.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" marginTop={mobile ? "32px" : "52px"} mb={2}>
        <Button color="primary" onClick={onNext} bigFont bold>
          {uuid ? "Continue..." : "Find Your Provider"}
        </Button>
      </Box>
      {!uuid && (
        <Typography align="center">
          <b>&lt;5 minutes</b>
        </Typography>
      )}
    </Grid>
  );

  // return (
  //   <ChapterLayout
  //     title={<Title />}
  //     backgroundImageUrl={backgroundImageUrl}
  //     backgroundMobileImageUrl={backgroundMobileImageUrl}
  //   >
  //     <Typography variant="h6" align="center" className={classes.subtitle}>
  //       Share your preferences and we'll filter through thousands of options to find the best 3 matches for you.
  //     </Typography>
  //     <Box display="flex" justifyContent="center" marginTop={mobile ? "32px" : "52px"}>
  //       <Button color="primary" onClick={onNext}>
  //         {uuid ? "Continue..." : "Find Your Provider"}
  //       </Button>
  //     </Box>
  //     <Box display="flex" justifyContent="center" marginTop={mobile ? "8px" : "32px"} id="video">
  //       <VideoInstruction videoId="dSsZEojW" title="Wondering where to start?" subtitle="1 min" />
  //     </Box>
  //   </ChapterLayout>
  // );
};

export default Welcome;
