import FormData from "form-data";
import { axiosInstance, attachCallbacks } from "../../utils/api";
import authenticationService from "./authentication/authenticationService";

const authenticatedRequest = ({ url, method, data, callbacks, authContext, headers = {} }) => {
  const { onSuccess = () => {}, onError = () => {}, onAfter = () => {} } = callbacks;

  axiosInstance
    .request({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${authenticationService.getToken()}`,
        ...headers
      }
    })
    .then(response => {
      onSuccess(response);
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        authContext.logOut();
      } else {
        onError(error);
      }
    })
    .then(() => onAfter());
};

export const signupRequest = ({ firstName, lastName, email, password, authToken, referral, captcha, callbacks }) => {
  attachCallbacks(
    axiosInstance.post("/signup", {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      auth_token: authToken,
      referral,
      captcha
    }),
    callbacks
  );
};

export const invitedUserRequest = ({ authToken, callbacks }) => {
  attachCallbacks(axiosInstance.get(`/invited_user?auth_token=${authToken}`), callbacks);
};

export const loginRequest = ({ email, password, callbacks }) => {
  attachCallbacks(
    axiosInstance.post("/login", {
      email,
      password
    }),
    callbacks
  );
};

export const changePasswordRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/change_password",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const resetPasswordRequest = ({ email, callbacks }) => {
  attachCallbacks(
    axiosInstance.post("/request_password_reset", {
      email
    }),
    callbacks
  );
};

export const confirmResetPasswordRequest = ({ email, token, callbacks }) => {
  attachCallbacks(
    axiosInstance.post("/confirm_reset_password", {
      email,
      reset_password_token: token
    }),
    callbacks
  );
};

export const activateAccountRequest = ({ token, callbacks }) => {
  attachCallbacks(axiosInstance.post("/activate", { auth_token: token }), callbacks);
};

export const onboardingUserDataRequest = ({ userId, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/onboarding/user_data${userId ? `?user_id=${userId}` : ""}`,
    method: "get",
    data: {},
    callbacks,
    authContext
  });
};

export const setAccountTypeRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/onboarding/account_type",
    method: "patch",
    data,
    callbacks,
    authContext
  });
};

export const documentSignatureRequest = ({ userId, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/onboarding/request_document_signature${userId ? `?user_id=${userId}` : ""}`,
    method: "get",
    data: {},
    callbacks,
    authContext
  });
};

export const resetPassword = ({ password, email, token, callbacks }) => {
  attachCallbacks(
    axiosInstance.post("/reset_password", {
      password,
      email,
      reset_password_token: token
    }),
    callbacks
  );
};

export const fetchProfileRequest = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/profile",
    method: "get",
    data: {},
    callbacks,
    authContext
  });
};

export const fetchResourcesRequest = ({ callbacks }) => {
  attachCallbacks(axiosInstance.get("/resources"), callbacks);
};

export const fetchFiltersRequest = ({ callbacks }) => {
  attachCallbacks(axiosInstance.get("/resources/filters"), callbacks);
};

export const fetchGroupDashboardDataRequest = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/data",
    method: "get",
    data: {},
    callbacks,
    authContext
  });
};

export const addGroupTherapistsRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/add_therapist",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const buySubscriptionRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/buy_subscription",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const addGroupAdminRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/add_admin",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const removeGroupAdminRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/remove_admin",
    method: "delete",
    data,
    callbacks,
    authContext
  });
};

export const resendInvitationRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/resend_invitation",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const removePcFromGroupRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/remove_pc_therapist",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const activatePcMatchingRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/groups/activate_pc_matching",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const updateProfileRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/profile",
    method: "patch",
    data,
    callbacks,
    authContext
  });
};

export const updateUserRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/user",
    method: "patch",
    data,
    callbacks,
    authContext
  });
};

export const updateYourContentRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/profile/your_content",
    method: "patch",
    data,
    callbacks,
    authContext
  });
};

export const updateProfileImageRequest = ({ image, callbacks, authContext }) => {
  const formData = new FormData();
  formData.append("profile_image", image);

  authenticatedRequest({
    url: "/profile/image",
    method: "post",
    data: formData,
    callbacks,
    authContext,
    headers: { contentType: "multipart/form-data" }
  });
};

export const removeProfileImageRequest = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/profile/image",
    method: "delete",
    data: {},
    callbacks,
    authContext
  });
};

export const fetchClientTrackerData = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/clients",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const enrollClientRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/enroll",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const archiveClientRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/archive",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const changeClientStatusRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/close_session",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const restartTherapyRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/restart_therapy",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const enrollArchivedClientRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/enroll_archived",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const unarchiveTherapyDetailRequest = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/client_tracker/unarchive",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const fetchTherapyDetail = ({ therapyDetailId, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/therapy_details/${therapyDetailId}`,
    method: "get",
    callbacks,
    authContext
  });
};

export const updateTherapySession = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/therapy_sessions/${data.id}`,
    method: "patch",
    data,
    callbacks,
    authContext
  });
};

export const deleteTherapySession = ({ therapySessionId, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/therapy_sessions/${therapySessionId}`,
    method: "delete",
    callbacks,
    authContext
  });
};

export const createContactLog = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/therapy_contact_logs`,
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const updateContactLog = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/therapy_contact_logs/${data.id}`,
    method: "patch",
    data,
    callbacks,
    authContext
  });
};

export const deleteContactLog = ({ contactLogId, callbacks, authContext }) => {
  authenticatedRequest({
    url: `/therapy_contact_logs/${contactLogId}`,
    method: "delete",
    callbacks,
    authContext
  });
};

export const fetchTherapists = ({ data, callbacks }) => {
  let url = "/therapists?";
  if (data.stateName) {
    url = `${url}state_name=${data.stateName}&`;
  }
  if (data.keyword) {
    url = `${url}keyword=${data.keyword}&`;
  }

  url = `${url}${(data.search || "").replace("?", "")}`;

  attachCallbacks(axiosInstance.get(url), callbacks);
};

export const fetchTherapist = ({ id, callbacks }) => {
  attachCallbacks(axiosInstance.get(`/therapists/${id}`), callbacks);
};

export const sendEmailToTherapist = ({ id, data, callbacks }) => {
  attachCallbacks(axiosInstance.post(`/therapists/${id}/send_message`, data), callbacks);
};

export const requestExternalCheckoutSession = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.post("/external_payments/checkout", data), callbacks);
};

export const requestExternalCheckoutAddCardSession = ({ data, callbacks }) => {
  attachCallbacks(axiosInstance.post("/external_payments/checkout_add_card", data), callbacks);
};

export const requestCheckoutSession = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    data,
    url: "/payments/checkout",
    method: "post",
    callbacks,
    authContext
  });
};

export const requestCustomerPortalSession = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    data,
    url: "/payments/create_customer_portal_session",
    method: "post",
    callbacks,
    authContext
  });
};

export const requestPaymentLink = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    data,
    url: "/payments/get_link",
    method: "post",
    callbacks,
    authContext
  });
};

export const fetchSubscriptionsRequest = ({ callbacks, authContext, userId }) => {
  authenticatedRequest({
    url: `/payments/subscriptions${userId ? `?user_id=${userId}` : ""}`,
    method: "get",
    callbacks,
    authContext
  });
};

export const fetchProducts = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/payments/products",
    method: "get",
    callbacks,
    authContext
  });
};

export const fetchProductsV1 = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "payments/products_v1",
    method: "get",
    callbacks,
    authContext
  });
};

export const requestUpdatePaymentMethodSession = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    data,
    url: "/payments/request_payment_update",
    method: "post",
    callbacks,
    authContext
  });
};

export const requestRemovePaymentMethod = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/payments/request_payment_remove",
    method: "post",
    callbacks,
    authContext
  });
};

export const fetchTestimonialQuote = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/testimonial_quote",
    method: "get",
    callbacks,
    authContext
  });
};

export const requestSendReferralEmails = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    data,
    url: "/referrals/send_emails",
    method: "post",
    callbacks,
    authContext
  });
};

export const updateSessionModes = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/profile/session_modes",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const requestMediaUploadLink = ({ data, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/media",
    method: "post",
    data,
    callbacks,
    authContext
  });
};

export const fetchTherapistMedia = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/media",
    method: "get",
    callbacks,
    authContext
  });
};

export const updateMediaStatus = ({ status, callbacks, authContext }) => {
  authenticatedRequest({
    url: "/media",
    method: "put",
    data: { status },
    callbacks,
    authContext
  });
};

export const removeTherapistMedia = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/media",
    method: "delete",
    callbacks,
    authContext
  });
};

export const markProductUpdateMessagesAsRead = ({ callbacks, authContext }) => {
  authenticatedRequest({
    url: "/profile/mark_product_update_messages_as_read",
    method: "post",
    callbacks,
    authContext
  });
};
