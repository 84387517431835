import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";

const useStyles = makeStyles(theme => ({
  fullDialog: {
    height: "calc(100% - 40px)",
    width: "calc(100% - 40px)",
    maxWidth: 1024,
    margin: "20px auto"
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: 8
  },
  title: {
    padding: "12px 0"
  }
}));

const Modal: React.FC<{
  children: ReactElement;
  title?: string;
  fullScreen?: boolean;
  isOpen: boolean;
  onClose: () => void;
}> = ({ children, title, fullScreen, isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onClose}
      classes={{ root: fullScreen ? classes.fullDialog : undefined, paper: classes.content }}
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {title && (
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container justify="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Dialog>
  );
};

export default Modal;
