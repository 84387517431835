import React, { ReactNode } from "react";
import classnames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery, Box } from "@material-ui/core";

const useStyles = (props: { backgroundImageUrl: string; backgroundMobileImageUrl: string }) =>
  makeStyles(theme => ({
    container: {
      height: "100%",
      color: "black"
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      padding: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2)
      }
    },
    background: {
      backgroundSize: "cover",
      backgroundImage: `url(${props.backgroundImageUrl})`,
      [theme.breakpoints.down("sm")]: {
        backgroundImage: `url(${props.backgroundMobileImageUrl})`
      }
    },
    fullHeight: {
      height: "100%"
    },
    center: {
      alignItems: "center",
      display: "flex"
    },
    subtitle: {
      fontFamily: "Tiempos, Times, serif",
      margin: "0 10%"
    },
    button: {
      fontSize: 20,
      borderBottom: "2px solid black",
      padding: 6,
      marginTop: 24,
      "&:hover": {
        paddingBottom: 4,
        borderBottomWidth: 4
      }
    }
  }));

type ChapterLayoutProps = {
  title: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  backgroundImageUrl: string;
  backgroundMobileImageUrl: string;
};

const ChapterLayout: React.FC<ChapterLayoutProps> = ({
  title,
  footer,
  children,
  backgroundImageUrl,
  backgroundMobileImageUrl
}) => {
  const theme = useTheme();
  const classes = useStyles({ backgroundImageUrl, backgroundMobileImageUrl })();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      direction={mobile ? "column" : "row"}
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs md={6} className={classnames(classes.content, classes.background)}>
        {mobile && title}
      </Grid>
      <Grid item xs md={6} className={classes.content}>
        <Grid container direction={mobile ? "row" : "column"} className={mobile ? undefined : classes.fullHeight}>
          <Grid item xs={mobile ? 12 : undefined} md className={classes.center}>
            <Box width="100%">
              {!mobile && title}
              {children}
            </Box>
          </Grid>
          <Grid item xs={mobile ? 12 : undefined}>
            {footer}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChapterLayout;
