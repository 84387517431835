import React, { FC } from "react";
import LogoAndMessagePage from "./LogoAndMessagePage";

const LoadError: FC<{ code: number }> = ({ code }) => {
  if (code === 401) {
    return (
      <LogoAndMessagePage
        message={
          <>
            We are sorry but it looks like this url is incorrect.
            <br />
            Please make sure that you have copied the link provided in the email.
          </>
        }
      />
    );
  }

  return (
    <LogoAndMessagePage message="We are sorry but something went wrong. Please refresh the page or try again later." />
  );
};

export default LoadError;
