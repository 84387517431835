export const hjTagRecording = tag => {
  if (window.hj) {
    window.hj("tagRecording", [tag]);
  }
};

export const hjIdentify = (id, attributes = {}) => {
  if (window.hj) {
    window.hj("identify", id, attributes);
  }
};
