import React, { Dispatch, SetStateAction } from "react";
import DragAndDropForm from "../Forms/DragAndDropForm/DragAndDropForm";
import SelectionForm from "../Forms/SelectionForm/SelectionForm";
import SliderForm from "../Forms/SliderForm/SliderForm";
import TextInputForm, { TextData } from "../Forms/TextInputForm/TextInputForm";
import { FormDefinition } from "../../types";
import {
  WELCOME,
  INTRO,
  MULTIPLE_SELECTION,
  SINGLE_SELECTION,
  SLIDER_SELECTION,
  SORTABLE_SELECTION,
  TEXT_INPUT,
  SUBMIT,
  SUMMARY,
  GRAPH_SELECTION,
  SINGLE_SELECTION_WITH_TEXT,
  MULTIPLE_SELECTION_WITH_TEXT,
  TEXT,
  SINGLE_SELECTION_DROPDOWN
} from "../../types/FormTypes";
import Intro from "./Intro";
import Welcome from "./Welcome";
import SubmitForm from "../Forms/SubmitForm/SubmitForm";
import Summary from "./Summary";
import GraphForm from "../Forms/GraphForm/GraphForm";
import SelectionWithTextForm, { SelectionWithTextData } from "../Forms/SelectionWithTextForm/SelectionWithTextForm";
import TextForm from "../Forms/TextForm/TextForm";
import SelectionWithDropdownForm from "../Forms/SelectionWithDropdownForm/SelectionWithDropdownForm";

export type WizardProps = {
  formDefinition?: FormDefinition;
  goNext: () => void;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: any;
};

const Wizard: React.FC<WizardProps> = ({ formDefinition, goNext, setSavedData, savedData }) => {
  if (!formDefinition) {
    return <Welcome onNext={goNext} />;
  }

  const { step, clientId } = formDefinition;

  if (step.type === WELCOME) {
    return <Welcome onNext={goNext} />;
  }

  if (step.type === INTRO) {
    return (
      <Intro
        title={step.title}
        subtitle={step.subtitle}
        header={step.header}
        backgroundImageUrl={step.backgroundImageUrl}
        backgroundMobileImageUrl={step.backgroundMobileImageUrl}
        onNext={goNext}
      />
    );
  }

  if (step.type === SUBMIT) {
    return (
      <SubmitForm
        title={step.title}
        subtitle={step.subtitle}
        subtitle2={step.subtitle2}
        setSavedData={setSavedData}
        savedData={savedData as TextData}
        onNext={goNext}
        topLink={step.topLink}
        isNumericalFiled={step.extra ? step.extra.isNumericalFiled : undefined}
        isEmailField={step.extra ? step.extra.isEmailField : undefined}
        maskFormat={step.extra && step.extra.maskFormat}
        minLength={step.extra && step.extra.minLength}
        maxLength={step.extra && step.extra.maxLength}
        minValue={step.extra && step.extra.minValue}
        maxValue={step.extra && step.extra.maxValue}
        adornment={step.extra && step.extra.adornment}
        textInputLabel={step.extra && step.extra.textInputLabel}
        textPlaceholder={step.extra && step.extra.textPlaceholder}
        customErrorMessage={step.customErrorMessage}
        validationMessage={step.validationMessage}
        checkboxesDetails={step.extra && step.extra.checkboxesDetails}
      />
    );
  }

  if (step.type === SUMMARY) {
    return (
      <Summary
        title={step.title}
        subtitle={step.subtitle}
        items={step.items}
        redirectUrl={step.extra && step.extra.redirectUrl}
        clientId={clientId}
      />
    );
  }

  if (step.type === GRAPH_SELECTION) {
    return (
      <GraphForm
        title={step.title}
        subtitle={step.subtitle}
        subtitle2={step.subtitle2}
        topLink={step.topLink}
        item={step.items[0]}
        setSavedData={setSavedData}
        savedData={savedData}
        onNext={goNext}
      />
    );
  }

  if (step.type === SLIDER_SELECTION) {
    return (
      <SliderForm
        title={step.title}
        subtitle={step.subtitle}
        items={step.items}
        defaults={step.defaults}
        setSavedData={setSavedData}
        savedData={savedData}
        onNext={goNext}
        topLink={step.topLink}
        validationMessage={step.validationMessage}
      />
    );
  }

  if (step.type === TEXT) {
    return <TextForm title={step.title} subtitle={step.subtitle} topLink={step.topLink} onNext={goNext} />;
  }

  if (step.type === TEXT_INPUT) {
    return (
      <TextInputForm
        title={step.title}
        subtitle={step.subtitle}
        subtitle2={step.subtitle2}
        setSavedData={setSavedData}
        savedData={savedData as TextData}
        onNext={goNext}
        topLink={step.topLink}
        isNumericalFiled={step.extra ? step.extra.isNumericalFiled : undefined}
        isEmailField={step.extra ? step.extra.isEmailField : undefined}
        maskFormat={step.extra && step.extra.maskFormat}
        minLength={step.extra && step.extra.minLength}
        maxLength={step.extra && step.extra.maxLength}
        minValue={step.extra && step.extra.minValue}
        maxValue={step.extra && step.extra.maxValue}
        adornment={step.extra && step.extra.adornment}
        textInputLabel={step.extra && step.extra.textInputLabel}
        textPlaceholder={step.extra && step.extra.textPlaceholder}
        customErrorMessage={step.customErrorMessage}
        validationMessage={step.validationMessage}
        checkboxesDetails={step.extra && step.extra.checkboxesDetails}
      />
    );
  }

  if (step.type === SORTABLE_SELECTION) {
    return (
      <DragAndDropForm
        title={step.title}
        subtitle={step.subtitle}
        items={step.items}
        setSavedData={setSavedData}
        savedData={savedData as Array<string>}
        onNext={goNext}
        topLink={step.topLink}
      />
    );
  }

  if (step.type === SINGLE_SELECTION || step.type === MULTIPLE_SELECTION) {
    return (
      <SelectionForm
        title={step.title}
        subtitle={step.subtitle}
        notice={step.notice}
        type={step.type}
        items={step.items}
        setSavedData={setSavedData}
        savedData={savedData as Array<string>}
        onNext={goNext}
        topLink={step.topLink}
        textUnderChoices={step.textUnderChoices}
        excludingWorksAcrossAllGroups={step.excludingWorksAcrossAllGroups}
        selectionsLimit={step.selectionsLimit}
        validationMessage={step.validationMessage}
      />
    );
  }

  if (step.type === SINGLE_SELECTION_WITH_TEXT || step.type === MULTIPLE_SELECTION_WITH_TEXT) {
    return (
      <SelectionWithTextForm
        title={step.title}
        subtitle={step.subtitle}
        notice={step.notice}
        type={step.type}
        items={step.items}
        setSavedData={setSavedData}
        savedData={savedData as SelectionWithTextData}
        onNext={goNext}
        topLink={step.topLink}
        selectionsLimit={step.selectionsLimit}
        isNumericalFiled={step.extra ? step.extra.isNumericalFiled : undefined}
        isEmailField={step.extra ? step.extra.isEmailField : undefined}
        maskFormat={step.extra && step.extra.maskFormat}
        minLength={step.extra && step.extra.minLength}
        maxLength={step.extra && step.extra.maxLength}
        minValue={step.extra && step.extra.minValue}
        maxValue={step.extra && step.extra.maxValue}
        adornment={step.extra && step.extra.adornment}
        textInputLabel={step.extra && step.extra.textInputLabel}
        customErrorMessage={step.customErrorMessage}
        validationMessage={step.validationMessage}
        checkboxLabel={step.extra && step.extra.checkboxLabel}
      />
    );
  }

  if (step.type === SINGLE_SELECTION_DROPDOWN) {
    return (
      <SelectionWithDropdownForm
        title={step.title}
        subtitle={step.subtitle}
        notice={step.notice}
        type={step.type}
        items={step.items}
        setSavedData={setSavedData}
        savedData={savedData as string}
        onNext={goNext}
        topLink={step.topLink}
        textUnderChoices={step.textUnderChoices}
        validationMessage={step.validationMessage}
      />
    );
  }

  return <></>;
};

export default Wizard;
