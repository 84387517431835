import { createMuiTheme } from "@material-ui/core/styles";
// import gray from "@material-ui/core/colors/gray";

export default createMuiTheme({
  typography: {
    fontFamily: ["Avenir", "Arial", "sans-serif"]
  },
  palette: {
    primary: {
      main: "#32668f",
      light: "#5196CD"
    },
    secondary: {
      main: "#ffffff",
      light: "#CFE3ED"
    },
    background: {
      // default: "#fafafa",
      default: "#ffffff",
      paper: "#e7f7ff"
    }
    // gray: gray[500]
  }
});
