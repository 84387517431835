import * as React from "react";
import styles from "./Modal.module.css";
import Modal from "./Modal";
import useModal from "./useModal";
import { ModalContentProps } from "../../types";
import { scrollToTop } from "../../../common/utils";

interface LinkAndModalProps {
  linkText: string;
  modalTitle: string;
  content: ModalContentProps;
}

const LinkAndModal: React.FC<LinkAndModalProps> = ({ linkText, modalTitle, content }) => {
  const { isVisible, toggle } = useModal();

  const { contentStyle, paragraphs } = content;

  const htmlParagraphs = paragraphs.map((text: string, idx) => (
    <p
      key={text}
      className={`${styles.paragraph} ${idx === 0 ? styles.firstParagraph : ""}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ));

  const textContent = () => {
    if (contentStyle === "center") {
      return <section className={styles.centeredContent}>{htmlParagraphs}</section>;
    }

    return <section className={styles.columnContent}>{htmlParagraphs}</section>;
  };

  return (
    <>
      <span
        onClick={() => {
          toggle();
          scrollToTop();
        }}
        className={styles.linkModal}
      >
        {linkText}
      </span>
      <Modal isVisible={isVisible} closeIt={toggle} modalId="linkAndModal">
        <div className={styles.customModalContent}>
          <h1 className={styles.modalTitle}>{modalTitle}</h1>
          <section className={styles.customContent}>{textContent()}</section>
          <footer className={styles.customFooter}>
            <span className={styles.customClose} onClick={() => toggle()}>
              Got it
            </span>
          </footer>
        </div>
      </Modal>
    </>
  );
};

export default LinkAndModal;
