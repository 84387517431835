import React, { FC } from "react";
import { Box, Dialog, DialogContent, DialogTitle, makeStyles, useMediaQuery } from "@material-ui/core";
import ReactJWPlayer from "react-jw-player";
import ButtonBase from "@material-ui/core/ButtonBase";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import { useTheme } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { JW_PLAYER_SCRIPT, JW_PLAYER_VERTICAL_SCRIPT } from "../../config";

const useStyles = makeStyles(theme => ({
  closeButton: {
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  dialogContent: {
    padding: 0,
    paddingTop: "0px !important"
  },
  dialogPaper: {
    color: "transparent",
    overflowY: "unset",
    transition: "0.1s",
    "&:hover": {
      color: "white"
    },
    [theme.breakpoints.down("sm")]: {
      color: "white"
    }
  },
  dialogTitle: {
    textAlign: "right",
    padding: 0,
    backgroundColor: "black",
    height: 0
  },
  dialogTitleBox: {
    position: "absolute",
    width: "100%",
    top: "-32px",
    "&.video": {
      top: 0,
      padding: "2px 8px",
      zIndex: 2
    }
  }
}));

const MediaPlayerDialog: FC<{
  show: boolean;
  onClose: (event: React.MouseEvent) => void;
  hlsManifestUrl: string;
  mediaType: string;
  verticalMode?: boolean;
}> = ({ show, onClose, hlsManifestUrl, mediaType, verticalMode = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const playerId = uuidv4();

  return (
    <Dialog
      open={show}
      onClose={onClose}
      maxWidth={mobile || verticalMode ? "xs" : "md"}
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Box className={classnames(classes.dialogTitleBox, { video: mediaType === "video" })}>
          <ButtonBase onClick={onClose}>
            <CloseIcon className={classes.closeButton} />
          </ButtonBase>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box>
          <ReactJWPlayer
            isAutoPlay
            playerId={playerId}
            playerScript={mobile || verticalMode ? JW_PLAYER_VERTICAL_SCRIPT : JW_PLAYER_SCRIPT}
            file={hlsManifestUrl}
            customProps={mediaType === "video" ? {} : { width: "auto", height: 40 }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MediaPlayerDialog;
