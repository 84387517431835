import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import classNames from "classnames";
import CustomSection from "../../common/layout/CustomSection";
import { withMatchData } from "../common/MatchDataProvider";
import { MatchDataContextType } from "../types";
import TherapistCarousel from "./Carousel/TherapistCarousel";
import Preferences from "./Preferences";
import curve6 from "../../../assets/layout/curve-6.svg";
import curve3Flipped from "../../../assets/layout/curve-3-flipped.svg";
import curve1Flipped from "../../../assets/layout/curve-1-flipped.svg";
import LeftSection from "./LeftSection";

const useStyles = makeStyles(theme => ({
  setTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: "32px",
    textAlign: "left",
    "&.mobile": {
      textAlign: "center"
    }
  },
  matchSet: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  preferences: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0
    }
  }
}));

const chooseSectionImage = (index: number, matchesLength: number) => {
  if (index === matchesLength - 2) {
    return curve6;
  }
  if (index % 2 === 1) {
    return curve1Flipped;
  }
  return curve3Flipped;
};

const AdditionalMatches: FC<{
  matchDataContext: MatchDataContextType;
  setTherapistIdWithOpenCalendar: Dispatch<SetStateAction<any>>;
  setOpenCarouselIndex: Dispatch<SetStateAction<number>>;
  setLearnMoreIndex: Dispatch<SetStateAction<number>>;
}> = ({ matchDataContext, setTherapistIdWithOpenCalendar, setOpenCarouselIndex, setLearnMoreIndex }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const { matches, updated, setUpdated } = matchDataContext;

  const scrollToRef = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (updated) {
      scrollToRef();
      setUpdated(false);
    }
  }, [setUpdated, updated]);

  return (
    <div>
      {matches.slice(1).map((match, idx) => (
        <CustomSection
          key={`PreferenceSet${idx + 1}`}
          withPadding={false}
          limitWidth={false}
          image={chooseSectionImage(idx, matches.length)}
          color={idx % 2 === 1 ? "silver" : "lightBlue"}
        >
          <Grid container className={classes.matchSet}>
            <Grid item xs={12} md={6} className={classes.preferences}>
              <LeftSection>
                <Typography className={classNames(classes.setTitle, { mobile })}>{`SET #${idx + 2}`}</Typography>
                <Preferences
                  title={`${mobile ? "The below" : "These"} matches are based on your preferences:`}
                  alignLeft={!mobile}
                  preferenceSetIndex={idx + 1}
                />
              </LeftSection>
            </Grid>
            <Grid ref={idx === matches.length - 2 ? scrollRef : null} item xs={12} md={6}>
              <TherapistCarousel
                setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
                setOpenCarouselIndex={setOpenCarouselIndex}
                setLearnMoreIndex={setLearnMoreIndex}
                preferenceSetIndex={idx + 1}
              />
            </Grid>
          </Grid>
        </CustomSection>
      ))}
    </div>
  );
};

export default withMatchData(AdditionalMatches);
