import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import StepContainer from "../../common/StepContainer";
import { ModalLinkProps, StepItems } from "../../../types";
import CheckboxElement from "../../FormElements/CheckboxElement";
import SelectionElement from "../../FormElements/SelectionElement";
import TextElement from "../../FormElements/TextElement";

export type SelectionWithTextData = {
  selection: Array<string>;
  text: string;
  checkbox: boolean;
};

export type SelectionWithTextFormProps = {
  title: string;
  type: string;
  items: Array<StepItems>;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: SelectionWithTextData;
  topLink?: ModalLinkProps;
  subtitle?: string;
  notice?: string;
  onNext: () => void;
  selectionsLimit?: number;
  isNumericalFiled?: boolean;
  isEmailField?: boolean;
  maskFormat?: string;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  adornment?: string;
  textInputLabel?: string;
  customErrorMessage?: string;
  validationMessage?: string;
  checkboxLabel?: string;
};

const SelectionWithTextForm: React.FC<SelectionWithTextFormProps> = ({
  title,
  type,
  items,
  setSavedData,
  savedData,
  topLink,
  subtitle,
  notice,
  onNext,
  selectionsLimit,
  isNumericalFiled,
  isEmailField,
  maskFormat,
  minLength,
  maxLength,
  minValue,
  maxValue,
  adornment,
  textInputLabel,
  customErrorMessage,
  validationMessage,
  checkboxLabel
}) => {
  const [selection, setSelection] = useState(savedData.selection);
  const [selectionValid, setSelectionValid] = useState(false);

  const [text, setText] = useState(savedData.text);
  const [textValid, setTextValid] = useState(false);
  const [showText, setShowText] = useState(false);

  const [checkbox, setCheckbox] = useState(savedData.checkbox);

  const [isValid, setIsValid] = useState(false);
  const [movingToNext, setMovingToNext] = useState(false);

  useEffect(() => {
    setSavedData({ selection, text, checkbox });
  }, [selection, text, checkbox, setSavedData]);

  useEffect(() => {
    setIsValid(selectionValid && (!showText || textValid));
  }, [selectionValid, showText, textValid]);

  function onNextWithValidation() {
    setMovingToNext(true);
    if (isValid) {
      onNext();
    }
  }

  const handleClick = (event: React.MouseEvent) => {
    onNextWithValidation();
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === "Enter") {
      onNextWithValidation();
    }
  };

  useEffect(() => {
    setMovingToNext(false);
  }, [savedData]);

  return (
    <>
      <StepContainer
        title={title}
        subtitle={subtitle}
        notice={notice}
        topLink={topLink}
        onNext={handleClick}
        isValid={isValid}
        showValidationMessage={(movingToNext && !isValid) || !!validationMessage}
        validationMessage={
          validationMessage || (showText && !textValid ? customErrorMessage : "Please make a selection")
        }
      >
        <Grid item xs={12}>
          <SelectionElement
            type={type}
            items={items}
            setSavedData={setSelection}
            savedData={savedData.selection}
            setIsValid={setSelectionValid}
            selectionsLimit={selectionsLimit}
            setShowExtra={setShowText}
          />
        </Grid>

        {showText && (
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}>
                <TextElement
                  setSavedData={setText}
                  savedData={savedData.text}
                  isNumericalFiled={isNumericalFiled}
                  isEmailField={isEmailField}
                  maskFormat={maskFormat}
                  minLength={minLength}
                  maxLength={maxLength}
                  minValue={minValue}
                  maxValue={maxValue}
                  adornment={adornment}
                  textInputLabel={textInputLabel}
                  setIsValid={setTextValid}
                  onKeyUp={handleKeyUp}
                />
              </Grid>

              {checkboxLabel && (
                <Grid item xs={12}>
                  <CheckboxElement
                    checkboxLabel={checkboxLabel}
                    setSavedData={setCheckbox}
                    savedData={savedData.checkbox}
                    small
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </StepContainer>
    </>
  );
};

export default SelectionWithTextForm;
