export const RAILS_SECURITY_TOKEN: string = process.env.REACT_APP_FORM_SECURITY_TOKEN || "";
export const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || "";
export const GTAG_ID: string = process.env.REACT_APP_GTAG_ID || "";
export const API_URL: string = process.env.REACT_APP_API_URL || "";
export const DIRECTORY_URL: string = process.env.REACT_APP_DIRECTORY_URL || "";
export const RECAPTCHA_SITE_KEY: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
export const FULLSTORY_ORG_ID: string = process.env.REACT_APP_FULLSTORY_ORG_ID || "";
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN || "";
export const STRIPE_KEY: string = process.env.REACT_APP_STRIPE_KEY || "";
export const HOTJAR_ID: string | undefined = process.env.REACT_APP_HOTJAR_ID;
export const HOTJAR_SV: string | undefined = process.env.REACT_APP_HOTJAR_SV;
export const HELLOSIGN_CLIENT_ID: string | undefined = process.env.REACT_APP_HELLOSIGN_CLIENT_ID;
export const JW_PLAYER_SCRIPT: string | undefined = process.env.REACT_APP_JW_PLAYER_SCRIPT;
export const JW_PLAYER_VERTICAL_SCRIPT: string | undefined = process.env.REACT_APP_JW_PLAYER_VERTICAL_SCRIPT;
