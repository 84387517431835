/* eslint-disable */

export const trackPage = (pageUrl: string, pageTitle: string) => {
  window.dataLayer.push({
    event: "pageVisited",
    step_path: pageUrl,
    step_title: pageTitle
  });
};

export const trackFormAbandonment = (formId: number, stepTitle: string) => {
  window.dataLayer.push({
    event: "formAbandonment",
    eventCategory: "Form Abandonment",
    eventAction: stepTitle,
    eventLabel: `Version ${formId}`
  });
};
