import React, { ReactNode } from "react";
import DOMPurify from "dompurify";
import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ModalLinkProps } from "../../types";
import LinkAndModal from "../Modal/LinkAndModal";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Tiempos, Times, serif"
  },
  subtitle: {
    maxWidth: 1200,
    textAlign: "center",
    margin: "0 auto"
  },
  notice: {
    color: "#dd0000",
    fontStyle: "italic"
  },
  container: {
    textAlign: "center"
  }
}));

const TitleWrapper: React.FC<{
  children: ReactNode;
  title: string;
  subtitle?: string;
  subtitle2?: string;
  notice?: string;
  topLink?: ModalLinkProps;
  textUnderChoices?: string;
}> = ({ children, title, subtitle, subtitle2, notice, topLink, textUnderChoices }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container justify="center" spacing={mobile ? 1 : 4}>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant={mobile ? "h5" : "h4"}
          align="center"
          id="stepTitle"
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
        />
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant={mobile ? "body1" : "h6"}
            align="center"
            className={classes.subtitle}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle) }}
            id="stepSubtitle"
          />
        </Grid>
      )}
      {subtitle2 && (
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="body1"
            align="center"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle2) }}
          />
        </Grid>
      )}
      {notice && (
        <Grid item xs={12} sm={9}>
          <Typography component="h3" variant="body1" align="center" className={classes.notice}>
            {notice}
          </Typography>
        </Grid>
      )}
      {topLink && topLink.textLink && (
        <Grid item xs={12}>
          <Grid container justify="center" className={classes.container}>
            {topLink.content && topLink.title && (
              <LinkAndModal linkText={topLink.textLink} content={topLink.content} modalTitle={topLink.title} />
            )}
            {topLink.url && (
              <a href={topLink.url} target="_blank" rel="noopener noreferrer">
                {topLink.textLink}
              </a>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        {children}
      </Grid>
      {textUnderChoices && (
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant={mobile ? "body1" : "h6"}
            align="center"
            className={classes.subtitle}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textUnderChoices) }}
            id="stepSubtitle"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TitleWrapper;
