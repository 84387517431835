import { useRef, useEffect } from "react";

const createRootElement = (id: string) => {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
};

const addRootElementToBody = (rootElem: HTMLElement) => {
  // if document.body doesn't have any children
  if (!document.body.childElementCount) {
    document.body.appendChild(rootElem);
  } else {
    document.body.insertBefore(rootElem, document.body.lastElementChild!.nextElementSibling);
  }
};

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements, example:
 *    const target = usePortal(id);
 *    return createPortal(myModal, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'modal-root'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const usePortal = (id: string) => {
  const rootElemRef = useRef<null | HTMLElement>(null);

  useEffect(
    function setupElement() {
      // Look if the target elem exists already on the dom
      const existingParent = document.getElementById(id);
      // Parent is either a new root or the existing dom element
      const parentElem = existingParent || createRootElement(id);
      // If there is no existing DOM element, add a new one.
      if (!existingParent) {
        addRootElementToBody(parentElem);
      }
      if (rootElemRef.current) {
        // Add the detached element to the parent
        parentElem.appendChild(rootElemRef.current);
      }

      return function removeElement() {
        if (rootElemRef.current) {
          // teardown modal element
          parentElem.removeChild(rootElemRef.current);
          // rootElemRef.current.remove();
        }
      };
    },
    [id]
  );

  /**
   * With this strategy we instatiate useRef element lazily.
   *
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement("div");
    }
    return rootElemRef.current;
  };
  // it's the DOM element where the modal will be appended with a portal
  return getRootElem();
};

export default usePortal;
