import React, { Dispatch, SetStateAction } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import Box from "@material-ui/core/Box";
import DraggableElem from "./DraggableElem";
import styles from "./DragAndDropForm.module.css";
import { ModalLinkProps, SelectionFieldProps, StepItems } from "../../../types";
import StepContainer from "../../common/StepContainer";

type DragAndDropFormProps = {
  title: string;
  items: Array<StepItems>;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: Array<string>;
  topLink?: ModalLinkProps;
  subtitle?: string;
  onNext: () => void;
};

const DragAndDropForm: React.FC<DragAndDropFormProps> = ({
  title,
  subtitle,
  items,
  setSavedData,
  savedData,
  onNext
}) => {
  const optionElements = (items[0].options as SelectionFieldProps[]).reduce(
    (acc, option) => {
      acc[option.value] = option.label;
      return acc;
    },
    {} as { [key: string]: string }
  );

  const onUpdate = (newOrder: Array<string>) => setSavedData(newOrder);

  const reorder = (list: Array<string>, startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const newOptionList: string[] = reorder(savedData, source.index, destination.index);
    onUpdate(newOptionList);
  }

  const elementList = () => {
    return savedData.map((optionValue: string, index: number) => (
      <DraggableElem key={optionValue} value={optionValue} label={optionElements[optionValue]} index={index} />
    ));
  };

  return (
    <StepContainer title={title} subtitle={subtitle} onNext={onNext} isValid={true}>
      <p className={styles.textContainer}>Most important</p>
      <Box display="flex" justifyContent="center">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="option-list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} data-testid="dropable-container">
                {elementList()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <p className={styles.textContainer}>Least important</p>
    </StepContainer>
  );
};

export default DragAndDropForm;
