import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { decamelizeKeys } from "humps";
import queryString from "query-string";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Modal from "./Modal";
import SubmitButton from "../therapist-app/common/SubmitButton";
import { sendEmailToTherapist } from "../therapist-app/api";
import { withNotification } from "../therapist-app/notification/NotificationProvider";
import StateValidator from "../therapist-app/common/state-validator";
import { RECAPTCHA_SITE_KEY } from "../../config";
import Spinner from "./Spinner";
import { sendEvent } from "../match-page/events";
import { TherapistType } from "../match-page/types";

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 600,
    margin: "0 auto"
  },
  header: {
    fontFamily: "Tiempos, serif",
    marginBottom: 20
  },
  input: {
    backgroundColor: "white"
  },
  text: {
    backgroundColor: "white",
    alignItems: "start",
    minHeight: "7em",
    [theme.breakpoints.up("sm")]: {
      minHeight: "15em"
    }
  }
}));

type FormData = {
  name: string;
  email: string;
  subject: string;
  body: string;
  captcha: string | null;
};

const ContactForm: React.FC<{
  therapist: TherapistType;
  clientName?: string;
  clientEmail?: string;
  messageSubject?: string;
  messageContent?: string;
  isOpen: boolean;
  onClose: () => void;
  showNameAndEmail?: boolean;
  notificationContext: any;
}> = ({
  therapist,
  clientName,
  clientEmail,
  messageSubject,
  messageContent,
  isOpen,
  onClose,
  showNameAndEmail = true,
  notificationContext
}) => {
  const classes = useStyles();

  const emptyState = {
    name: clientName || "",
    email: clientEmail || "",
    subject: messageSubject || "",
    body: messageContent || "",
    captcha: null
  };

  const validationSchema = {
    ...(showNameAndEmail ? { name: ["validatePresence"], email: ["validatePresence", "validateEmail"] } : {}),
    subject: ["validatePresence"],
    body: ["validatePresence"]
  };

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const [formData, setFormData] = useState<FormData>(emptyState);
  const [sending, setSending] = useState(false);

  const validator = useMemo(() => new StateValidator(formData, validationSchema), [formData, validationSchema]);

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onCaptchaChange = (value: string | null) => {
    if (value) {
      setFormData({ ...formData, captcha: value });
      setSending(true);
    }
  };

  const executeCaptcha = () => {
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const close = useCallback(() => {
    setFormData(emptyState);
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    onClose();
  }, [onClose, emptyState]);

  useEffect(() => {
    if (sending && formData.captcha) {
      const data = decamelizeKeys(formData) as any;
      const { auth_token } = queryString.parse(window.location.search);

      sendEmailToTherapist({
        id: therapist.id,
        data: { ...data, auth_token },
        callbacks: {
          onSuccess: () => {
            close();
            notificationContext.displaySuccessNotification("Email sent successfully.");
            if (auth_token) {
              sendEvent("email_sent", { therapist_id: therapist.id });
            }
          },
          onError: () => {
            notificationContext.displayErrorNotification("An error occurred. Please try again later");
          },
          onAfter: () => {
            setSending(false);
          }
        }
      });
    }
  }, [formData, sending, close, notificationContext, therapist.id]);

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <div className={classes.form}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" className={classes.header}>
              {`Send email to ${therapist.firstName}`}
            </Typography>
          </Grid>
          {showNameAndEmail && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Your name"
                  name="name"
                  value={formData.name}
                  onChange={onFieldChange}
                  helperText={validator.validationError("name")}
                  variant="outlined"
                  fullWidth
                  InputProps={{ classes: { root: classes.input } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Your email"
                  name="email"
                  value={formData.email}
                  onChange={onFieldChange}
                  helperText={validator.validationError("email")}
                  fullWidth
                  variant="outlined"
                  InputProps={{ classes: { root: classes.input } }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              label="Subject"
              name="subject"
              value={formData.subject}
              onChange={onFieldChange}
              helperText={validator.validationError("subject")}
              fullWidth
              variant="outlined"
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Enter your message here"
              name="body"
              value={formData.body}
              onChange={onFieldChange}
              helperText={validator.validationError("body")}
              multiline
              fullWidth
              variant="outlined"
              InputProps={{ classes: { root: classes.text } }}
            />
          </Grid>
          {RECAPTCHA_SITE_KEY && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  onChange={onCaptchaChange}
                  sitekey={RECAPTCHA_SITE_KEY}
                  size="invisible"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justify="center">
              <Spinner loading={sending}>
                <SubmitButton
                  id="send-email-button"
                  onClick={executeCaptcha}
                  inProgress={false}
                  disabled={!validator.isStateValid()}
                  round
                >
                  <Typography variant="body1">Send</Typography>
                </SubmitButton>
              </Spinner>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default withNotification(ContactForm);
