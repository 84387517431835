import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Route } from "react-router-dom";
import MatchDataProvider from "./common/MatchDataProvider";
import Theme from "./theme";
import MatchResult from "./match-result";
import PreferencesDataProvider from "./common/PreferencesDataProvider";
import LogoAndMessagePage from "./common/LogoAndMessagePage";

const MatchResultWithData = () => (
  <PreferencesDataProvider>
    <MatchDataProvider>
      <MatchResult />
    </MatchDataProvider>
  </PreferencesDataProvider>
);

const MatchFollowUpWithData = () => {
  return <LogoAndMessagePage message="Sorry, this page is temporarily unavailable" />;
};

const App = () => (
  <MuiThemeProvider theme={Theme}>
    <CssBaseline />
    <Route exact path="/matched" component={MatchResultWithData} />
    <Route exact path="/matched/follow-up" component={MatchFollowUpWithData} />
  </MuiThemeProvider>
);

export default App;
