import React from "react";
import { Box, ButtonBase, Grid, InputAdornment, makeStyles, TextField, useMediaQuery } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  search: {
    borderRadius: 20,
    backgroundColor: "white"
  }
}));

export type SearchBoxProps = {
  search: string;
  setSearch: (newSearch: string) => void;
  placeholder?: string;
};

const SearchBox: React.FC<SearchBoxProps> = ({ search, setSearch, placeholder = "" }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12}>
      <Box p="12px" margin="auto" maxWidth={mobile ? 306 : 606}>
        <TextField
          placeholder={placeholder || "Search..."}
          variant="outlined"
          size="small"
          onChange={event => setSearch(event.target.value)}
          value={search}
          fullWidth
          InputProps={{
            classes: { root: classes.search },
            style: { fontSize: 18 },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ButtonBase disabled={search === ""} onClick={() => setSearch("")}>
                  <CloseIcon color={search === "" ? "disabled" : "primary"} />
                </ButtonBase>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Grid>
  );
};

export default SearchBox;
