import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles, Snackbar, Typography } from "@material-ui/core";
import OptionButton from "../../../common/OptionButton";
import { SelectionFieldProps, StepItems } from "../../types";
import {
  MULTIPLE_SELECTION,
  MULTIPLE_SELECTION_WITH_TEXT,
  SINGLE_SELECTION,
  SINGLE_SELECTION_WITH_TEXT
} from "../../types/FormTypes";
import SearchBox from "./SearchBox";

const useStyles = makeStyles(() => ({
  groupTitle: {
    margin: "10px 0 0 0"
  },
  text: {
    fontSize: 18
  }
}));

export type SelectionElementProps = {
  type: string;
  items: Array<StepItems>;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: Array<string>;
  selectionsLimit?: number;
  excludingWorksAcrossAllGroups?: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setShowExtra?: Dispatch<SetStateAction<boolean>>;
};

const SelectionElement: React.FC<SelectionElementProps> = ({
  type,
  items,
  setSavedData,
  savedData,
  selectionsLimit,
  excludingWorksAcrossAllGroups,
  setIsValid,
  setShowExtra
}) => {
  const classes = useStyles();

  const [showLimitInfo, setShowLimitInfo] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState<{ [key: number]: string }>({});

  const optionsItem = useMemo(() => {
    const result: { [key: string]: string } = {};
    items.forEach(item => {
      (item.options as SelectionFieldProps[]).forEach(option => (result[option.value] = item.key));
    });
    return result;
  }, [items]);

  const excludingOptionValues = useMemo(
    () =>
      items.flatMap(item =>
        (item.options as SelectionFieldProps[]).filter(option => option.excluding).map(option => option.value)
      ),
    [items]
  );

  const showExtraOptionValues = useMemo(
    () =>
      items.flatMap(item =>
        (item.options as SelectionFieldProps[]).filter(option => option.showExtra).map(option => option.value)
      ),
    [items]
  );

  useEffect(() => {
    setIsValid(savedData.length !== 0);
    if (setShowExtra) {
      setShowExtra(showExtraOptionValues.filter(value => savedData.includes(value)).length > 0);
    }
  }, [setIsValid, setShowExtra, savedData, showExtraOptionValues]);

  const checked = (valueToCheck: string): boolean => savedData.includes(valueToCheck);

  const onClick = (clickedValue: string) => {
    setIsValid(true);
    setShowLimitInfo(false);

    const clickedValueChecked = checked(clickedValue);
    let newValues = Array<string>();

    if (clickedValueChecked) {
      newValues = savedData.filter(v => v !== clickedValue);
    } else if (type === SINGLE_SELECTION || type === SINGLE_SELECTION_WITH_TEXT) {
      newValues = [clickedValue];
    } else if (type === MULTIPLE_SELECTION || type === MULTIPLE_SELECTION_WITH_TEXT) {
      newValues = newMultipleSelectionValues(clickedValue);

      if (selectionsLimit && newValues.length > selectionsLimit) {
        setShowLimitInfo(true);
        return;
      }
    }

    setSavedData(newValues);
  };

  const newMultipleSelectionValues = (clickedValue: string): string[] => {
    const clickedValueItemKey = optionsItem[clickedValue];

    if (excludingOptionValues.includes(clickedValue)) {
      if (clickedValueItemKey && !excludingWorksAcrossAllGroups) {
        return savedData.filter(v => optionsItem[v] !== clickedValueItemKey).concat([clickedValue]);
      }

      return [clickedValue];
    } else {
      return savedData
        .filter(v => {
          const isExcluding = excludingOptionValues.includes(v);
          const belongsToSameGroup = optionsItem[v] === clickedValueItemKey;
          return !(isExcluding && (belongsToSameGroup || excludingWorksAcrossAllGroups));
        })
        .concat([clickedValue]);
    }
  };

  const hideLimitInfo = (event: any, reason: string) => {
    if (reason !== "clickaway") {
      setShowLimitInfo(false);
    }
  };

  return (
    <>
      <Snackbar
        open={showLimitInfo}
        autoHideDuration={1000}
        onClose={hideLimitInfo}
        message={`You can choose up to ${selectionsLimit} specializations`}
      />
      {items.map(({ title, options, shape, searchable, searchPlaceholder }, idx) => {
        const regExp = new RegExp(searchPhrase[idx] || "", "i");
        const itemOptions = (options as SelectionFieldProps[]).filter(({ label }) => regExp.test(label));

        return (
          <Grid container justify="center" key={idx}>
            {title && (
              <Grid item xs={12}>
                <Typography variant="h6" align="center" className={classes.groupTitle}>
                  {title}
                </Typography>
              </Grid>
            )}
            {searchable && (
              <SearchBox
                search={searchPhrase[idx] || ""}
                setSearch={(newSearch: string) => setSearchPhrase(prev => ({ ...prev, [idx]: newSearch }))}
                placeholder={searchPlaceholder}
              />
            )}
            {itemOptions.length === 0 && (
              <Grid item>
                <Typography className={classes.text}>No results</Typography>
              </Grid>
            )}
            {itemOptions.map(option => {
              const { label, title, subtitle, description, imageUrl, value } = option;
              return (
                <Grid item key={value}>
                  <OptionButton
                    id={value}
                    shape={shape}
                    label={label}
                    title={title}
                    subtitle={subtitle}
                    description={description}
                    imageUrl={imageUrl}
                    onClick={() => onClick(value)}
                    checked={checked(value)}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </>
  );
};

export default SelectionElement;
