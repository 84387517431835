import React, { FC, useState } from "react";
import queryString from "query-string";
import classNames from "classnames";
import { makeStyles, IconButton, Theme } from "@material-ui/core";
import { ReactComponent as PlayIcon } from "../../assets/play_icon.svg";
import MediaPlayerDialog from "./MediaPlayerDialog";
import { sendEvent } from "../match-page/events";
import { MediaType } from "../therapist-app/profile/types";

const useStyles = makeStyles<Theme, { left?: number; top?: number; position?: "absolute" | "relative" }>({
  container: {
    position: "relative"
  },
  mediaPlayer: {
    position: props => props.position || "absolute",
    top: props => props.top || 0,
    left: props => props.left || 0,
    zIndex: 100
  },
  icon: {
    height: 40,
    width: 40,
    color: "#6B315D"
  },
  button: {
    margin: 1,
    borderRadius: 20,
    padding: 0,
    backgroundColor: "white",
    "&.border": {
      padding: 2
    }
  }
});

const MediaWidget: FC<{
  media?: MediaType;
  position?: "absolute" | "relative";
  top?: number;
  left?: number;
  iconWithBorder?: boolean;
  verticalMode?: boolean;
}> = ({ media, top, left, position, iconWithBorder = true, verticalMode = false }) => {
  const classes = useStyles({ top, left, position });
  const [playing, setPlaying] = useState(false);
  const { auth_token: authToken } = queryString.parse(window.location.search);

  const play = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (media) {
      setPlaying(true);

      if (media.therapistId) {
        if (authToken) {
          sendEvent("media_played", { therapist_id: media.therapistId, media_type: media.mediaType });
        }
      }
    }
  };

  const pause = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setPlaying(false);
  };

  if (!media) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.mediaPlayer}>
        <IconButton onClick={play} className={classNames(classes.button, { border: iconWithBorder })}>
          <PlayIcon className={classes.icon} />
        </IconButton>
        <MediaPlayerDialog
          show={playing}
          onClose={pause}
          hlsManifestUrl={media.hlsManifestUrl}
          mediaType={media.mediaType}
          verticalMode={verticalMode}
        />
      </div>
    </div>
  );
};

export default MediaWidget;
