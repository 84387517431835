import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, makeStyles, Typography, useMediaQuery, ButtonBase } from "@material-ui/core";
import TitleWrapper from "../Steps/TitleWrapper";
import { ModalLinkProps } from "../../types";
import Button from "./Button";
import CrisisResources from "../../../common/CrisisResources";

const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(2)}px 0`,
    height: "100%",
    width: "100%",
    maxWidth: 1330,
    flexWrap: "nowrap"
  },
  buttonContainer: {
    position: "sticky",
    bottom: 30,
    paddingBottom: 16
  },
  buttonWrapper: {
    maxWidth: 1200
  },
  textButton: {
    fontSize: 20,
    borderBottom: "2px solid black",
    padding: 6,
    color: "black",
    marginBottom: 16,
    "&:hover": {
      paddingBottom: 4,
      borderBottomWidth: 4
    }
  },
  error: {
    color: "#FF0000",
    "& span": {
      backgroundColor: "white",
      padding: 8,
      borderRadius: 8
    }
  }
}));

type StepContainerProps = {
  title: string;
  subtitle?: string;
  subtitle2?: string;
  notice?: string;
  topLink?: ModalLinkProps;
  textUnderChoices?: string;
  onNext?: (event: React.MouseEvent) => void;
  buttonLabel?: string;
  isValid: boolean;
  showValidationMessage?: boolean;
  validationMessage?: string;
  buttonAsText?: boolean;
};

const StepContainer: React.FC<StepContainerProps> = ({
  children,
  title,
  subtitle,
  subtitle2,
  notice,
  topLink,
  textUnderChoices,
  onNext,
  buttonLabel,
  isValid,
  showValidationMessage = false,
  validationMessage,
  buttonAsText
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const label = buttonLabel || "Continue";

  return (
    <Grid container direction="column" component="section" justify="center" className={classes.container}>
      <Grid item xs>
        <Box display="flex" alignItems="center" height="100%" pb={3}>
          <TitleWrapper
            title={title}
            subtitle={subtitle}
            subtitle2={subtitle2}
            notice={notice}
            topLink={topLink}
            textUnderChoices={textUnderChoices}
          >
            {children}
          </TitleWrapper>
        </Box>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Grid container justify="center" spacing={2}>
          {showValidationMessage && (
            <Grid item xs={12}>
              <Typography variant="body1" align="center" className={classes.error}>
                <span>{validationMessage}</span>
              </Typography>
            </Grid>
          )}
          {onNext && (
            <Grid item xs={12} className={classes.buttonWrapper}>
              <Grid container justify={buttonAsText && !mobile ? "flex-end" : "center"}>
                {buttonAsText && (
                  <ButtonBase focusRipple onClick={onNext} className={classes.textButton}>
                    <Typography variant="h6" align="center">
                      <strong>Next question</strong>
                    </Typography>
                  </ButtonBase>
                )}
                {!buttonAsText && (
                  <Button
                    color="primary"
                    onClick={onNext}
                    id="next-button"
                    smallFont={label.length > 24}
                    disabled={!isValid}
                  >
                    {label}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <CrisisResources align="center" color="inherit" />
      </Grid>
    </Grid>
  );
};

export default StepContainer;
