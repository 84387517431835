import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ENVIRONMENT, SENTRY_DSN } from "./config";

declare global {
  interface Window {
    dataLayer: Array<any>;
    hj: any;
    VWO: any;
  }
}

window.dataLayer = window.dataLayer || [];

try {
  const _img = new Image();
  if (window.location.pathname === "/") {
    _img.src = require("./assets/welcome.jpg");
  } else if (window.location.pathname === "/get-started") {
    _img.src = require("./assets/match_people.jpg");
  }
} catch (e) {}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

Sentry.init({
  dsn: SENTRY_DSN,
  allowUrls: [/https?:\/\/((www)\.)?((staging-app)\.)?mywellbeing\.com.*/],
  environment: ENVIRONMENT,
  ignoreErrors: [
    "Non-Error promise rejection captured",
    "Cannot read property 'unregister' of undefined",
    "[object XMLHttpRequest]"
  ]
});
