import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { MatchDataContextType, TherapistType } from "../../types";
import MatchedOn from "./MatchedOn";
import { ReactComponent as VerifiedIcon } from "../../assets/verified.svg";
import Section from "./Section";
import Insurance from "./Insurance";
import ProfileInfoContent from "./ProfileInfo/ProfileInfoContent";
import { ReactComponent as InsuranceIcon } from "../../assets/insurance.svg";
import { withMatchData } from "../../common/MatchDataProvider";

export type ExpandedSectionProps = {
  therapist: TherapistType;
  matchDataContext: MatchDataContextType;
  matchIndex: number;
};

const useStyles = makeStyles(theme => ({
  expandedSection: {
    width: "100%",
    padding: 5,
    paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontFamily: "Avenir",
    fontSize: 14
  },
  verified: {
    paddingTop: 5,
    paddingBottom: 5,
    display: "flex",
    alignContent: "center",
    justifyContent: "center"
  },
  verifiedText: {
    display: "flex"
  },
  icon: {
    marginRight: 5,
    height: 20,
    width: "auto"
  },
  insuranceHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  insuranceIcon: {
    marginRight: 5,
    height: 24,
    width: "auto"
  },
  greeting: {
    fontSize: 14
  },
  sectionHeader: {
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  readMoreWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    paddingTop: theme.spacing(2)
  },
  readMore: {
    fontSize: 16,
    textDecoration: "underline"
  }
}));

const ExpandedSection: React.FC<ExpandedSectionProps> = ({ therapist, matchDataContext, matchIndex }) => {
  const classes = useStyles();
  const { clientPreference } = matchDataContext.matches[matchIndex];

  return (
    <Grid container className={classes.expandedSection}>
      {therapist.verified && (
        <Grid item xs={12} className={classes.verified}>
          <Typography className={classes.verifiedText}>
            <VerifiedIcon className={classes.icon} />
            Verified background check
          </Typography>
        </Grid>
      )}
      <Section
        header={<Typography className={classes.sectionHeader}>Why You Matched</Typography>}
        color="rgb(235, 244, 250)"
      >
        <MatchedOn therapist={therapist} clientPreference={clientPreference} />
      </Section>
      <Section
        header={
          <div className={classes.insuranceHeader}>
            <InsuranceIcon className={classes.insuranceIcon} />
            <Typography className={classes.sectionHeader}>Insurance</Typography>
          </div>
        }
        color="rgb(255,230,223)"
      >
        <Insurance therapist={therapist} />
      </Section>
      <Section
        header={
          <Typography className={classes.sectionHeader}>{`Greeting message from ${therapist.firstName}`}</Typography>
        }
        color="rgb(239,239,239)"
      >
        <Typography className={classes.greeting}>{therapist.biography}</Typography>
        <div className={classes.readMoreWrapper}>
          <Link className={classes.readMore} href={therapist.profilePageLink} target="_blank">
            Learn more
          </Link>
        </div>
      </Section>
      {/*<Section><ProfileInfoContent therapist={therapist} /></Section>*/}
    </Grid>
  );
};

export default withMatchData(ExpandedSection);
