import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as MoveIcon } from "../../../assets/move-me.svg";
import styles from "./DragAndDropForm.module.css";
import { DragableOption } from "../../../types";

const DraggableElem: React.FC<DragableOption> = ({ value, label, index }: DragableOption) => {
  return (
    <Draggable draggableId={value} index={index}>
      {provided => (
        <div
          className={`${styles.draggableElem}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span data-testid="DraggableElem-content">{label}</span>
          <MoveIcon />
        </div>
      )}
    </Draggable>
  );
};

export default DraggableElem;
