import React, { Dispatch, SetStateAction, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import Carousel from ".";
import TherapistCard from "../TherapistCard";
import { MatchDataContextType } from "../../types";
import UpdatePreferencesCard from "./UpdatePreferences/UpdatePreferencesCard";
import { withMatchData } from "../../common/MatchDataProvider";
import NoMoreMatchesCard from "./NoMoreMatchesCard";

const useStyles = makeStyles(() => ({
  carousel: {
    width: "100%"
  }
}));

const TherapistCarousel: React.FC<{
  matchDataContext: MatchDataContextType;
  setTherapistIdWithOpenCalendar: Dispatch<SetStateAction<any>>;
  openCarouselIndex?: number;
  setOpenCarouselIndex: Dispatch<SetStateAction<number>>;
  setLearnMoreIndex?: Dispatch<SetStateAction<number>>;
  initialIndex?: number;
  preferenceSetIndex: number;
}> = ({
  matchDataContext,
  setTherapistIdWithOpenCalendar,
  openCarouselIndex = -1,
  setOpenCarouselIndex,
  setLearnMoreIndex,
  initialIndex,
  preferenceSetIndex
}) => {
  const classes = useStyles();

  const { therapists, clientPreference } = matchDataContext.matches[preferenceSetIndex];
  const { canUpdate } = clientPreference;

  const onLearnMoreClick = (clickedLearnedMoreIndex: number) => {
    if (setLearnMoreIndex) {
      setLearnMoreIndex(clickedLearnedMoreIndex);
    }
    setOpenCarouselIndex(preferenceSetIndex);
  };

  const isCarouselOpen = useMemo(() => {
    return openCarouselIndex >= 0;
  }, [openCarouselIndex]);

  return (
    <div className={classes.carousel}>
      <Carousel expanded={isCarouselOpen} initialIndex={initialIndex}>
        {therapists
          .map((therapist, idx) => (
            <TherapistCard
              key={therapist.id}
              therapist={therapist}
              setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
              expanded={isCarouselOpen}
              onLearnMore={() => onLearnMoreClick(idx)}
              matchIndex={preferenceSetIndex}
            />
          ))
          .concat(
            canUpdate ? (
              <UpdatePreferencesCard
                key="update preferences"
                expanded={isCarouselOpen}
                setOpenCarouselIndex={setOpenCarouselIndex}
                onUpdatePreferences={() => onLearnMoreClick(therapists.length)}
                matchIndex={preferenceSetIndex}
              />
            ) : (
              <NoMoreMatchesCard expanded={isCarouselOpen} key="more-matches" />
            )
          )}
      </Carousel>
    </div>
  );
};

export default withMatchData(TherapistCarousel);
