import React, { ReactNode, useRef, useState, useMemo, useEffect, TouchEvent } from "react";
import { makeStyles, IconButton, useTheme, useMediaQuery } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import classNames from "classnames";

const itemMargin = 8;
const transitionTime = 250;

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflowX: "hidden",
    paddingTop: 20,
    margin: "-20px 0",
    position: "relative"
  },
  wrapper: {
    display: "flex",
    width: "100%",
    position: "relative"
  },
  content: {
    display: "flex",
    transition: `all ${transitionTime}ms linear`,
    overflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    top: 0,
    left: 0,
    "&.expanded": {
      margin: "0 auto"
    },
    "& > item": {
      flex: "0 0 auto"
    }
  },
  item: {
    padding: `0 ${itemMargin}px`
  },
  navigation: {
    backgroundColor: "rgb(42,57,72)",
    position: "absolute",
    border: "1px solid rgb(255, 255, 255)",
    top: "calc(50% - 28px)",
    "&.expanded": {
      top: "50vh",
      position: "fixed"
    },
    "& svg": {
      color: "rgb(255, 255, 255)",
      fontSize: 24
    },
    "&:hover": {
      padding: 9,
      backgroundColor: "rgb(42,57,72)",
      "& svg": {
        fontSize: 30
      }
    },
    "&:focus": {
      backgroundColor: "rgb(42,57,72)"
    }
  }
}));

const Carousel: React.FC<{ children: ReactNode[]; expanded?: boolean; initialIndex?: number }> = ({
  children,
  expanded,
  initialIndex
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [indexInitialized, setIndexInitialized] = useState(false);
  const [canSlideRight, setCanSlideRight] = useState(false);
  const [canSlideLeft, setCanSlideLeft] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const itemsLength = children ? children.length : 0;

  useEffect(() => {
    setCurrentItemIndex(0);
  }, [itemsLength]);

  useEffect(() => {
    if (!indexInitialized && canSlideLeft && initialIndex) {
      setCurrentItemIndex(initialIndex);
      setIndexInitialized(true);
    }
  }, [canSlideLeft, indexInitialized, initialIndex]);

  const calculatedOffset = useMemo(() => {
    // set initial offset to show a fragment of previous card
    let result = currentItemIndex === 0 || mobile ? 0 : 16;

    // calculate offset on mobile so the card is centered
    let currentItem = itemsRef.current[currentItemIndex];
    if (mobile && currentItem && windowWidth > currentItem.clientWidth) {
      result += (windowWidth - currentItem.clientWidth) / 2;
    }

    // add the width of the items that are on left from the current item
    for (let i = 0; i < currentItemIndex; i += 1) {
      currentItem = itemsRef.current[currentItemIndex];
      result -= currentItem ? currentItem.clientWidth : 0;
    }

    return result;
  }, [currentItemIndex, mobile, windowWidth]);

  const slideLeft = () => {
    setCurrentItemIndex(prevState => prevState + 1);
  };

  const slideRight = () => {
    setCurrentItemIndex(prevState => prevState - 1);
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchPosition(e.touches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) return;

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      if (canSlideLeft) slideLeft();
    }

    if (diff < -5) {
      if (canSlideRight) slideRight();
    }

    setTouchPosition(null);
  };

  useEffect(() => {
    // use timeout to wait for container transition to complete
    const timeoutHandle = setTimeout(() => {
      let lastItemNotFullyVisible = true;
      const containerEl = containerRef.current;

      if (containerEl && windowWidth > 0) {
        lastItemNotFullyVisible = containerEl.getBoundingClientRect().right > windowWidth;
      }

      setCanSlideRight(currentItemIndex > 0);
      setCanSlideLeft(currentItemIndex < itemsLength - 1 && lastItemNotFullyVisible);
    }, transitionTime + 25);

    return () => clearTimeout(timeoutHandle);
  }, [currentItemIndex, itemsLength, windowWidth]);

  useEffect(() => {
    const onResize = () => {
      const htmlEl = document.querySelector("html");
      if (htmlEl) setWindowWidth(htmlEl.clientWidth);
    };

    window.addEventListener("resize", onResize);

    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <div className={classes.container} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <div className={classes.wrapper}>
        <div
          ref={containerRef}
          className={classNames(classes.content, { expanded })}
          style={{ left: `${calculatedOffset}px` }}
        >
          {children.map((item, idx) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className={classes.item}
              ref={el => {
                itemsRef.current[idx] = el;
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      {canSlideRight && (
        <IconButton
          onClick={slideRight}
          className={classNames(classes.navigation, { expanded })}
          style={{ left: expanded ? 16 : 0 }}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      {canSlideLeft && (
        <IconButton
          onClick={slideLeft}
          className={classNames(classes.navigation, { expanded })}
          style={{ right: expanded ? 16 : 0 }}
        >
          <ArrowForwardIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Carousel;
