import React, { Dispatch, SetStateAction } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import CustomSection from "../../common/layout/CustomSection";
import LeftSection from "./LeftSection";
import Hero from "./Hero";
import TherapistCarousel from "./Carousel/TherapistCarousel";
import curve3Flipped from "../../../assets/layout/curve-3-flipped.svg";

type PrimaryMatchesProps = {
  clientName: string;
  setTherapistIdWithOpenCalendar: Dispatch<SetStateAction<any>>;
  setOpenCarouselIndex: Dispatch<SetStateAction<number>>;
  setLearnMoreIndex?: Dispatch<SetStateAction<number>>;
};

const PrimaryMatches: React.FC<PrimaryMatchesProps> = ({
  clientName,
  setTherapistIdWithOpenCalendar,
  setOpenCarouselIndex,
  setLearnMoreIndex
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <CustomSection>
        <Typography
          style={{ fontSize: 20, fontWeight: "bold", marginBottom: "32px", textAlign: mobile ? "center" : "left" }}
        >
          MATCH RESULTS
        </Typography>
        <Typography variant="h1" style={{ fontFamily: "Tiempos", fontSize: mobile ? 42 : 62, marginBottom: "16px" }}>
          {`Made For ${clientName}`}
        </Typography>
      </CustomSection>

      <CustomSection limitWidth={false} withPadding={false}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <LeftSection>
              <Hero />
            </LeftSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <TherapistCarousel
              setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
              setOpenCarouselIndex={setOpenCarouselIndex}
              setLearnMoreIndex={setLearnMoreIndex}
              preferenceSetIndex={0}
            />
          </Grid>
        </Grid>
      </CustomSection>

      <CustomSection image={curve3Flipped} />
    </>
  );
};

export default PrimaryMatches;
