export const WELCOME: string = "Welcome";
export const INTRO: string = "Intro";
export const SUBMIT: string = "Submit";
export const SUMMARY: string = "Summary";
export const SINGLE_SELECTION: string = "SingleSelect";
export const SINGLE_SELECTION_DROPDOWN: string = "SingleSelectDropdown";
export const MULTIPLE_SELECTION: string = "MultiSelect";
export const SINGLE_SELECTION_WITH_TEXT: string = "SingleSelectWithText";
export const MULTIPLE_SELECTION_WITH_TEXT: string = "MultiSelectWithText";
export const TEXT: string = "Text";
export const TEXT_INPUT: string = "TextInput";
export const SLIDER_SELECTION: string = "MultiRange";
export const GRAPH_SELECTION: string = "GraphRange";
export const SORTABLE_SELECTION: string = "Sortable";
