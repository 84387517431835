import React, { SetStateAction, useState } from "react";
import { CircularProgress, Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import icon from "../../../assets/not_quite_right_icon.svg";
import FlatButton from "../../../../therapist-app/profile/common/FlatButton";
import UpdatePreferences from "./UpdatePreferences";
import { MatchDataContextType } from "../../../types";
import { withMatchData } from "../../../common/MatchDataProvider";
import { seeMoreMatches } from "../../../preferences";
import { withNotification } from "../../../../therapist-app/notification/NotificationProvider";

export type UpdatePreferencesCardProps = {
  expanded: boolean;
  onUpdatePreferences: () => void;
  notificationContext: any;
  matchDataContext: MatchDataContextType;
  matchIndex: number;
  setOpenCarouselIndex: React.Dispatch<SetStateAction<number>>;
};

const useStyles = makeStyles(theme => ({
  container: {
    width: 280,
    "&.expanded": {
      [theme.breakpoints.up("lg")]: {
        width: 380
      }
    }
  },
  paper: {
    borderRadius: 40,
    marginBottom: theme.spacing(0.5),
    minHeight: 460,
    position: "relative"
  },
  imageItem: {
    position: "relative",
    marginTop: theme.spacing(2.5)
  },
  icon: {
    height: 150,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(1)
  },
  cardCopy: {
    fontSize: 12,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    "&.noSeeMore": {
      fontSize: 15
    }
  },
  showMoreButton: {
    alignSelf: "center"
  },
  buttons: {
    bottom: 0,
    position: "absolute",
    width: "100%",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    "&.expanded": {
      position: "relative"
    }
  },
  seeMore: {
    paddingBottom: theme.spacing(1)
  },
  noMoreTherapists: {
    fontSize: 12,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "justify"
  },
  spinnerColor: {
    color: "white"
  },
  spinnerRoot: {
    padding: theme.spacing(0.5)
  }
}));

const UpdatePreferencesCard: React.FC<UpdatePreferencesCardProps> = ({
  expanded,
  onUpdatePreferences,
  notificationContext,
  matchDataContext,
  matchIndex,
  setOpenCarouselIndex
}) => {
  const classes = useStyles();

  const { loadData, matches } = matchDataContext;
  const { therapists, clientPreference } = matches[matchIndex];
  const { canSeeMoreMatches, id: clientPreferenceId } = clientPreference;

  const [sendUpdate, setSentUpdate] = useState(false);
  const [submitButtonCopy, setSubmitButtonCopy] = useState<string>(
    expanded ? "Submit for new matches" : "Update preferences"
  );
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [seeMoreMatchesButtonDisabled, setSeeMoreMatchesButtonDisabled] = useState(false);
  const [noMoreTherapists, setNoMoreTherapists] = useState(false);

  const onSubmitForNewMatches = () => {
    setSentUpdate(true);
    setSubmitButtonDisabled(true);
  };

  const onSeeMoreMatches = () => {
    setSeeMoreMatchesButtonDisabled(true);
    seeMoreMatches(
      clientPreferenceId,
      () => {
        loadData();
        setSeeMoreMatchesButtonDisabled(false);
      },
      () => {
        notificationContext.displayErrorNotification("An error occurred. Please refresh the page and try again");
        setSeeMoreMatchesButtonDisabled(false);
      }
    );
  };

  const Copy = () => {
    if (therapists.length === 0) {
      return (
        <Grid item xs={12} className={classes.cardCopy}>
          It looks like there are no perfect fits for the specific preferences you shared.
          <br />
          <br />
          Please adjust any flexible preferences once more to try again.
        </Grid>
      );
    }

    return (
      <Grid item xs={12} className={classNames(classes.cardCopy, { noSeeMore: expanded || !canSeeMoreMatches })}>
        First things first: book a consult with at least one of your matches to explore your fit in real time.
        <br />
        <br />
        If your matches are not a good fit, update your preferences below to receive a second set.
      </Grid>
    );
  };

  return (
    <div className={classNames(classes.container, { expanded })}>
      <Paper className={classes.paper} elevation={1}>
        <Grid container>
          <Grid item xs={12} className={classes.imageItem}>
            <img src={icon} className={classes.icon} alt="Card icon" />
          </Grid>
          <Grid item xs={12} className={classes.cardTitle}>
            Not quite right?
          </Grid>
          <Copy />
          {expanded && (
            <Grid item xs={12}>
              <UpdatePreferences
                matchIndex={matchIndex}
                sendUpdate={sendUpdate}
                setSendUpdate={setSentUpdate}
                preferenceSetIndex={matchIndex}
                setOpenCarouselIndex={setOpenCarouselIndex}
                setSubmitButtonCopy={setSubmitButtonCopy}
                setSubmitButtonDisabled={setSubmitButtonDisabled}
                setNoMoreTherapists={setNoMoreTherapists}
              />
            </Grid>
          )}
          <Grid item xs={12} id="contact" className={classNames(classes.buttons, { expanded })}>
            {!expanded && canSeeMoreMatches && (
              <Grid item xs={12} className={classes.seeMore}>
                <FlatButton
                  fullWidth
                  color="navy"
                  round
                  onClick={onSeeMoreMatches}
                  disabled={seeMoreMatchesButtonDisabled}
                >
                  {seeMoreMatchesButtonDisabled ? (
                    <CircularProgress
                      classes={{ colorPrimary: classes.spinnerColor, root: classes.spinnerRoot }}
                      color="primary"
                    />
                  ) : (
                    "See more, same preferences"
                  )}
                </FlatButton>
              </Grid>
            )}
            {noMoreTherapists && (
              <Typography className={classes.noMoreTherapists}>
                * There are no more therapists available for given preferences.
                <br />
                Please update your preferences if you want to get more matches.
              </Typography>
            )}
            <Grid item xs={12}>
              <FlatButton
                fullWidth
                color="navy"
                round
                disabled={submitButtonDisabled}
                onClick={expanded ? onSubmitForNewMatches : onUpdatePreferences}
              >
                {sendUpdate ? (
                  <CircularProgress
                    classes={{ colorPrimary: classes.spinnerColor, root: classes.spinnerRoot }}
                    color="primary"
                  />
                ) : (
                  submitButtonCopy
                )}
              </FlatButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default withNotification(withMatchData(UpdatePreferencesCard));
