import React from "react";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MaterialTooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: "11px 22px",
    fontSize: 12,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: "0.5px solid #ababab",
    borderRadius: 10
  },
  icon: {
    color: "#5196cd"
  }
}));

const Tooltip = ({ placement = "right", title = undefined, children = undefined }) => {
  const classes = useStyles();

  const QuestionmarkButton = React.forwardRef((props, ref) => {
    return (
      <IconButton {...props} ref={ref} aria-label="tooltip" size="small">
        <HelpIcon className={classes.icon} />
      </IconButton>
    );
  });

  return (
    <MaterialTooltip
      title={title}
      enterTouchDelay={50}
      leaveTouchDelay={0}
      interactive
      arrow
      placement={placement}
      classes={{ tooltip: classes.tooltip }}
    >
      {children || <QuestionmarkButton />}
    </MaterialTooltip>
  );
};

export default Tooltip;
