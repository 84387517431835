import React, { FC, useRef } from "react";
import classNames from "classnames";
import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      lineHeight: 1,
      marginBottom: 8,
      fontSize: 20
    },
    "&.alignLeft": {
      textAlign: "left"
    }
  }
}));

const ZayaCalculator: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery("@media (max-width:1400px)");

  const iframeRef = useRef(null);

  const marginBottom = mobile ? 16 : 32;

  return (
    <Grid container alignItems="flex-start" spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h2" className={classNames(classes.title, { alignLeft: !mobile })}>
          Save 30-100% of your session fee-even if your therapist does not accept your insurance in-network-using
          &quot;out-of-network benefits.&quot;
        </Typography>
        <br />
        <Typography>WHAT&apos;S THIS ALL ABOUT?</Typography>
        <br />
        <Typography style={{ fontFamily: "Tiempos" }}>
          Believe it or not, even when a therapist is not &quot;in-network&quot;, you are often eligible to receive
          30-70% of the session fee back from insurance.
        </Typography>
        <br />
        <Typography style={{ fontFamily: "Tiempos" }}>
          Enter your insurance card information into this instant calculator to learn how much you are eligible to save
          based on your specific insurance plan and current deductible.
        </Typography>
        <br />
        <Typography style={{ fontFamily: "Tiempos" }}>
          To earn these savings, you can either submit claims to your insurance company yourself after your session,
          or&nbsp;
          <a href="https://mentaya.com/?referrerId=mywellbeing">Mentaya</a> can do it for you automatically after each
          session for a 5% fee. On average, Mentaya gets their clients 62% back on therapy costs.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box display="flex" alignItems="center" style={{ marginBottom }}>
          <iframe
            ref={iframeRef}
            style={{
              height: sm ? "620px" : "420px",
              width: "1px",
              minWidth: "100%",
              border: "none",
              borderRadius: 20,
              maxWidth: 600,
              margin: "auto",
              display: "block"
            }}
            src="https://app.zaya.health/public/partners/BRNeuhBxXCsaABngx9o9/eligibility/widget"
            title="Check Zaya eligibility"
            id="mentayaWidget"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ZayaCalculator;
