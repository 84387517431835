export default {
  TOKEN_KEY: "authToken",

  isAuthenticated() {
    return !!this.getToken();
  },

  getToken() {
    return window.localStorage.getItem(this.TOKEN_KEY);
  },

  setToken(token) {
    window.localStorage.setItem(this.TOKEN_KEY, token);
  },

  clearToken() {
    window.localStorage.removeItem(this.TOKEN_KEY);
  }
};
