import React, { FC } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import handsetIcon from "../assets/handsetIcon.png";
import Tooltip from "../../common/Tooltip";
import VideoInstruction from "../../common/VideoInstruction";

const Hero: FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const marginBottom = mobile ? 16 : 32;

  return (
    <>
      <Box mb={3} display="flex" justifyContent={mobile ? "center" : "flex-start"}>
        <VideoInstruction videoId="FYo79M6p" title="Wondering where to start?" subtitle="2 min" />
      </Box>

      <Typography style={{ fontSize: mobile ? 20 : 32, marginBottom }}>Your next step:</Typography>

      <Grid container alignItems="flex-start">
        <Grid item>
          <img src={handsetIcon} alt="Handset" style={{ marginRight: 16 }} />
        </Grid>
        <Grid item xs>
          <Typography style={{ fontFamily: "Tiempos", fontSize: 20, marginBottom }}>
            Schedule a brief, free&nbsp;phone&nbsp;consult
            <br />
            with each of your matches
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography>Why book consult calls?</Typography>
            <Tooltip
              placement="right-start"
              title={
                <Typography variant="body2">
                  Consultation calls are a brief, free opportunity to confirm your fit.
                  <br />
                  <br />
                  Over 70% of rapport is carried through the voice, and of course, there is only so much that a
                  headshot/bio combination on a piece of paper can tell you. These calls are a zero-cost,
                  low-time-commitment opportunity to experience a small preview of what working with this provider will
                  be like.
                  <br />
                  <br />
                  We encourage you to book with all of your matches so that you can compare how you feel with each to
                  inform your decision.
                  <br />
                  <br />
                  If you&apos;re nervous about who to choose, a piece of perspective: we like to say that you will
                  definitely know if it&apos;s a hard &quot;NO&quot;, and if you&apos;re not sure, consider the provider
                  a &quot;Maybe&quot; who is worth another session (or few) to further gauge how you feel. Sometimes,
                  personal work takes time, so be patient with yourself and trust the process.
                </Typography>
              }
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>What to say during your calls?</Typography>
            <Tooltip
              placement="right-start"
              title={
                <Typography variant="body2">
                  Great question! Here are a couple of suggestions:
                  <br />
                  <br />
                  1. Let your provider know what brought you to search for care.
                  <br />
                  2. Share a little bit about yourself with your provider and ask if they have worked with anyone like
                  you before.
                  <br />
                  3. Ask your provider what working with them looks and feels like. As you listen to their answer,
                  check-in with yourself and jot down a note: How do you feel? Does their description sound like
                  something you&apos;d like to try?
                </Typography>
              }
            />
          </Box>
          <Box display="flex" alignItems="center" style={{ marginBottom }}>
            <Typography>How do you book the calls?</Typography>
            <Tooltip
              placement="right-start"
              title={
                <Typography variant="body2">
                  We&apos;ve got you. If your provider has given MyWellbeing access to their calendar, you will see a
                  &quot;Book consult now!&quot; button that will open their availability for you to book your consult
                  call right here and now. All providers will have the option for you to email them to schedule your
                  consultation call. If you prefer to email, we have prepared a template that you can use, which you
                  will see when you click the &quot;Email to schedule&quot; option. You can edit the email&apos;s
                  subject or body as you see fit. Happy scheduling!
                </Typography>
              }
            />
          </Box>
        </Grid>
        {mobile && (
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: 20, textAlign: mobile ? "center" : "left", marginLeft: mobile ? 0 : "80px" }}
            >
              Let&apos;s go
            </Typography>
            <Typography
              style={{
                fontSize: 36,
                textAlign: mobile ? "center" : "left",
                marginLeft: mobile ? 0 : "108px",
                marginBottom: "24px"
              }}
            >
              &darr;
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Hero;
