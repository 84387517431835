/* eslint-disable react/no-unused-state */
import React from "react";

const NotificationContext = React.createContext();

export const withNotification = Component => ({ ...props }) => (
  <NotificationContext.Consumer>
    {notificationContext => <Component notificationContext={notificationContext} {...props} />}
  </NotificationContext.Consumer>
);

export default class NotificationProvider extends React.Component {
  state = {
    message: "",
    variant: "info",
    open: false,
    displaySuccessNotification: this.displaySuccessNotification.bind(this),
    displayErrorNotification: this.displayErrorNotification.bind(this),
    closeNotification: this.closeNotification.bind(this)
  };

  displaySuccessNotification(message) {
    this.setState({ message, variant: "success", open: true });
  }

  displayErrorNotification(message) {
    this.setState({ message, variant: "error", open: true });
  }

  closeNotification() {
    this.setState({ open: false });
  }

  render() {
    const { children } = this.props;

    return <NotificationContext.Provider value={this.state}>{children}</NotificationContext.Provider>;
  }
}
