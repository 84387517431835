import React from "react";
import { ModalLinkProps } from "../../../types";
import StepContainer from "../../common/StepContainer";

export type TextData = {
  text: string;
  checkboxesValues?: { [key: string]: boolean };
};

export type TextFormProps = {
  title: string;
  onNext?: () => void;
  topLink?: ModalLinkProps;
  subtitle?: string;
};

const TextForm: React.FC<TextFormProps> = ({ title, topLink, subtitle, onNext }) => {
  return (
    <StepContainer
      title={title}
      subtitle={subtitle}
      topLink={topLink}
      isValid
      onNext={onNext}
      buttonAsText
      buttonLabel="Next question"
    />
  );
};

export default TextForm;
