import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckboxElement from "./CheckboxElement";
import { CheckboxDetails } from "../../types";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8
    }
  },
  item: {
    width: "100%"
  }
}));

export type CheckboxesGroupProps = {
  checkboxesDetails: Array<CheckboxDetails>;
  checkboxesValues: { [key: string]: boolean };
  setCheckboxesValues: Dispatch<SetStateAction<{ [key: string]: boolean } | undefined>>;
  setCheckboxesValid: Dispatch<SetStateAction<boolean>>;
  setCheckboxErrorMessage: Dispatch<SetStateAction<string>>;
};

const CheckboxesGroup: React.FC<CheckboxesGroupProps> = ({
  checkboxesDetails,
  checkboxesValues,
  setCheckboxesValues,
  setCheckboxesValid,
  setCheckboxErrorMessage
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const invalidCheckbox = checkboxesDetails.find(
      checkboxDetails => checkboxDetails.required && !checkboxesValues[checkboxDetails.key]
    );

    setCheckboxesValid(!invalidCheckbox);
    setCheckboxErrorMessage((invalidCheckbox && invalidCheckbox.errorMessage) || "");
  }, [checkboxesDetails, checkboxesValues, setCheckboxesValid, setCheckboxErrorMessage]);

  return (
    <Grid container direction="column" alignItems="center" spacing={mobile ? 1 : 0} className={classes.container}>
      {checkboxesDetails.map(checkboxData => (
        <Grid item key={checkboxData.key} className={classes.item}>
          <CheckboxElement
            checkboxLabel={checkboxData.label}
            setSavedData={checked => setCheckboxesValues({ ...checkboxesValues, [checkboxData.key]: checked })}
            savedData={checkboxesValues[checkboxData.key]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxesGroup;
