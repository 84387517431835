import React from "react";
import classnames from "classnames";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export interface OptionButtonProps {
  id?: string;
  label: string;
  title?: string;
  subtitle?: string;
  description?: string;
  imageUrl?: string;
  checked: boolean;
  onClick: (event: React.MouseEvent) => void;
  shape?: "circle" | "rectangle";
  small?: boolean;
  withInput?: {
    value: string;
    placeholder: string;
    onChange: (event: string) => void;
  };
}

const useStyles = makeStyles(theme => ({
  button: {
    background: "#FFFFFF",
    color: "#2A3948",
    textAlign: "center",
    margin: 8,
    padding: 12,
    height: 56,
    width: 280,
    borderRadius: 28,
    fontSize: 18,
    lineHeight: "18px",
    [theme.breakpoints.down("xs")]: {
      margin: "6px 0",
      height: 52,
      borderRadius: 26
    },
    "& .container": {
      height: "100%",
      alignContent: "center",
      "& .content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }
    },
    "&.circle": {
      height: 130,
      width: 130,
      borderRadius: "50%",
      [theme.breakpoints.down("xs")]: {
        height: 110,
        width: 110,
        margin: 4
      }
    },
    "&.rectangle": {
      height: 380,
      width: 245,
      borderRadius: 18,
      "& .container": {
        [theme.breakpoints.up("md")]: {
          alignContent: "flex-start"
        }
      },
      "& .content": {
        height: 160,
        [theme.breakpoints.down("sm")]: {
          height: 76
        }
      },
      "& .title": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 600,
        color: theme.palette.primary.light,
        fontFamily: "Times"
      },
      "& .text": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 600
      },
      "& .subtitle": {
        marginTop: 8
      },
      "& .description": {
        fontFamily: "Tiempos, Times, serif",
        marginTop: 24
      },
      [theme.breakpoints.down("sm")]: {
        height: 100,
        width: 280,
        "& .title": {
          fontSize: 18,
          lineHeight: "24px"
        },
        "& .description": {
          display: "none"
        }
      }
    }
  },
  imageContent: {
    height: 180,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      height: 76,
      alignItems: "center",
      padding: 0
    }
  },
  image: {
    display: "block",
    width: "auto",
    // maxWidth: 200,
    height: "100%",
    // marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0
    }
  },
  buttonSelected: {
    color: "#F19E86",
    border: "4px solid #F19E86",
    padding: 8
  },
  small: {
    height: 40,
    width: "inherit",
    fontSize: 14,
    padding: 11,
    margin: 5,
    border: "1px solid #D9EBF9"
  },
  lightBorder: {
    padding: 11,
    border: "1px solid #F19E86"
  },
  inputField: {
    margin: "0 7px",
    fontSize: 14
  }
}));

const OptionButton: React.FC<OptionButtonProps> = ({
  id,
  label,
  title,
  subtitle,
  description,
  imageUrl,
  checked,
  shape,
  onClick,
  small = false,
  withInput = undefined
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonBase
      component="div"
      id={id}
      focusRipple
      onClick={onClick}
      classes={{
        root: classnames(classes.button, {
          circle: shape === "circle",
          rectangle: shape === "rectangle",
          [classes.buttonSelected]: checked,
          [classes.small]: small,
          [classes.lightBorder]: small && checked
        })
      }}
    >
      <Grid container className="container">
        {shape === "rectangle" && !mobile && (
          <Grid item md={12}>
            <div className="title" dangerouslySetInnerHTML={{ __html: title ? title : "&nbsp;" }} />
          </Grid>
        )}
        {imageUrl && (
          <Grid item xs={4} md={12} className={classes.imageContent}>
            <img src={imageUrl} alt="icon" className={classes.image} />
          </Grid>
        )}
        <Grid item xs={imageUrl ? 8 : 12} md={12} className="content">
          <div>
            <div className="text" dangerouslySetInnerHTML={{ __html: label }} />
            {subtitle && <div className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
            {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
            {withInput ? (
              <TextField
                id="additional input"
                placeholder={withInput.placeholder}
                value={withInput.value}
                margin="dense"
                InputProps={{
                  className: classes.inputField
                }}
                onChange={event => withInput.onChange(event.target.value)}
              />
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default OptionButton;
