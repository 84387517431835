import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import React from "react";
import FlatButton from "../profile/common/FlatButton";

const SubmitButton = ({ inProgress, children, ...props }) => {
  if (inProgress) {
    return <CircularProgress size={25} />;
  }
  return <FlatButton {...props}>{children}</FlatButton>;
};

export default SubmitButton;
