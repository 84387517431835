import React, { Dispatch, SetStateAction, useState } from "react";
import { ResponsiveContainer, Area, AreaChart } from "recharts";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ModalLinkProps, SelectionFieldProps, StepItems } from "../../../types";
import StepContainer from "../../common/StepContainer";
import SliderElement from "../../../../common/SliderElement";

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: -13,
    "@media (pointer: coarse)": {
      marginTop: -20
    }
  }
}));

export type GraphFormProps = {
  title: string;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: string;
  topLink?: ModalLinkProps;
  subtitle?: string;
  subtitle2?: string;
  item: StepItems;
  onNext: () => void;
};

const GraphForm: React.FC<GraphFormProps> = ({
  title,
  subtitle,
  subtitle2,
  item,
  topLink,
  savedData,
  setSavedData,
  onNext
}) => {
  const classes = useStyles();
  const value = savedData || "350";
  const [tmpValue, setTmpValue] = useState(Number(value));

  const onUpdate = (newValue: number) => {
    setSavedData(newValue);
  };

  const onChange = (newTmpValue: number) => {
    setTmpValue(newTmpValue);
  };

  const options = item.options as Array<SelectionFieldProps>;
  const chartOptions = options.map(({ value }) => ({ value: Number(value) }));
  const minValue = Number(options[0].label);
  const maxValue = Number(options[options.length - 1].label);
  const percentage = ((tmpValue - minValue) / (maxValue - minValue)) * 100;

  return (
    <StepContainer
      title={title}
      subtitle={subtitle}
      subtitle2={subtitle2}
      topLink={topLink}
      onNext={onNext}
      isValid={true}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          {item.title && (
            <Typography variant="body2" align="center">
              <b>{item.title}</b>
            </Typography>
          )}

          <ResponsiveContainer width="100%" height={200}>
            <AreaChart margin={{ top: 0, left: 0, bottom: 0, right: 0 }} data={chartOptions}>
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                  <stop offset="0%" stopColor="#32668f" />
                  <stop offset={`${percentage}%`} stopColor="#32668f" />
                  <stop offset={`${percentage}%`} stopColor="#32668f" stopOpacity={0.5} />
                  <stop offset="100%" stopColor="#32668f" stopOpacity={0.5} />
                </linearGradient>
              </defs>
              <Area type="monotone" dataKey="value" stroke="#8884d8" fill="url(#gradient)" />
            </AreaChart>
          </ResponsiveContainer>

          <div className={classes.slider}>
            <SliderElement
              minValue={minValue}
              maxValue={maxValue}
              minLabel={`$${minValue}`}
              maxLabel={`$${maxValue}`}
              selectedValue={value}
              step={10}
              displayValueLabel
              withDefaultTrack
              onUpdate={onUpdate}
              onChange={onChange}
              valueLabelFormat={(value: number, index: number) => `$${value}`}
            />
          </div>
        </Grid>
      </Grid>
    </StepContainer>
  );
};

export default GraphForm;
