import React, { lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import MatchApp from "./components/match-page/App";
import MatchingForm from "./components/client-form-wizard/components/MatchingForm";

const TherapistApp = lazy(() => import("./components/therapist-app/App"));
const ReferralPage = lazy(() => import("./components/referral-page/ReferralPage"));

const Router: React.FC = () => {
  const location = useLocation();

  return (
    <Switch>
      <Route path="/matched" component={MatchApp} />
      <Route path="/therapist-app" component={TherapistApp} />
      <Route path="/get-started" component={MatchingForm} />
      <Route exact path="/ref" component={ReferralPage} />
      <Redirect from="/login" to="/therapist-app" />
      <Redirect from="/signup" to={{ pathname: "/therapist-app/signup", search: location.search }} />
      <Redirect from="/get-matched" to={{ pathname: "/get-started", search: location.search }} />
      <Redirect from="/" to="/therapist-app" />
    </Switch>
  );
};

export default Router;
