import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles(() => ({
  button: {
    position: "absolute",
    top: 25,
    left: 25
  },
  backArrow: {
    fontSize: 24,
    color: "inherit"
  }
}));

interface BackButtonProps {
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onBackClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonBase focusRipple onClick={onBackClick} data-cy="form-back-button" className={classes.button}>
      <ArrowBackIosIcon className={classes.backArrow} />
      <Typography variant={mobile ? "body1" : "h6"} align="center">
        <strong>Back</strong>
      </Typography>
    </ButtonBase>
  );
};

export default BackButton;
