import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { TherapistType } from "../../types";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 14
  }
}));

const Insurance: React.FC<{ therapist: TherapistType }> = ({ therapist }) => {
  const classes = useStyles();

  const listStrings = (list: string[]) => {
    let listed = "";

    list.map((item, idx) => {
      if (idx === list.length - 1) listed += ` and ${item}`;
      else if (idx > 0) listed += `, ${item}`;
      else listed += `${item}`;
      return listed;
    });

    return listed;
  };

  const insuranceList =
    therapist.insurance && therapist.insurance.length > 0 ? listStrings(therapist.insurance) : undefined;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.text}>
          {insuranceList
            ? `This provider accepts ${insuranceList} in network. For all other insurances, this provider is an out of
                 network provider.`
            : `This provider does not accept insurance in-network, but you can still save 30-100% of the session fee by
                 using "out-of-network benefits."`}
        </Typography>
        {!insuranceList && (
          <Typography className={classes.text}>
            See how much you are eligible to save by inputting your insurance card info into the instant calculator,
            which you will find by closing out of these provider cards and scrolling down on your match results page.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Insurance;
