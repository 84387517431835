import React, { useState, FC, Dispatch } from "react";
import { PopupModal } from "react-calendly";
import Mustache from "mustache";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { ReactComponent as EmailIcon } from "../../assets/email.svg";
import { ReactComponent as PointerIcon } from "../../assets/pointer.svg";
import { sendEvent } from "../../events";
import ContactForm from "../../../common/ContactForm";
import { hjTagRecording } from "../../../../utils/hotjar";
import FlatButton from "../../../therapist-app/profile/common/FlatButton";
import { TherapistType, MatchDataContextType } from "../../types";
import Tooltip from "../../../common/Tooltip";
import { withMatchData } from "../../common/MatchDataProvider";

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 5,
    height: 20,
    width: "auto"
  },
  learnMore: {
    marginRight: 5
  },
  buttonLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

type ContactDetailsProps = {
  therapist: TherapistType;
  setTherapistIdWithOpenCalendar: Dispatch<number | undefined>;
  onLearnMore: () => void;
  expanded: boolean;
  matchDataContext: MatchDataContextType;
};

const ContactDetails: FC<ContactDetailsProps> = ({
  therapist,
  setTherapistIdWithOpenCalendar,
  onLearnMore,
  expanded,
  matchDataContext
}) => {
  const classes = useStyles();

  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const documentRoot = document.getElementById("root");

  const { client } = matchDataContext;
  const { name, email } = client;
  const { id, firstName, calendar } = therapist;

  const { messageTemplate } = matchDataContext;

  const messageContent =
    messageTemplate && messageTemplate.content
      ? Mustache.render(messageTemplate.content, { therapist_name: firstName, client_name: name })
      : undefined;

  const messageSubject = messageTemplate && messageTemplate.subject ? messageTemplate.subject : undefined;

  const onEmailClick = () => {
    setIsContactFormOpen(true);
    try {
      if (typeof window.gtag === "function") {
        window.gtag("event", "conversion", { send_to: "AW-809257514/C-9ECNaX1YoCEKqU8YED" });
      }
      hjTagRecording("Match Result | reach out by email clicked");
      sendEvent("email_contact_clicked", { therapist_id: id });
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const onCalendarClick = () => {
    if (calendar) {
      const isCalendly = calendar.includes("calendly");

      if (isCalendly) {
        setTherapistIdWithOpenCalendar(therapist.id);

        setIsCalendlyOpen(true);
      } else {
        window.open(calendar, "_blank");
      }
      try {
        if (typeof window.gtag === "function") {
          window.gtag("event", "conversion", { send_to: "AW-809257514/3RhXCNmm1YoCEKqU8YED" });
        }
        hjTagRecording("Match Result | book your free call clicked");
        sendEvent("calendar_contact_clicked", {
          therapist_id: id,
          calendly: isCalendly
        });
        // eslint-disable-next-line no-empty
      } catch {}
    }
  };

  const noCalendar = !calendar || calendar === "";

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip
            placement="bottom"
            title={
              noCalendar
                ? "This provider has not yet shared their calendar link. Please email the provider to schedule"
                : ""
            }
          >
            <span>
              <FlatButton
                fullWidth
                color="navy"
                onClick={onCalendarClick}
                round
                dataTrack="book-call"
                id={`${id}-phone-button`}
                disabled={noCalendar}
              >
                <div className={classes.buttonLine}>
                  <PointerIcon className={classes.icon} />
                  <span>Book consult now</span>
                </div>
              </FlatButton>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <FlatButton
            fullWidth
            color="navy"
            onClick={onEmailClick}
            round
            dataTrack="reach-out-by-email"
            id={`${id}-email-button`}
          >
            <div className={classes.buttonLine}>
              <EmailIcon className={classes.icon} />
              Email to schedule
            </div>
          </FlatButton>
        </Grid>

        {!expanded && (
          <Grid item xs={12}>
            <FlatButton color="white" fullWidth smallerFont round onClick={onLearnMore}>
              <Typography className={classes.learnMore}>Learn More</Typography>
            </FlatButton>
          </Grid>
        )}
      </Grid>

      <ContactForm
        therapist={therapist}
        clientEmail={email}
        clientName={name}
        messageSubject={messageSubject}
        messageContent={messageContent}
        isOpen={isContactFormOpen}
        onClose={() => setIsContactFormOpen(false)}
        showNameAndEmail={false}
      />

      {documentRoot && calendar && (
        <PopupModal
          url={calendar}
          prefill={{ email, name }}
          pageSettings={{ hideEventTypeDetails: false, hideLandingPageDetails: true }}
          rootElement={documentRoot}
          open={isCalendlyOpen}
          onModalClose={() => setIsCalendlyOpen(false)}
        />
      )}
    </>
  );
};

export default withMatchData(ContactDetails);
