import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: "Avenir",
    lineHeight: "normal",
    minWidth: 170,
    height: 40,
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 14,
    "&.smallerFont": {
      fontSize: 12
    },
    "&.radius": {
      borderRadius: 10
    },
    "&.leftRadius": {
      borderRadius: "10px 0 0 10px"
    },
    "&.rightRadius": {
      borderRadius: " 0 10px 10px 0"
    },
    "&.round": {
      borderRadius: 20
    },
    "&.border": {
      border: "1px solid #c0cdd7",
      height: 38
    },
    "&.blue": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "@media not all and (hover: none)": {
        "&:hover": {
          backgroundColor: theme.palette.primary.dark
        }
      },
      "&.disabled": {
        backgroundColor: theme.palette.primary.light
      }
    },
    "&.orange": {
      color: "#2A3948",
      backgroundColor: "#F19E86",
      "@media not all and (hover: none)": {
        "&:hover": {
          backgroundColor: "#b75b42"
        }
      },
      "&.disabled": {
        backgroundColor: "#eac4b8"
      }
    },
    "&.white": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      "@media not all and (hover: none)": {
        "&:hover": {
          border: "1px solid"
        }
      },
      "&.disabled": {
        color: theme.palette.primary.light
      }
    },
    "&.red": {
      backgroundColor: "#C94A4C",
      "@media not all and (hover: none)": {
        "&:hover": {
          backgroundColor: "#b33436"
        }
      },
      "&.disabled": {
        backgroundColor: "#d49697"
      }
    },
    "&.purple": {
      backgroundColor: "#6B315D",
      "@media not all and (hover: none)": {
        "&:hover": {
          backgroundColor: "#662857"
        }
      },
      "&.disabled": {
        backgroundColor: "#834f77"
      }
    },
    "&.navy": {
      backgroundColor: "rgb(42,57,72)",
      "@media not all and (hover: none)": {
        "&:hover": {
          backgroundColor: "rgba(42,57,72,0.95)"
        }
      },
      "&.disabled": {
        opacity: 0.5,
        color: theme.palette.common.white,
        "& > *": {
          opacity: 0.5
        }
      }
    }
  }
}));

const FlatButton: FC<{
  children: ReactNode;
  onClick?: () => void;
  href?: string;
  color?: "blue" | "orange" | "white" | "red" | "purple" | "navy";
  disabled?: boolean;
  fullWidth?: boolean;
  inactive?: boolean;
  withRadius?: boolean;
  leftRadius?: boolean;
  rightRadius?: boolean;
  withBorder?: boolean;
  id?: string;
  dataTrack?: string;
  smallerFont?: boolean;
  round?: boolean;
}> = ({
  children,
  disabled,
  inactive,
  color = "blue",
  fullWidth,
  withRadius,
  leftRadius,
  rightRadius,
  withBorder,
  onClick,
  href,
  id,
  dataTrack,
  smallerFont,
  round
}) => {
  const classes = useStyles();

  return (
    // @ts-ignore
    <Button
      id={id}
      className={classNames(classes.button, {
        disabled: disabled || inactive,
        [color]: color,
        radius: withRadius,
        border: withBorder,
        leftRadius: leftRadius,
        rightRadius: rightRadius,
        smallerFont,
        round
      })}
      onClick={onClick}
      href={href}
      target={href ? "_blank" : undefined}
      fullWidth={fullWidth}
      disabled={disabled}
      data-track={dataTrack}
    >
      {children}
    </Button>
  );
};

export default FlatButton;
