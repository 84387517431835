import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../../config";
import TextInputForm, { TextData, TextInputFormProps } from "../TextInputForm/TextInputForm";

const SubmitForm: React.FC<TextInputFormProps> = ({
  title,
  setSavedData,
  savedData,
  topLink,
  subtitle,
  subtitle2,
  onNext,
  isNumericalFiled,
  isEmailField,
  maskFormat,
  minLength,
  maxLength,
  minValue,
  maxValue,
  textInputLabel,
  textPlaceholder,
  adornment,
  customErrorMessage,
  validationMessage,
  checkboxesDetails
}) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (submitting && savedData.captcha) {
      onNext();
      if (recaptchaRef && recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  }, [submitting, savedData, onNext]);

  const onCaptchaChange = (value: string | null) => {
    if (value) {
      setSavedData({ ...savedData, captcha: value });

      setSubmitting(true);
    }
  };

  const executeCaptcha = () => {
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const onSubmitClick = () => {
    executeCaptcha();
  };

  return (
    <>
      <TextInputForm
        title={title}
        subtitle={subtitle}
        subtitle2={subtitle2}
        setSavedData={setSavedData}
        savedData={savedData as TextData}
        onNext={onSubmitClick}
        topLink={topLink}
        isNumericalFiled={isNumericalFiled}
        isEmailField={isEmailField}
        maskFormat={maskFormat}
        minLength={minLength}
        maxLength={maxLength}
        minValue={minValue}
        maxValue={maxValue}
        adornment={adornment}
        textInputLabel={textInputLabel}
        textPlaceholder={textPlaceholder}
        customErrorMessage={customErrorMessage}
        validationMessage={validationMessage}
        checkboxesDetails={checkboxesDetails}
        buttonLabel="Get My Matches"
      />
      <ReCAPTCHA ref={recaptchaRef} onChange={onCaptchaChange} sitekey={RECAPTCHA_SITE_KEY} size="invisible" />
    </>
  );
};

export default SubmitForm;
