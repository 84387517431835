import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ButtonBase, Typography, useMediaQuery, Box } from "@material-ui/core";
import ChapterLayout from "./ChapterLayout";

const useTitleStyles = makeStyles(theme => ({
  title: {
    fontSize: "5rem",
    fontFamily: "Tiempos, Times, serif",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem"
    }
  },
  header: {
    "& span": {
      borderTop: "2px solid black",
      padding: "14px 5px 0",
      fontFamily: "Tiempos, Times, serif"
    }
  }
}));

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontFamily: "Tiempos, Times, serif",
    margin: "0 10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      margin: 0
    }
  },
  button: {
    fontSize: 20,
    borderBottom: "2px solid black",
    padding: 6,
    marginTop: 24,
    "&:hover": {
      paddingBottom: 4,
      borderBottomWidth: 4
    }
  }
}));

type IntroProps = {
  title: string;
  subtitle?: string;
  header?: string;
  backgroundImageUrl?: string;
  backgroundMobileImageUrl?: string;
  onNext: () => void;
};

// eslint-disable-next-line no-undef
type TitleProps = Pick<IntroProps, "title" | "header">;

const Title: React.FC<TitleProps> = ({ header, title }) => {
  const classes = useTitleStyles();

  return (
    <div>
      {header && (
        <Typography variant="h4" align="center" className={classes.header}>
          <span>{header}</span>
        </Typography>
      )}
      <Typography variant="h1" align="center" className={classes.title} gutterBottom>
        {title}
      </Typography>
    </div>
  );
};

const Intro: React.FC<IntroProps> = ({
  title,
  subtitle,
  header,
  backgroundImageUrl,
  backgroundMobileImageUrl,
  onNext
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ChapterLayout
      title={<Title header={header} title={title} />}
      footer={
        <Box display="flex" justifyContent={mobile ? "center" : "flex-end"}>
          <ButtonBase focusRipple onClick={onNext} className={classes.button}>
            <Typography variant="h6" align="center">
              <strong>Next question</strong>
            </Typography>
          </ButtonBase>
        </Box>
      }
      backgroundImageUrl={backgroundImageUrl || "/backgrounds/big/flowers.jpeg"}
      backgroundMobileImageUrl={backgroundMobileImageUrl || "/backgrounds/small/flowers.jpeg"}
    >
      {subtitle && (
        <Typography
          variant="h6"
          align="center"
          className={classes.subtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </ChapterLayout>
  );
};

export default Intro;
