import React, { ReactNode } from "react";
import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingTop: 20
  },
  closeIcon: {
    position: "fixed",
    zIndex: 101,
    top: 8,
    right: 16,
    backgroundColor: "rgb(42,57,72)",
    border: "1px solid rgb(255, 255, 255)",
    "& svg": {
      color: "rgb(255, 255, 255)",
      fontSize: 24
    },
    "&:hover": {
      padding: 9,
      backgroundColor: "rgb(42,57,72)",
      "& svg": {
        fontSize: 30
      }
    },
    "&:focus": {
      backgroundColor: "rgb(42,57,72)"
    }
  }
}));

const LearnMore: React.FC<{
  children: ReactNode | ReactNode[];
  isOpen: boolean;
  onClose: () => void;
}> = ({ children, isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }}>
      <Box>
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Dialog>
  );
};

export default LearnMore;
