/* eslint-disable */
export const trackPage = (path: string, title: string | undefined) => {
  if (gtag) {
    gtag("event", "pageview", {
      page_path: path,
      page_title: title
    });
  }
};

export const trackFormAbandonment = (formId: number, stepTitle: string) => {
  if (gtag) {
    gtag("event", "formAbandonment", {
      eventCategory: "Form Abandonment",
      eventAction: stepTitle,
      eventLabel: `Version ${formId}`
    });
  }
};
