import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Typography from "@material-ui/core/Typography/Typography";
import { withStyles } from "@material-ui/core";

const style = theme => ({
  root: {
    padding: "100px 0",
    textAlign: "center"
  },
  loadingText: {
    marginTop: theme.spacing(2)
  }
});

const LoadingData = ({ classes, text }) => (
  <div className={classes.root}>
    <CircularProgress />
    <Typography variant="h5" className={classes.loadingText}>
      {text}
    </Typography>
  </div>
);

export default withStyles(style)(LoadingData);
