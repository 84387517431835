import React from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import logo from "../../../../assets/logo.png";
import logoIcon from "../../../../assets/logo-icon.png";
import BackButton from "./BackButton";

const useStyles = makeStyles(() => ({
  header: {
    width: "100%",
    height: 60,
    padding: "20px 20px 6px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fffff",
    "&.blueBackground": {
      backgroundColor: "#CFE3ED"
    }
  },
  logo: {
    height: 34,
    cursor: "pointer"
  }
}));

const Header: React.FC<{
  goBack?: () => void;
  blueBackground?: boolean;
}> = ({ goBack, blueBackground }) => {
  const classes = useStyles();
  const welcomeStep = ["/get-started", "/get-started/welcome"].includes(window.location.pathname);

  return (
    <header className={classNames(classes.header, { blueBackground })}>
      {goBack && <BackButton onBackClick={goBack} />}
      <a href="https://mywellbeing.com" target="_blank" rel="noopener noreferrer">
        <img src={welcomeStep ? logo : logoIcon} alt="Logo" className={classes.logo} />
      </a>
    </header>
  );
};

export default Header;
