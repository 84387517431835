import React, { FC } from "react";
import classNames from "classnames";
import { Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import availability from "../../assets/availability.svg";
import fee from "../../assets/fee.svg";
import age from "../../assets/age.svg";
import orientation from "../../assets/orientation.svg";
import state from "../../assets/state.svg";
import expertise from "../../assets/expertise.svg";
import gender from "../../assets/gender.svg";
import style from "../../assets/style.svg";
import raceEthnicity from "../../assets/race_ethnicity.svg";
import { ClientPreferenceType, TherapistType } from "../../types";
import Tooltip from "../../../common/Tooltip";
import { AgeRangeType, AvailabilityType, IdNameKeyType, OrientationType } from "../../../therapist-app/profile/types";

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 8,
    fontWeight: "bold",
    textAlign: "center"
  },
  wrapper: {
    "&.disabled": {
      opacity: 0.35
    }
  },
  icon: {
    height: 30,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  iconTitle: {
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center"
  },
  description: {
    fontSize: 12
  }
}));

const Item: FC<{
  title: string;
  icon: string;
  disabled: boolean;
  tooltip?: any;
}> = ({ title, icon, disabled, tooltip }) => {
  const classes = useStyles();

  const element = () => (
    <div>
      <img src={icon} className={classes.icon} alt={title} />
      <Typography className={classes.iconTitle} dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );

  return (
    <Grid item xs={4}>
      <div className={classNames(classes.wrapper, { disabled })}>
        {tooltip ? (
          <Tooltip title={tooltip} placement="bottom">
            {element()}
          </Tooltip>
        ) : (
          element()
        )}
      </div>
    </Grid>
  );
};

const MatchedOn: FC<{ therapist: TherapistType; clientPreference: ClientPreferenceType }> = ({
  therapist,
  clientPreference
}) => {
  const {
    firstName,
    feeOverlaps,
    overlappingFee,
    ageOverlaps,
    overlappingAge,
    lgbtqiaOverlaps,
    overlappingLgbtqia,
    genderOverlaps,
    overlappingGender,
    stylesOverlap,
    overlappingStyles,
    ethnicitiesOverlap,
    overlappingEthnicities,
    availabilitiesOverlap,
    overlappingAvailabilities,
    specializationsOverlap,
    overlappingSpecializations,
    specializationDescription
  } = therapist;
  const {
    feeRanges,
    ages,
    lgbtqia,
    genders,
    styles,
    ethnicities,
    availabilities,
    specializations,
    mostImportantFactor,
    mostImportantSpecialization
  } = clientPreference;

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const overlapString = (overlap: string[], customTherapistVerb: string) => (
    <div>
      {customTherapistVerb || "This provider matches:"}
      <br />
      <ul>
        {overlap.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );

  const clientChoiceString = (clientChoice: (AgeRangeType | IdNameKeyType | AvailabilityType | OrientationType)[]) => (
    <div>
      You requested:
      <ul>
        {clientChoice.map(item => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
    </div>
  );

  const tooltipCopy = (
    title: string,
    overlap: string | string[],
    clientChoice: (AgeRangeType | IdNameKeyType | AvailabilityType)[] | OrientationType,
    customTherapistVerb: string
  ) => {
    if (!overlap || overlap.length === 0) return undefined;

    const isOrientationNoPreference =
      clientChoice && !Array.isArray(clientChoice) && clientChoice.key === "no-preference";
    const isAgeGenderNoPreference =
      Array.isArray(clientChoice) && clientChoice.every(item => item.key === "no-preference");
    const shouldDisplayTherapistChoice = !(
      ["Age", "Orientation", "Gender"].some(item => item === title) &&
      (isAgeGenderNoPreference || isOrientationNoPreference)
    );

    const toArray = (item: any | any[]) => (Array.isArray(item) ? item : [item]);

    const overlapArray = toArray(overlap);
    const clientChoiceArray = toArray(clientChoice);

    return (
      <div>
        {clientChoiceString(clientChoiceArray)}
        {shouldDisplayTherapistChoice && overlapString(overlapArray, customTherapistVerb)}
      </div>
    );
  };

  return (
    <Grid container justify="center" spacing={1}>
      {feeRanges.length > 0 && (
        <Item
          title="Fee"
          icon={fee}
          disabled={!feeOverlaps}
          tooltip={tooltipCopy("Fee", overlappingFee, feeRanges, "This provider offers:")}
        />
      )}
      {ages.length > 0 && (
        <Item
          title="Age"
          icon={age}
          disabled={!ageOverlaps}
          tooltip={tooltipCopy("Age", overlappingAge, ages, "This provider is:")}
        />
      )}
      {lgbtqia && (
        <Item
          title="Orientation"
          icon={orientation}
          disabled={!lgbtqiaOverlaps}
          tooltip={tooltipCopy("Orientation", overlappingLgbtqia, lgbtqia, "This provider identifies as:")}
        />
      )}
      <Item title="State" icon={state} disabled={false} tooltip="This provider is licensed to work in your state." />
      {genders.length > 0 && (
        <Item
          title="Gender"
          icon={gender}
          disabled={!genderOverlaps}
          tooltip={tooltipCopy("Gender", overlappingGender, genders, "This provider identifies as:")}
        />
      )}
      {styles.length > 0 && (
        <Item
          title="Style"
          icon={style}
          disabled={!stylesOverlap}
          tooltip={tooltipCopy("Style", overlappingStyles, styles, "This provider offers:")}
        />
      )}
      {ethnicities.length > 0 && (
        <Item
          title="Race/<br />Ethnicity"
          icon={raceEthnicity}
          disabled={!ethnicitiesOverlap}
          tooltip={tooltipCopy(
            "Race/<br />Ethnicity",
            overlappingEthnicities,
            ethnicities,
            "This provider identifies as:"
          )}
        />
      )}
      {availabilities.length > 0 && (
        <Item
          title="Availability"
          icon={availability}
          disabled={!availabilitiesOverlap}
          tooltip={
            availabilitiesOverlap
              ? tooltipCopy("Availability", overlappingAvailabilities, availabilities, "This provider offers:")
              : overlapString(overlappingAvailabilities, "This provider offers:")
          }
        />
      )}
      {specializations.length > 0 && (
        <Item
          title="Expertise"
          icon={expertise}
          disabled={!specializationsOverlap}
          tooltip={tooltipCopy(
            "Expertise",
            overlappingSpecializations,
            specializations,
            "This provider has expertise in:"
          )}
        />
      )}

      {mostImportantFactor && (
        <Grid item xs={12}>
          <Typography className={classes.description}>
            You let us know that <b>{mostImportantFactor}</b> was most important to you, so we weighed that most heavily
            in your matches.
          </Typography>
        </Grid>
      )}
      {mostImportantSpecialization && specializationsOverlap && (
        <Grid item xs={12}>
          <Typography className={classes.description}>
            You let us know that <b>{mostImportantSpecialization}</b> is most important to you, and {firstName} has
            strong expertise in that area.
          </Typography>
        </Grid>
      )}
      {mostImportantSpecialization && specializationDescription && (
        <Grid item xs={12}>
          <Typography className={classes.description}>
            {firstName} has shared the below about their expertise in <b>{mostImportantSpecialization}</b>:{" "}
            {specializationDescription}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography className={classes.description}>
          {`${mobile ? "Click on" : "Hover over"} the icons above to learn which of your preferences this provider is a
          good fit for, making you a strong match.`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MatchedOn;
