import { IconButton, InputBase, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { SetStateAction, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  multiSelectText: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "&.disabled": {
      justifyContent: "center"
    }
  },
  iconButton: {
    padding: 2
  },
  closeIconColor: {
    color: "black"
  },
  closeIcon: {
    marginLeft: 4,
    marginRight: 5,
    height: theme.spacing(1.5),
    width: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  addSelect: {
    backgroundColor: "rgb(239,239,239)",
    borderRadius: theme.spacing(2),
    "&.Mui-focused": {
      border: "1px solid black"
    }
  },
  dropdownEntry: {
    fontSize: 12,
    paddingTop: 5,
    paddingLeft: 2,
    paddingRight: 5
  },
  addIcon: {
    left: 6,
    top: "auto",
    height: 12,
    width: 12,
    color: "black"
  },
  required: {
    fontSize: 12,
    fontWeight: "bold",
    color: "red",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    textAlign: "justify"
  },
  limited: {
    fontSize: 12,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    textAlign: "justify"
  }
}));

const PreferenceList: React.FC<{
  currentState: any[];
  disabled?: boolean;
  options: any[];
  update: React.Dispatch<SetStateAction<any>>;
  limit?: number;
  title: string;
}> = ({ currentState, disabled, options, update, limit, title }) => {
  const classes = useStyles();

  const [listOpen, setListOpen] = useState(false);

  const handleAdd = (event: React.ChangeEvent<{ value: any }>) => {
    const value =
      event.target.value[0].excluding === true || event.target.value[0].key === "no-preference"
        ? event.target.value
        : currentState.concat(event.target.value).filter(item => item.key !== "no-preference");
    if (limit && value.length === limit) {
      setListOpen(false);
    }
    update(value);
  };

  const handleRemove = (element: any) => {
    if (currentState.length === 1) {
      const noPreferenceOption = options.find(item => item.key === "no-preference");
      if (noPreferenceOption) currentState.push(noPreferenceOption);
    }
    update(currentState.filter(item => item !== element));
  };

  const length = options ? Math.min(4, options.length) : 0;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 30 * length + 8,
        width: 120
      }
    }
  };

  const remainingOptions = options.filter(item => currentState.find(curr => curr.name === item.name) === undefined);

  return (
    <div>
      {currentState.map(item => (
        <Typography key={`ListItem${item.id}`} className={classNames(classes.multiSelectText, { disabled })}>
          {!disabled && (
            <IconButton
              classes={{ root: classes.iconButton, colorPrimary: classes.closeIconColor }}
              color="primary"
              onClick={() => handleRemove(item)}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          )}
          {item.name}
        </Typography>
      ))}
      {!disabled && (
        <Select
          multiple
          fullWidth
          disabled={remainingOptions.length === 0 || currentState.length >= (limit || 99)}
          IconComponent={AddIcon}
          open={listOpen}
          onOpen={() => setListOpen(true)}
          onClose={() => setListOpen(false)}
          value={[]}
          onChange={handleAdd}
          MenuProps={MenuProps}
          input={<InputBase classes={{ root: classes.addSelect }} />}
          classes={{ icon: classes.addIcon }}
        >
          {remainingOptions.map(opt => (
            <MenuItem value={opt} key={`MenuItem${opt.id}`} className={classes.dropdownEntry}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      )}
      {currentState.length === 0 && (
        <Typography className={classes.required}>{`* ${title} cannot be left blank`}</Typography>
      )}
      {limit && currentState.length === limit && (
        <Typography className={classes.limited}>{`* ${title} cannot have more than ${limit} selections`}</Typography>
      )}
    </div>
  );
};

export default PreferenceList;
