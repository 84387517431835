import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as CloseIcon } from "../../../../assets/close-icon.svg";
import usePortal from "./usePortal";
import styles from "./Modal.module.css";

export interface ModalProps {
  isVisible: boolean;
  closeIt: () => void;
  modalId: string;
}

const Modal: React.FC<ModalProps> = ({ isVisible, closeIt, modalId, children: content }) => {
  const modalRef: HTMLElement = usePortal(modalId);
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        closeIt();
      }
    };
    if (isVisible) {
      window.addEventListener("keydown", handleEsc);
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isVisible, closeIt]);

  const ModalComponent = (
    <div className={styles.modal} aria-modal role="dialog">
      <header className={styles.modalHeader}>
        <CloseIcon
          className={styles.close}
          onClick={() => closeIt()}
          data-dismiss="modal"
          aria-label="Close"
          data-testid="modal-close"
        />
      </header>
      <section className={styles.modalContent}>{content}</section>
    </div>
  );

  return isVisible ? createPortal(ModalComponent, modalRef) : null;
};

export default Modal;
