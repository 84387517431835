import React, { FC } from "react";
import * as Sentry from "@sentry/react";
import { hjTagRecording } from "../../utils/hotjar";
import LogoAndMessagePage from "../match-page/common/LogoAndMessagePage";

const Fallback: FC<{ error: Error }> = ({ error }) => {
  hjTagRecording("Error");
  let message = "Sorry, something went wrong. Please reload the page or try again later.";
  if (error.name === "ChunkLoadError") {
    message = "This application has been updated, please refresh your browser to see the latest content.";
  }

  return <LogoAndMessagePage message={message} />;
};

const fallback = ({ error }: { error: Error }) => <Fallback error={error} />;

const ErrorBoundary: React.FC = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
