import { InputBase, makeStyles, Select } from "@material-ui/core";
import React, { SetStateAction } from "react";

const useStyles = makeStyles(theme => ({
  select: {
    fontSize: 12,
    display: "flex",
    alignItems: "center"
  },
  selectInputRoot: {
    height: theme.spacing(4),
    padding: 8,
    paddingLeft: theme.spacing(1),
    backgroundColor: "rgb(239,239,239)",
    borderRadius: theme.spacing(2),
    "&.Mui-focused": {
      border: "1px solid black"
    }
  }
}));

const PreferenceSelect: React.FC<{
  currentState: any;
  options: any[];
  update: React.Dispatch<SetStateAction<any>>;
}> = ({ currentState, options, update }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    update(options.find(item => item.id === Number(event.target.value)));
  };

  return (
    <Select
      fullWidth
      native
      className={classes.select}
      value={currentState.id}
      onChange={handleChange}
      input={<InputBase classes={{ root: classes.selectInputRoot }} />}
    >
      {options.map(opt => (
        <option value={opt.id} key={opt.name}>
          {opt.name}
        </option>
      ))}
    </Select>
  );
};

export default PreferenceSelect;
