import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  progress: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 10px",
    color: "white",
    fontWeight: "bolder",
    minWidth: 70,
    textAlign: "right",
    transition: "0.5s"
  },
  container: {
    backgroundColor: "inherit",
    height: 30
  }
}));

interface StepsProgressProps {
  completedStepsCount?: number;
  totalStepsCount?: number;
}

const StepsProgress: React.FC<StepsProgressProps> = ({ completedStepsCount, totalStepsCount }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {completedStepsCount && totalStepsCount && (
        <div style={{ width: `${(completedStepsCount / totalStepsCount) * 100}%` }} className={classes.progress}>
          {completedStepsCount} of {totalStepsCount}
        </div>
      )}
    </div>
  );
};

export default StepsProgress;
