import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography, useMediaQuery } from "@material-ui/core";
import CustomSection from "../../common/layout/CustomSection";

const NoMatches: React.FC<{ clientName: string }> = ({ clientName }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CustomSection>
      <Typography
        variant="h1"
        style={{ textAlign: "center", fontFamily: "Tiempos", fontSize: mobile ? 32 : 46, margin: "32px 0 16px" }}
      >
        {`Good to "see" you, ${clientName}`}
      </Typography>
      <Typography style={{ fontSize: 22, marginBottom: "16px", textAlign: "center" }}>
        At this time, we are sorry to share that the specific preferences you&apos;ve submitted do
        <br />
        not have a puzzle piece fit in our network of providers.
      </Typography>
    </CustomSection>
  );
};

export default NoMatches;
