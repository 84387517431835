import { emailRegex, urlRegex } from "../../common/regexps";

const PRESENCE_ERROR_MESSAGE = "This field is required";
const NUMBER_ERROR_MESSAGE = "This field can only contain numeric values";
const NON_EMPTINESS_ERROR_MESSAGE = "Please select at least one option";
const EMAIL_ERROR_MESSAGE = "Please enter a correct email address";
const URL_ERROR_MESSAGE = "Please enter a correct URL (starting with http:// or https://)";
const PHONE_NUMBER_ERROR_MESSAGE = "Please enter a valid phone number (10 digits, without +1)";
const CHECKED_ERROR_MESSAGE = "Please accept the terms";
const ZIP_CODE_ERROR_MESSAGE = "Please enter a valid US zip code (5 digits)";

export default class StateValidator {
  constructor(componentState, validationSchema) {
    this.componentState = componentState;
    this.validationSchema = validationSchema;
    this.validations = {};
    this.validateAll();
  }

  validateAll = () => {
    Object.entries(this.validationSchema).forEach(([attributeName, validationFunctions]) => {
      this.chainValidations(attributeName, validationFunctions);
    });
  };

  chainValidations = (attributeName, validations) => {
    validations.some(validation => {
      if (typeof validation === "string") {
        this[validation](attributeName);
      } else if (validation instanceof Array) {
        this[validation[0]](attributeName, ...validation.slice(1));
      } else {
        this.validateWithPredicate(validation.predicate, attributeName, validation.errorMessage);
      }
      return this.isInvalid(attributeName);
    });
  };

  validateChecked = attributeName => this.validateWithPredicate(this.isChecked, attributeName, CHECKED_ERROR_MESSAGE);

  validatePresence = attributeName => this.validateWithPredicate(this.isPresent, attributeName, PRESENCE_ERROR_MESSAGE);

  validateNumber = attributeName => this.validateWithPredicate(this.isNumber, attributeName, NUMBER_ERROR_MESSAGE);

  validatePhoneNumber = attributeName =>
    this.validateWithPredicate(this.isPhoneNumber, attributeName, PHONE_NUMBER_ERROR_MESSAGE);

  validateNonEmptiness = attributeName =>
    this.validateWithPredicate(this.isNonEmpty, attributeName, NON_EMPTINESS_ERROR_MESSAGE);

  validateEmail = attributeName => this.validateWithPredicate(this.isAnEmail, attributeName, EMAIL_ERROR_MESSAGE);

  validateUrl = attributeName => this.validateWithPredicate(this.isAnUrl, attributeName, URL_ERROR_MESSAGE);

  validateZipCode = attributeName => this.validateWithPredicate(this.isAZipCode, attributeName, ZIP_CODE_ERROR_MESSAGE);

  validateMaxCount = (attributeName, limit, itemsName) =>
    this.validateWithPredicate(
      value => !value || value.length <= limit,
      attributeName,
      `Exceeded a limit of ${limit} ${itemsName}.`
    );

  validateBeLessThan = (attributeName, otherAttributeName) => {
    const value2 = this.componentState[otherAttributeName];

    return this.validateWithPredicate(
      value => !value || !value2 || (value && parseInt(value, 10) <= parseInt(value2, 10)),
      attributeName,
      `This amount must be less than your ${otherAttributeName.replace(/([A-Z])/g, " $1").toLowerCase()}.`
    );
  };

  validateWithPredicate = (predicate, attributeName, errorMessage) => {
    if (predicate(this.componentState[attributeName])) {
      this.clear(attributeName);
    } else {
      this.validations[attributeName] = {
        invalid: true,
        validationError: errorMessage
      };
    }
  };

  clear = attributeName => {
    this.validations[attributeName] = { invalid: false, validationError: "" };
  };

  isStateValid = () =>
    Object.values(this.validations)
      .map(({ invalid }) => !invalid)
      .every(i => i);

  isChecked = value => value === true;

  isPresent = value => value !== undefined && (typeof value !== "string" || value.trim() !== "");

  isNumber = value => value === undefined || /^\d*$/.test(value);

  isPhoneNumber = value => !value || /^\d{10}$/.test(value.replace(/\s/g, ""));

  isNonEmpty = collection => !!collection && collection.length > 0;

  isAnEmail = value => !value || value.length === 0 || emailRegex.test(value);

  isAnUrl = value => !value || value.length === 0 || urlRegex.test(value);

  isAZipCode = value => !value || value.length === 0 || /^[0-9]{5}$/.test(value);

  isInvalid = attributeName => this.validations[attributeName] && this.validations[attributeName].invalid;

  validationError = attributeName => this.validations[attributeName] && this.validations[attributeName].validationError;

  isRequired = attributeName => this.validationSchema[attributeName] !== undefined;
}
