import React, { FC, useEffect, useState } from "react";
import FullStory, { setUserVars } from "react-fullstory";
import { useCalendlyEventListener } from "react-calendly";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import curve1Flipped from "../../../assets/layout/curve-1-flipped.svg";
import curve4 from "../../../assets/layout/curve-4.svg";
import curve6 from "../../../assets/layout/curve-6.svg";
import { withMatchData } from "../common/MatchDataProvider";
import { FULLSTORY_ORG_ID } from "../../../config";
import { isSendingEventsDisabled, sendEvent } from "../events";
import CustomSection from "../../common/layout/CustomSection";
import Preferences from "./Preferences";
import { MatchDataContextType } from "../types";
import TherapistCarousel from "./Carousel/TherapistCarousel";
import LearnMore from "./TherapistCard/LearnMore";
import AdditionalMatches from "./AdditionalMatches";
import Footer from "./Footer";
import FlatButton from "../../therapist-app/profile/common/FlatButton";
import NoMatches from "./NoMatches";
import PrimaryMatches from "./PrimaryMatches";
import ZayaCalculator from "./ZayaCalculator";

const MatchResult: FC<{ matchDataContext: MatchDataContextType }> = ({ matchDataContext }) => {
  const theme = useTheme();

  const [therapistIdWithOpenCalendar, setTherapistIdWithOpenCalendar] = useState<number | undefined>();
  const [openCarouselIndex, setOpenCarouselIndex] = useState(-1);
  const [learnMoreIndex, setLearnMoreIndex] = useState(0);
  const [openCarousel, setOpenCarousel] = useState(0);

  const { client, matches } = matchDataContext;
  const { name, email } = client;

  useEffect(() => {
    if (!isSendingEventsDisabled()) {
      setUserVars({
        displayName: name,
        email
      });
    }
  }, [name, email]);

  useEffect(() => {
    if (openCarouselIndex >= 0) {
      setOpenCarousel(openCarouselIndex);
    }
  }, [openCarouselIndex]);

  useCalendlyEventListener({
    onEventScheduled: event =>
      sendEvent("calendly_scheduled", { therapist_id: therapistIdWithOpenCalendar, ...event.data })
  });

  const hasResults = matches.length > 0 && matches[0].therapists.length > 0;

  return (
    <div style={{ minHeight: "calc(100% - 60px)", backgroundColor: theme.palette.primary.main }}>
      {!isSendingEventsDisabled() && <FullStory org={FULLSTORY_ORG_ID} host="fullstory.com" namespace="FS" />}

      {!hasResults && <NoMatches clientName={name} />}
      {hasResults && (
        <PrimaryMatches
          clientName={name}
          setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
          setOpenCarouselIndex={setOpenCarouselIndex}
          setLearnMoreIndex={setLearnMoreIndex}
        />
      )}

      <CustomSection color={hasResults ? "silver" : undefined} image={matches.length > 1 ? curve1Flipped : curve6}>
        <Box pt="24px">
          <Preferences
            title={
              hasResults
                ? "The above matches are based on your preferences:"
                : "Update any flexible preferences below to try again:"
            }
            preferenceSetIndex={0}
            hideInsurance={!hasResults}
          />
        </Box>

        <Box display="flex" justifyContent="center" mt="32px">
          <FlatButton onClick={() => setOpenCarouselIndex(0)} color="orange" round>
            Need to update your preferences?
          </FlatButton>
        </Box>
      </CustomSection>

      {matches.length > 1 && (
        <AdditionalMatches
          setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
          setOpenCarouselIndex={setOpenCarouselIndex}
          setLearnMoreIndex={setLearnMoreIndex}
        />
      )}

      <CustomSection color="lightRose" image={curve4}>
        <Box id="mentaya" mt={{ xs: 2, sm: 8 }}>
          <ZayaCalculator />
        </Box>
      </CustomSection>

      <div id="footer">
        <CustomSection color="darkBlue">
          <Footer />
        </CustomSection>
      </div>

      <LearnMore onClose={() => setOpenCarouselIndex(-1)} isOpen={openCarouselIndex >= 0}>
        <TherapistCarousel
          setTherapistIdWithOpenCalendar={setTherapistIdWithOpenCalendar}
          openCarouselIndex={openCarouselIndex}
          setOpenCarouselIndex={setOpenCarouselIndex}
          initialIndex={learnMoreIndex}
          preferenceSetIndex={openCarousel}
        />
      </LearnMore>
    </div>
  );
};

export default withMatchData(MatchResult);
