import queryString from "query-string";
import { createEventRequest } from "./api";

export const isSendingEventsDisabled = () => {
  const query = queryString.parse(window.location.search);
  const { auth_token: authToken, mode } = query;
  return !authToken || mode === "preview" || authToken === "demo";
};

export const sendEvent = (name, payload = {}) => {
  if (isSendingEventsDisabled()) {
    return;
  }

  const { source } = queryString.parse(window.location.search);

  createEventRequest({
    data: {
      event: {
        name,
        payload: { ...payload, source }
      }
    },
    callbacks: {
      onSuccess: () => {},
      onError: () => {},
      onAfter: () => {}
    }
  });
};

export const sendFormVisitedEvent = (payload = {}) => {
  const query = queryString.parse(window.location.search);
  const { auth_token: authToken } = query;
  if (authToken === "demo") {
    return;
  }

  createEventRequest({
    data: {
      event: {
        name: "form_visited",
        payload
      }
    },
    callbacks: {
      onSuccess: () => {},
      onError: () => {},
      onAfter: () => {}
    }
  });
};
