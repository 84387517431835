import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import StepContainer from "../../common/StepContainer";
import { ModalLinkProps, StepItems } from "../../../types";
import SelectionElement from "../../FormElements/SelectionElement";
import { SINGLE_SELECTION } from "../../../types/FormTypes";

export type SelectionFormProps = {
  title: string;
  type: string;
  items: Array<StepItems>;
  setSavedData: Dispatch<SetStateAction<Array<string>>>;
  savedData: Array<string>;
  topLink?: ModalLinkProps;
  textUnderChoices?: string;
  subtitle?: string;
  notice?: string;
  onNext: () => void;
  excludingWorksAcrossAllGroups?: boolean;
  selectionsLimit?: number;
  validationMessage?: string;
};

const SelectionForm: React.FC<SelectionFormProps> = ({
  title,
  type,
  items,
  setSavedData,
  savedData,
  topLink,
  textUnderChoices,
  subtitle,
  notice,
  onNext,
  excludingWorksAcrossAllGroups,
  selectionsLimit,
  validationMessage
}) => {
  const [isValid, setIsValid] = useState(false);
  const [movingToNext, setMovingToNext] = useState(false);
  const [savedDataInitiallyEmpty] = useState(savedData.length === 0);

  const onNextWithValidation = (event: React.MouseEvent) => {
    setMovingToNext(true);
    if (isValid) {
      onNext();
    }
  };

  useEffect(() => {
    setMovingToNext(false);
  }, [savedData, setMovingToNext]);

  useEffect(() => {
    if (savedDataInitiallyEmpty && isValid && type === SINGLE_SELECTION) {
      setTimeout(() => onNext(), 200);
    }
  }, [savedDataInitiallyEmpty, isValid, type, onNext]);

  return (
    <>
      <StepContainer
        title={title}
        subtitle={subtitle}
        notice={notice}
        topLink={topLink}
        textUnderChoices={textUnderChoices}
        onNext={type === SINGLE_SELECTION && savedDataInitiallyEmpty ? undefined : onNextWithValidation}
        isValid={isValid}
        showValidationMessage={(movingToNext && !isValid) || !!validationMessage}
        validationMessage={validationMessage || "Please make a selection"}
      >
        <SelectionElement
          type={type}
          items={items}
          excludingWorksAcrossAllGroups={excludingWorksAcrossAllGroups}
          setSavedData={setSavedData}
          savedData={savedData}
          setIsValid={setIsValid}
          selectionsLimit={selectionsLimit}
        />
      </StepContainer>
    </>
  );
};

export default SelectionForm;
