import React, { Dispatch, SetStateAction } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
  checkbox: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0
    }
  },
  checkboxLabel: {
    "&.small": {
      fontSize: 12
    }
  },
  icon: {
    "&.small": {
      fontSize: 16
    }
  },
  formControlLabel: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "start"
    }
  }
}));

export type CheckboxElementProps = {
  checkboxLabel: string;
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: boolean;
  small?: boolean;
};

const CheckboxElement: React.FC<CheckboxElementProps> = ({ checkboxLabel, setSavedData, savedData, small }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSavedData(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={savedData}
            onChange={handleCheckboxChange}
            color="primary"
            className={classes.checkbox}
            icon={<CheckBoxOutlineBlankIcon className={classnames(classes.icon, { small })} />}
            checkedIcon={<CheckBoxIcon className={classnames(classes.icon, { small })} />}
          />
        }
        label={
          <Typography
            className={classnames(classes.checkboxLabel, { small })}
            dangerouslySetInnerHTML={{ __html: checkboxLabel }}
          />
        }
      />
    </FormGroup>
  );
};

export default CheckboxElement;
