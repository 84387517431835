import React, { Dispatch, SetStateAction } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { MetaTextProp, SliderConfig } from "../../../types";
import SliderElement from "../../../../common/SliderElement";

const sliderHeight = 113;
const headerHeight = 68;

const useStyles = makeStyles(() => ({
  sliderBox: {
    width: "100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "10px",
    WebkitBorderRadius: "10px"
  },
  titleSlider: {
    background: "#6B315D",
    borderRadius: "10px 10px 0px 0px",
    width: "100%",
    height: "68px",
    padding: "8px",
    color: "#ffffff"
  },
  sliderDescription: {
    padding: "10px 20px",
    height: `calc(100% - ${headerHeight}px - ${sliderHeight}px)`
  },
  descriptionTitle: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#2A3948"
  },
  descriptionText: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#2A3948",
    margin: "10px 0"
  },
  sliderContainer: {
    padding: 20,
    height: sliderHeight,
    borderTop: "1px solid gray"
  }
}));

interface SliderCardProps {
  setSavedData: Dispatch<SetStateAction<any>>;
  savedData: any;
  config: SliderConfig;
  title: string;
  id: string;
  isNotValid: boolean;
  errorMsg: string;
}

const SliderCard: React.FC<SliderCardProps> = ({
  setSavedData,
  savedData,
  config,
  id,
  title,
  isNotValid,
  errorMsg
}) => {
  const classes = useStyles();

  const value = savedData[id];

  const onChange = (selectedValue: number) => {
    setSavedData({ ...savedData, [id]: selectedValue });
  };

  const mapDescriptionContent = () => {
    return config.text.map(
      (textContent: MetaTextProp): React.ReactElement => (
        <div key={textContent.title}>
          <span className={classes.descriptionTitle}>{textContent.title}</span>
          <p className={classes.descriptionText}>{textContent.subtitle}</p>
        </div>
      )
    );
  };

  return (
    <Grid container className={classes.sliderBox}>
      <Grid container justify="center" alignItems="center" className={classes.titleSlider}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item className={classes.sliderDescription} xs={12}>
        {mapDescriptionContent()}
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.sliderContainer}>
          <SliderElement
            onUpdate={onChange}
            minValue={config.min.value}
            maxValue={config.max.value}
            minLabel={config.min.label}
            maxLabel={config.max.label}
            selectedValue={value}
            errorMsg={errorMsg}
            displayError={isNotValid}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SliderCard;
