import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    padding: `16px ${theme.spacing(12)}px`,
    [theme.breakpoints.down("sm")]: {
      padding: `16px ${theme.spacing(2)}px`
    }
  }
}));

const Section: React.FC<{ withPadding?: boolean }> = ({ children, withPadding = true }) => {
  const classes = useStyles();

  return <div className={withPadding ? classes.container : undefined}>{children}</div>;
};

export default Section;
